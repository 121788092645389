















import { Component, Prop } from "vue-property-decorator";

import { ValidationMessage, BaseInputComponent } from ".";
import LabelWithTooltip from "./LabelWithTooltip.vue";

@Component({
  components: {
    ValidationMessage,
    LabelWithTooltip,
  },
})
export default class InputNumber extends BaseInputComponent {
  @Prop() min?: number;
  @Prop() max?: number;
}
