import { HttpService } from "./common/http.service";
import {
  ActiveCertificateStatisticsCollectionFilter,
  IssuedCertificateStatisticsCollectionFilter,
  CertificateStatisticsCollectionModel,
  CertificateStatisticsGridLookupModel,
} from "@/core/models";

export const CertificateStatisticsService = {
  getCertificateStatisticsGridLookupData() {
    return HttpService.getData<CertificateStatisticsGridLookupModel>(`/certificateStatistics/gridLookupData`);
  },

  getActiveCertStatistics(filter: ActiveCertificateStatisticsCollectionFilter) {
    return HttpService.getData<CertificateStatisticsCollectionModel[]>(
      "/certificateStatistics/active-certificates-by-course",
      { params: filter },
    );
  },

  getIssuedCertStatistics(filter: IssuedCertificateStatisticsCollectionFilter) {
    return HttpService.getData<CertificateStatisticsCollectionModel[]>(
      "/certificateStatistics/issued-certificates-by-course",
      { params: filter },
    );
  },
};
