import { RoleName } from "@/core/constants";

export const emailTemplateRoutes = [
  {
    path: "/admin/email-templates/:locale?",
    name: "adminEmailTemplateList",
    meta: {
      title: "Email templates",
      roles: `${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "email-template" */ "@/views/emailtemplate/AdminEmailTemplateList.vue"),
  },
  {
    path: "/admin/email-templates/edit/:templateTypeId/:locale?",
    name: "adminEmailTemplateEdit",
    meta: {
      title: "Edit email template",
      roles: `${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "email-template" */ "@/views/emailtemplate/AdminEmailTemplateEdit.vue"),
  },
];
