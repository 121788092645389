export default {
  "common": {
    "name": "Naam",
    "hello": "Hallo",
    "details": "Details",
    "created": "Aangemaakt",
    "ok": "Oké",
    "cancel": "Annuleren",
    "save": "Opslaan",
    "unpublish": "",
    "id": "ID",
    "search": "Zoeken",
    "status": "Status",
    "view": "Weergave",
    "course": "Cursus",
    "union": "Bond",
    "noData": "Geen datum",
    "startDate": "Startdatum",
    "startTime": "",
    "endDate": "Einddatum",
    "notes": "Opmerkingen",
    "educators": "Opleiders",
    "forUser": "Voor gebruiker",
    "regions": "Regio's",
    "unions": "Bonden",
    "existingRegions": "",
    "existingUnions": "",
    "homeUnion": "",
    "expiresOn": "Verloopt op",
    "selectUser": "Selecteer gebruiker",
    "none": "Geen",
    "level": "Niveau",
    "actionAddAll": "Alles toevoegen",
    "reason": "Reden",
    "gettingData": "Gegevens ophalen",
    "success": "Succes",
    "error": "Fout",
    "actionEdit": "Bewerken",
    "actionSubmit": "Verzenden",
    "logo": "Logo",
    "actionDropFile": "Zet het bestand hier neer of",
    "imageFormatsAllowed1": "JPG/PNG/GIF-bestanden toegestaan",
    "forename": "Voornaam",
    "lastname": "Achternaam",
    "emailAddress": "E-mailadres",
    "email": "E-mail",
    "validationIsRequired": "is vereist",
    "code": "Code",
    "certified": "Gecertificeerd",
    "certExpiry": "Certificaat vervalt",
    "session": "Sessie",
    "yes": "Ja",
    "no": "Nee",
    "actionDownload": "Downloaden",
    "close": "Afsluiten",
    "user": "Gebruiker",
    "location": "",
    "from": "",
    "to": "",
    "type": "",
    "validationError": "",
    "validationErrorDescription": "",
    "errorSaving": "",
    "saving": ""
  },
  "navigation": {
    "courses": "Cursussen",
    "myCourses": "Mijn cursussen",
    "courseAdmin": "Cursusadministratie",
    "training": "Training",
    "mySessions": "Mijn sessies",
    "sessionAdmin": "Beheerder van sessie",
    "dashboard": "Dashboard",
    "navAbout": "Over",
    "navAdministration": "Administraties",
    "navUnions": "Bonden",
    "logout": "Uitloggen",
    "myProfile": "Mijn profiel",
    "coursesAttended": "Gevolgde cursussen",
    "licensesAwarded": "Toegekende licenties",
    "certification": "",
    "fileshare": "",
    "correspondence": ""
  },
  "mySessions": {
    "viewMyTrainingSession": "Bekijk mijn trainingen",
    "hostTrainingSession": "Een training organiseren",
    "selectCourse": "Selecteer een training",
    "selectUnion": "Selecteer een bond",
    "selectLicense": "Selecteer een licentie",
    "licenseInfo": "Licentie-informatie",
    "validIn": "Geldig in",
    "language": "Taal",
    "unionToAdmin": "Beherende bond"
  },
  "filters": {
    "filterByRegion": "Filter op regio",
    "filterByUnion": "Filter op bond",
    "filterByStatus": "Filter op status",
    "filterByQualityStatus": "Filter op kwaliteitsstatus",
    "filterByLevel": "Filter op niveau",
    "filterByStrand": "Filter op onderwerp",
    "filterByExpiry": "Filter op vervaldatum",
    "filterByDeliveryType": "Filter op type uitvoering",
    "pleaseSelectLevel": "Selecteer een niveau",
    "pleaseSelectStrand": "Selecteer een positie",
    "pleaseSelectRegions": "Selecteer regio's",
    "pleaseSelectUnions": "Selecteer bonden",
    "pleaseSelectStatus": "Selecteer status",
    "filterByLanguage": "",
    "filterByCourse": "",
    "filterByAttendeeRegion": "",
    "filterByAttendeeUnion": "",
    "filterByUserEmail": "",
    "filterByAssessmentStatus": ""
  },
  "sessionAdmin": {
    "viewScheduledTrainingSession": "Bekijk geplande trainingen",
    "scheduleTrainingSession": "Een training inplannen",
    "addTrainingSession": "Training toevoegen"
  },
  "editTrainingSession": {
    "editTrainingSession": "Training bewerken",
    "scheduledTraining": "Geplande training",
    "certificateLanguage": "Taal certificaat",
    "venueName": "Naam locatie",
    "venueAddress": "Adres locatie",
    "organiser": "Organisator",
    "selectAnEducator": "Selecteer een opleider",
    "addEducator": "Opleider toevoegen",
    "addNoteToHistory": "Notitie toevoegen aan geschiedenis",
    "approve": "Goedkeuren",
    "saveTrainingSession": "Training opslaan",
    "viewTrainingSessionHistory": "Geschiedenis trainingssessie bekijken",
    "noHistory": "Geen geschiedenis weer te geven",
    "addEducatorWarning": "Deze opleider kan assisteren, maar mag deze sessie niet leiden. Voor elke sessie is minimaal één hoofdopleider nodig.",
    "assistingEducators": "Assisterende opleiders",
    "canLead": "Mag leiden?",
    "selectLanguage": "Selecteer een taal",
    "scheduleCourse": "Plan een cursus",
    "saveCourse": "Cursus opslaan",
    "addCourse": "Cursus toevoegen",
    "emailLanguageTooltip": "E-mails naar deelnemers zijn in deze taal",
    "startTimeLocalTime": ""
  },
  "addNote": {
    "addNote": "Notitie toevoegen",
    "noteDetails": "Details notitie"
  },
  "courseSessionStatus": {
    "awaitingApproval": "In afwachting van goedkeuring",
    "rejected": "Afgekeurd",
    "approved": "Goedgekeurd"
  },
  "tabs": {
    "tabattendees": "Deelnemers",
    "tabhistory": "Geschiedenis",
    "tabCourseMaterials": "Cursusmateriaal"
  },
  "addCourseAttendees": {
    "titleViewTrainingSessionAttendees": "Deelnemers training bekijken",
    "editAttendeeList": "Deelnemerslijst bewerken",
    "attendeeName": "Naam",
    "assessmentStatus": "Beoordelingsstatus",
    "actions": "Acties",
    "editAttendees": "Deelnemers bewerken",
    "actionConfirm": "Bevestigen",
    "noUsersFound": "Geen gebruikers gevonden",
    "attendeeUpdateError": "Fout bij update van deelnemer",
    "attendeeUpdateErrorDescription": "Fout bij het bijwerken van deelnemerslijst. Is er een e-mailsjabloon geconfigureerd voor de achtergrond van sessie?",
    "optionSelectStatus": "Status selecteren",
    "actionUpdateStatus": "Status bijwerken",
    "actionAdd": "Toevoegen",
    "titleAddAssessment": "Beoordeling toevoegen",
    "selectLanguage": "Taal selecteren",
    "actionLicense": "Licentie",
    "actionLicenseDescription": "Een licentie toekennen aan deze deelnemer",
    "actionEdit": "Bewerken",
    "unmetPrerequisites": "Onvervulde vereisten",
    "approveAreYouSure": "Weet u zeker dat u de certificering voor de geselecteerde deelnemers wilt goedkeuren",
    "approveCertification": "Certificering goedkeuren",
    "errorUpdatingStatusTitle": "Fout bij bijwerken van status",
    "errorUpdatingStatusMessage": "Fout bij het bijwerken van status deelnemer",
    "sendAttendeeInvites": "",
    "sendAttendeeInvitesToolTip": ""
  },
  "editAssessment": {
    "editAssessment": "Beoordeling bewerken",
    "competency": "Competentie",
    "notYetCompetent": "Nog niet bevoegd",
    "notCompetent": "",
    "competent": "Bevoegd",
    "excellent": "Uitstekend",
    "criteria": "Criteria",
    "commentHelpText": "Opmerking - Verplicht voor 'Nog niet bevoegd’",
    "actionSaveAndPublish": "Opslaan en publiceren",
    "assessorComment": "Commentaar beoordelaar",
    "publishedBy": "Gepubliceerd door",
    "lastUpdatedBy": "Laatst bijgewerkt door",
    "addedBy": "Toegevoegd door",
    "errorSavingAssessment": "Fout bij opslaan beoordeling",
    "errorSavingAssessmentMessage": "Controleer of alle invoer geldig is",
    "confirmCancelAssessment": "Weet u zeker dat u wilt annuleren?",
    "leaveAssessment": "Beoordeling verlaten?",
    "confirmPublishAssessment": "Weet u zeker dat u deze beoordeling wilt publiceren? Eenmaal gepubliceerd, kan de beoordeling niet worden bewerkt",
    "publishAssessment": "Beoordeling publiceren?",
    "viewAssessment": "Beoordeling bekijken",
    "attendeeSelfReflection": "Zelfreflectie deelnemer",
    "postCourseProgress": "",
    "postLiveExperienceProgress": "",
    "attendeeNotCompetentHeading": "",
    "attendeeMayBeCompetent": "",
    "attendeeNotCompetent": "",
    "attendeeNotCompetentConfirmation": "",
    "unpublishAssessmentConfirmation": ""
  },
  "feedback": {
    "viewFeedback": "Feedback bekijken"
  },
  "activeCerts": {
    "activeCertificateStatistics": "Statistieken actieve certificaten",
    "date1": "Datum 1",
    "date2": "Datum 2",
    "activeCerts": "Actieve certificaten",
    "pickDate": "Kies een datum",
    "total": "Totaal"
  },
  "issuedCerts": {
    "issuedCertificateStatistics": "Statistieken uitgegeven certificaten",
    "filterByDeliveryType": "Filter op type uitvoering",
    "range1": "Bereik 1",
    "range2": "Bereik 2",
    "dateTo": "Op",
    "regionUserFilters": "Regio-/gebruikersfilters",
    "regionUserFiltersDescription1": "Filters op regio en bond worden afhankelijk van uitvoering cursus verschillend toegepast:",
    "regionUserFiltersDescription2": "Online & Scorm: Statistieken zijn gegroepeerd op bij deelnemer geregistreerde bond",
    "regionUserFiltersDescription3": "Face-to-face: Statistieken zijn gegroepeerd per bond waar de training plaatsvond",
    "courseCode": "Cursuscode",
    "courseName": "Cursusnaam",
    "issuedCerts1": "Afgegeven certificaten 1",
    "issuedCerts2": "Afgegeven certificaten 2",
    "percentageIncrease": "% Toename",
    "range1Start": "Bereik 1 start",
    "range1End": "Bereik 1 einde",
    "range2Start": "Bereik 2 start",
    "range2End": "Bereik 2 einde"
  },
  "strands": {
    "strandRugbyReady": "Rugby ready",
    "strandLaws": "Regels van het spel",
    "strandStrengthAndConditioning": "Kracht en conditie",
    "strandPlayerWelfare": "Welzijn van de speler",
    "strandKeepRugbyClean": "Keep Rugby Clean",
    "strandCoaching": "Coaching",
    "strandOfficiating": "Officials",
    "strandIntegrity": "Integriteit",
    "strandMatchDayStaff": "Personeel op de wedstrijddag",
    "strand": "Onderwerp"
  },
  "licenseStatus": {
    "licenseActive": "Actief",
    "licenseRevoked": "Ingetrokken",
    "licenseExpired": "Verlopen",
    "licenseProvisional": "Voorlopig"
  },
  "licenses": {
    "licenses": "Licenties",
    "grantEducatorLicense": "Opleider licentie toekennen",
    "actionRevoke": "Intrekken",
    "grantALicense": "Een licentie verlenen",
    "grantLicense": "Verleen licentie",
    "certificatesAwarded": "Certificaten uitgereikt",
    "actionGrantALicense": "Een licentie verlenen",
    "licenseRevoke": "Intrekking licentie",
    "revokeLicense": "Licentie intrekken",
    "noRevokeEmailHeader": "Geen automatische e-mail bij intrekking",
    "noRevokeEmailBody": "Er wordt geen automatische e-mail verzonden wanneer een licentie wordt ingetrokken. Beheerders willen deze actie mogelijk persoonlijk doorgeven",
    "viewEligibleCourses": "In aanmerking komende cursussen bekijken",
    "eligibleCourses": "In aanmerking komende cursussen",
    "messageLicenceAwardDelay": "Er zit vertraging tussen het toekennen van een certificaat aan een deelnemer door een trainer en de afgifte van het certificaat. de onderstaande tabel toont alleen uitgereikte certificaten, het kan dus zijn dat een recentelijk aan een deelnemer uitgereikt certificaat niet in de onderstaande tabel wordt weergegeven",
    "messageEducatorCanTeach": "Een opleider met de voorgestelde licentie kan de volgende cursussen geven",
    "licenseAwardedTo": "Licentie toegekend aan",
    "errorAddingLicense": "Fout bij het toevoegen van deze licentie",
    "gettingLicenseInformation": "Ophalen licentie-informatie",
    "noLicenses": "U heeft op dit moment geen actieve licenties",
    "licenseHistory": "Geschiedenis licenties",
    "qualityStatus": "Kwaliteitsstatus",
    "delayIssuingCertificate": "Opmerking: Vertraging bij verstrekken certificaten",
    "delayIssuingCertificateInfo": "Er zit vertraging tussen het toekennen van een certificaat aan een deelnemer door een trainer en de afgifte van het certificaat. de onderstaande tabel toont alleen uitgereikte certificaten, het kan dus zijn dat een recentelijk aan een deelnemer uitgereikt certificaat niet in de onderstaande tabel wordt weergegeven",
    "noActiveLicenses": "Geen actieve licenties",
    "addLicenseError": "Fout bij het toevoegen van deze licentie",
    "myLicenses": "Mijn licenties",
    "emailWorkforce": "Email workforce",
    "licenseAmended": "",
    "licenseAmendedConfirmationHeading": "",
    "licenseAmendConfirmation": "",
    "expireLicense": "",
    "amendLicense": ""
  },
  "regionAdmin": {
    "rtmUser": "RTM-gebruiker",
    "saveRegion": "Regio opslaan"
  },
  "unionAdmin": {
    "unionTrainingEducationAdministrator": "T&E-beheerder bond",
    "canDeliverCoursesForWr": "Kan cursussen geven namens World Rugby",
    "saveUnion": "Bond opslaan",
    "gettingUnion": "Bond ophalen",
    "unionSaved": "Bond opgeslagen"
  },
  "certStatus": {
    "certStatusInProgress": "In behandeling",
    "certStatusValid": "geldig",
    "certStatusInExpired": "Verlopen",
    "certStatusAll": "Alle"
  },
  "userAdmin": {
    "userList": "Gebruikerslijst",
    "inviteUser": "Gebruiker uitnodigen",
    "infoNoEmailInviteHeader": "Er wordt geen uitnodigingsmail verzonden",
    "infoNoEmailInviteBody1": "Deze functie voegt een gebruiker toe aan de database met de Iris-backend-rol",
    "infoNoEmailInviteBody2": "Er wordt geen uitnodigingsmail naar de gebruiker verzonden als onderdeel van deze functie, het is dus de verantwoordelijkheid van de iris-beheerders hen te informeren dat ze zijn toegevoegd",
    "infoNoEmailInviteBody3": "Als de gebruiker zich met behulp van het hier verstrekte e-mailadres nog niet bij WR SSO heeft geregistreerd, moet hij dit alsnog doen",
    "actionAddUserAndAssignBackendRole": " Gebruiker toevoegen en backend-rol toewijzen",
    "irisUserAddedHeader": "Iris-backend-gebruiker toegevoegd",
    "irisUserAddedBody": "is toegevoegd als een Iris-backend-gebruiker",
    "errorAddingUserHeader": "Fout bij toevoegen backend-gebruiker",
    "errorAddingUserBody": "Er is een fout opgetreden bij het toevoegen van de backend-gebruiker",
    "isExactEmail": "Is exacte e-mail",
    "userBaseId": "ID gebruikersgroep",
    "lastLoginDate": "laatste inlogdatum",
    "coursesAndCertificates": "Cursussen & certificaten",
    "roles": "Rollen",
    "currentValidCertificates": "Huidige geldige certificaten",
    "userLicenses": "Gebruikerslicenties",
    "userNeverGrantedLicense": "Deze gebruiker heeft nooit een licentie gekregen",
    "infoRegionManagerheader": "Rol regionale trainer",
    "infoRegionManagerDetail1": "De rol van regionale trainer kan worden toegekend in het",
    "infoRegionManagerDetail2": "administratieformulier van de regio",
    "infoUnionManagerheader": "Rol bondstrainer",
    "infoUnionManagerDetail1": "De rol van bondstrainer kan worden toegekend in het",
    "infoUnionManagerDetail2": "administratieformulier van de bond",
    "userRoles": "gebruikersrollen",
    "infoRolesUpdatedHeader": "Rollen bijgewerkt",
    "infoRolesUpdatedBody": "Gebruikersrollen zijn met succes bijgewerkt",
    "userExactEmail": "Gebruik exact e-mailadres",
    "select": "Selecteren",
    "noUsers": "Geen gebruikers",
    "searchAttendee": "Naar deelnemers zoeken om toe te voegen"
  },
  "reports": {
    "reports": "Rapporten",
    "report": "Rapport",
    "deliveryType": "Type uitvoering",
    "actionGetReport": "Rapport ophalen",
    "regions": "Regio(‘s)",
    "unions": "Bond(en)"
  },
  "qualityAudit": {
    "manage": "Beheren",
    "qualityAssurance": "Kwaliteitsborging",
    "qualityAudits": "Kwaliteitsaudits",
    "date": "Datum",
    "addQualityAudit": "Kwaliteitsaudit toevoegen",
    "noAuditsAdded": "Er zijn geen audits toegevoegd aan deze licentie",
    "educatorCanTeach": "Een opleider met de voorgestelde licentie kan de volgende cursussen geven:",
    "auditor": "Auditeur",
    "published": "Gepubliceerd",
    "actionPublish": "Publiceren",
    "audits": "Audits",
    "assignAuditor": "Auditeur toewijzen",
    "myAssignedQas": "Mijn toegewezen KA’s",
    "audit": "Auditeren",
    "auditBy": "Auditeren door",
    "publishedBy": "Deze audit is gepubliceerd door ",
    "onDate": "op",
    "venue": "Locatie",
    "detailsPlaceholder": "",
    "areasOfStrength": "Sterke punten",
    "areasOfStrengthPlaceholder": "Voeg hier sterke punten toe",
    "areasForDevelopment": "Gebieden voor ontwikkeling",
    "areasForDevelopmentPlaceholder": "Voeg hier ontwikkelingsgebieden toe",
    "agreedActionPlan": "Overeengekomen actieplan",
    "agreedActionPlanPlaceholder": "Voeg hier het overeengekomen actieplan toe",
    "educatorSelfReflection": "Zelfreflectie opleider",
    "educatorSelfReflectionPlaceholder": "Voeg hier zelfreflectie van opleider toe",
    "grade": "Niveau",
    "gradePlaceholder": "Selecteer een niveau",
    "behaviour": "Gedrag",
    "behaviourPlaceholder": "Selecteer een gedrag",
    "saveAsDraft": "Opslaan als concept",
    "auditsOnEducator": "Audits over opleider",
    "auditsByEducator": "Audits door opleider",
    "educator": "Opleider",
    "addEducatorNote": "Notitie opleider toevoegen",
    "educatorNoteTooltip": "Notities van opleiders zijn zichtbaar voor opleiders en hun managers op het tabblad cursushistorie",
    "qaSubmitSuccess": "Kwaliteitsaudit succesvol ingediend",
    "qaPublishedSuccess": "Kwaliteitsaudit succesvol gepubliceerd",
    "qaConfirmPublish": "Weet u zeker dat u deze kwaliteitsaudit wilt publiceren? De audit wordt alleen-lezen zodra deze is gepubliceerd.",
    "qaConfirmPublishTitle": "Audit publiceren?",
    "qaLoadingText": "Informatie kwaliteitsaudit ophalen...",
    "manageAudits": "Audits beheren",
    "myActiveLicences": "Mijn actieve licenties",
    "editQualityAudit": "Kwaliteitsaudit bewerken",
    "submitForm": "Formulier indienen",
    "auditPublished": "Audit gepubliceerd",
    "user": "Gebruiker",
    "licenseHolder": "Licentiehouder",
    "licenseStatus": "",
    "publishStatus": "",
    "actionSubmitEducatorFeedback": "",
    "actionSubmitForEducatorFeedback": ""
  },
  "qualityAuditStatus": {
    "Draft": "",
    "AwaitingEducatorFeedback": "",
    "AwaitingAuditeeFeedback": "",
    "AwaitingPublish": "",
    "Published": ""
  },
  "termsAndConds": {
    "heading": "Algemene voorwaarden World Rugby accepteren",
    "main": "Om Iris te kunnen gebruiken, moet u de volgende voorwaarden accepteren:",
    "educatorCodeOfConductHeading": "Gedragscode voor opleiders",
    "educatorLinkDescription": "De gedragscode voor opleiders kan ingezien worden op",
    "educatorAcceptCodeOfConduct": "Ik accepteer de gedragscode voor opleiders"
  },
  "courseSessionAttendees": {
    "removeAttendees": "Deelnemers verwijderen",
    "addAttendees": "Deelnemers toevoegen",
    "addAttendeesBy": "Deelnemers toevoegen op",
    "draft": "Concept",
    "assessment": "Beoordeling",
    "noAttendees": "Geen deelnemers",
    "noAttendeesSubTitle": "Er zijn geen deelnemers toegevoegd aan deze cursus. Klik op onderstaande knop om deelnemers toe te voegen",
    "bulkAddAttendees": "In bulk deelnemers op e-mailadres toevoegen",
    "findExisting": "Een bestaande gebruiker opzoeken",
    "registeringNew": "Een nieuwe gebruiker registreren",
    "bulkAddByEmailA": "Meerdere deelnemers toevoegen aan deze cursus. Voer e-mailadressen van deelnemers in het onderstaande veld in, één e-mailadres per regel, en klik op de knop 'Deelnemers toevoegen'.",
    "bulkAddByEmailB": "Alleen bestaande Iris-gebruikers worden aan de cursus toegevoegd. U krijgt een lijst met e-mailadressen van deelnemers die niet konden worden toegevoegd.",
    "addAttendeeLabel": "e-mailadressen van deelnemers (één per regel)",
    "addressesDoNotExist": "E-mailadressen van deelnemers bestaan niet in Iris",
    "emailsAreRegistered": "De volgende e-mailadressen zijn geregistreerd als gebruikers",
    "registerNewUser": "Registreer een nieuwe gebruiker en voeg deze toe als deelnemer aan deze cursus. Doe dit alleen als de gebruiker nog niet bestaat in Iris.",
    "firstName": "Voornaam",
    "surname": "Achternaam",
    "noEmailWarning": "Als de deelnemer geen e-mailadres heeft, worden de e-mailberichten verzonden naar de organiserende opleider",
    "registerNewUserAsAttendee": "Nieuwe gebruiker registreren als deelnemer",
    "draftAttendeeList": "De concept lijst met deelnemers",
    "updateAttendeeList": "Weet u zeker dat u de deelnemerslijst wilt bijwerken?",
    "confirmThisAction": "Door deze actie te bevestigen, ontvangen alle nieuw toegevoegde deelnemers een inleidende e-mail over deze cursus.",
    "confirmTitle": "Deelnemerslijst bijwerken?",
    "errorAddingUserMessage": "Er is een fout opgetreden bij het toevoegen van een gebruiker",
    "errorAddingAttendees": "Er is een fout opgetreden bij het toevoegen van deelnemers"
  },
  "courseSessionHost": {
    "attendeeNotes": "Notities van deelnemers",
    "attendeeNotesTooltip": "Deze notities verschijnen in uitnodigingsmail aan nieuwe deelnemers (eenmaal geconfigureerd in e-mailsjablonen). Schrijf in de geselecteerde taal",
    "addCourse": "Cursus toevoegen",
    "noEducatorLicensesDescription": "U heeft op dit moment geen actieve licenties. U hebt een actieve opleiderlicentie nodig om cursussen te geven. Neem contact op met uw regionale trainingsmanager om u een opleiderlicentie te verlenen",
    "locationTooltip": ""
  },
  "courseSessionRouteTags": {
    "viewMyCourses": "Mijn cursussen bekijken",
    "viewScheduledCourses": "Geplande cursussen bekijken",
    "hostCourse": "Een cursus organiseren",
    "scheduleCourse": "Cursus plannen",
    "editCourse": "Cursus bewerken",
    "viewCourse": "Cursus bekijken",
    "viewAttendees": "Deelnemers bekijken",
    "viewCourseHistory": "Cursushistorie bekijken"
  },
  "routeTags": {
    "acceptEducatorTerms": "Algemene voorwaarden voor opleiders accepteren"
  },
  "profileCourses": {
    "started": "Gestart",
    "noCoursesAttended": "Geen cursussen gevolgd",
    "notAttendedAnyCourses": "U hebt geen cursussen gevolgd",
    "validCertLookupAll": "Alle",
    "validCertLookupCurrent": "Huidige geldige certificaten",
    "validCertLookupExpired": "Verlopen",
    "validCertLookupInProgress": "In behandeling"
  },
  "profileLicenses": {
    "noLicenses": "Geen licenties",
    "noLicensesMatch": "Licenties voldoen niet aan criteria",
    "expiry": "Vervaldatum"
  },
  "attendeeEmailInvite": {
    "showEmailPreview": "Voorbeeld uitnodigingsmail weergeven",
    "attendeeEmailPreview": "Voorbeeld e-mail deelnemer",
    "subject": "Onderwerp",
    "htmlContent": "HTML-inhoud",
    "textContent": "Tekstinhoud"
  },
  "myDashboard": {
    "myActions": "Mijn acties",
    "recentCourseRequringApproval": "Recente cursussen vereisen goedkeuring",
    "attendeesAwaitingCertApproval": "Deelnemers wachten op goedkeuring certificering",
    "underMyAdmin": "Onder mijn beheer",
    "recentCourses": "Recente cursussen",
    "upcomingCourses": "Aankomende cursussen",
    "auditsInProgress": "Audits in uitvoering",
    "myRecentCourses": "Mijn recente cursussen",
    "awaitingApproval": "In afwachting van goedkeuring",
    "myUpcomingCourses": "Mijn aankomende cursussen",
    "myQualityAudits": "Mijn kwaliteitsaudits",
    "myAuditsInProgress": "Mijn audits in uitvoering",
    "myUpcomingAudits": "Mijn aankomende audits",
    "recentUpcomingCourses": "Recente en aankomende cursussen",
    "noCoursesSubtitle": "U heeft geen recente cursus er zijn geen cursussen gepland. Klik op onderstaande knop om een cursus te organiseren",
    "activeAudits": "Actieve audits",
    "noAuditsSubtitle": "U heeft geen recente audit en er zijn geen audits gepland.",
    "auditsRequiringMyFeedback": "",
    "assessmentPeriodDueToExpire": "",
    "assessmentsExpireInPastYear": "",
    "correspondence": "Correspondence"
  },
  "fileshare": {
    "courses": "",
    "fileshareCourseMaterials": ""
  },
  "correspondence": {
    "decline": "",
    "validateCorrespondence": "",
    "id": "",
    "emailScheduleType": "",
    "status": "",
    "targetedWorkforce": "Targeted workforce",
    "viewUsers": "",
    "hideUsers": "",
    "attachments": "",
    "approve": "",
    "validate": ""
  },
  "educatorsEmail": {
    "numberOfUser": "",
    "noUsersFound": "",
    "workforce": "Workforce",
    "filters": "Filters"
  },
  "compliance": {
    "compliance": "",
    "complianceCheckEdit": "",
    "complianceCheckAdd": "",
    "compliantOnDate": "",
    "complianceCheckSuccessfullySaved": "",
    "requiredCertificates": "",
    "usersToCheck": "",
    "confirmRemoveRequiredCertificate": "",
    "confirmRemoveUser": "",
    "errorAddingUsers": "",
    "noCertificates": ""
  },
  "educatorList": {
    "numberOfCoursesDelivered": "",
    "numberOfUnionsDeliveredIn": ""
  }
};
