import { HttpService } from "./common/http.service";
import {
  EmailTemplateModel,
  EmailTemplateTypeModel } from "@/core/models";

export const EmailTemplateService = {
  list(locale: string, courseId?: number) {
    const config = { params: { locale, courseId } };
    return HttpService.getData<EmailTemplateTypeModel[]>(`/emailtemplates`, config);
  },

  getTypeById(id: number) {
    return HttpService.getData<EmailTemplateTypeModel>(`/emailtemplates/type/${id}`);
  },

  getById(id: number, locale: string, courseId?: number) {
    const config = { params: { locale, courseId } };
    return HttpService.getData<EmailTemplateModel>(`/emailtemplates/${id}`, config);
  },

  getResponseById(id: number, locale: string, courseId?: number) {
    const config = { params: { locale, courseId } };
    return HttpService.getDataResponse(`/emailtemplates/${id}`, config);
  },

  updateTemplate(id: any, model: EmailTemplateModel) {
    return HttpService.putModel(`/emailtemplates/${id}`, model);
  },
};
