











import { Component, Vue, Prop } from "vue-property-decorator";
import _ from "lodash";

import { UserService } from "@/core/services";
import { UserModel, AutoCompleteItem } from "@/core/models";
import { UserSelector } from ".";
import AppVue from "@/apps/AppVue.vue";

@Component({
  components: {
    UserSelector,
  },
})
export default class UserSelectorDialog extends AppVue {
  @Prop() onSelected?: (user: AutoCompleteItem) => void;
  @Prop() findMethod?: (search: string, isExactEmail: boolean) => Promise<UserModel[]>;
  @Prop() showDialog = false;
  @Prop({ default: true }) useAutoComplete: boolean;

  get isOpen() {
    return this.showDialog;
  }

  onUserSelected(user: AutoCompleteItem) {
    if (this.onSelected) {
      this.onSelected(user);
      this.close();
    }
  }

  close() {
    this.$emit("onClose");
  }
}
