export enum LicenseQualityStatus {
  QaDueImminently = 10,
  QaDue = 20,
  AwaitingQa = 25,
  BelowStandard = 29,
  QaComplete = 30,
}

export enum LicenseAuditStatus {
  AwaitingAudit = 10,
  BelowStandard = 20,
  NeedsDevelopment = 30,
  AllGood = 40,
}

export enum QualityAuditStatus {
  Draft = 10,
  AwaitingAuditeeFeedback = 20,
  AwaitingPublish = 30,
  Published = 40,
}

export enum QualityAuditType {
  Course = 1,
  FaceToFace = 2,
  Online = 3,
}

export enum QualityAuditGrade {
  BelowExpectedStandard = 10,
  ExpectedStandard = 20,
  AboveExpectedStandard = 30,
}

export enum QualityAuditBehaviour {
  LecturerOrPresenter = 10,
  Facilitator = 20,
  Extremefacilitator = 30,
}

export enum QualityAuditListActor {
  Auditor = 1,
  Manager = 2,
}