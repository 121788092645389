import {AuthService} from "@/core/services/auth.service";
import {IMsalPlugin} from "./IMsalPlugin";

export default class MsalPlugin {
  static install(Vue: any, options: any) {
    Vue.prototype.$msal = new MsalPlugin(options, Vue);
  }

  constructor(options: any, Vue?: any) {
    const authService = new AuthService();

    const exposed: IMsalPlugin = {
      isAuthenticated() { return authService.isAuthenticated(); },
      login() { authService.login(); },
      loginTotpMfa() { authService.loginTotpMfa(); },
      logout(redirectToRoute?: string) { authService.logout(redirectToRoute); },
      async getAccessToken() { return await authService.getAccessToken(); },
      getAccount() { return authService.getAccount(); },
      getLoggedInAccount() { return authService.getLoggedInAccount(); },
      getStatus() {
        return `S ${this.isAuthenticated()}`;
      },
    };

    return exposed;
  }

}