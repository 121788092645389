










import { Component} from "vue-property-decorator";
import AppVue from "@/apps/AppVue.vue";

@Component({
  components: {
  },
})
export default class Footer extends AppVue {
}
