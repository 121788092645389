












































































































































import { Component, Watch, Prop } from "vue-property-decorator";
import _ from "lodash";

import {
  LocaleService,
  DefaultLocale,
  EmailTemplateService,
  UploadService,
} from "@/core/services";
import {
  LocaleModel,
  EmailTemplateTypeModel,
  EmailTemplateModel,
  UploadFileModel,
} from "@/core/models";
import {
  BaseFormComponent,
  InputField,
  ValidationHint,
} from "@/components";
import AppVue from "@/apps/AppVue.vue";
import {
  DtInput,
  InputSwitch,
  InputRichText,
} from "@/components";

@Component({
  components: {
    InputField,
    ValidationHint,
    InputRichText,
    InputSwitch,
  },
})
export default class EmailTemplateEditComponent extends BaseFormComponent {
  @Prop() listRoute: string;
  @Prop() courseId?: number;

  selectedLocaleCode: string = DefaultLocale;
  locales: LocaleModel[] = [];
  defaultLocaleCode: string = DefaultLocale;
  emailTemplateType: EmailTemplateTypeModel = this.getNewEmailtemplateType();
  model: EmailTemplateModel = this.getNewEmailtemplate();
  showTemplatePlaceholders: boolean = false;

  created() {
    this.setLocaleCode();
    this.BindEmailTemplate();
  }

  get emailTemplateTypeId(): number {
    return +this.$route.params.templateTypeId;
  }

  get localeCode() {
    return this.$route.params.locale;
  }

  get pageTitle() {
    if (this.emailTemplateType.id > 0) {
      return this.emailTemplateType.name;
    }

    return this.getBreadcrumbTitle();
  }

  get breadcrumbTitle() {
    return this.getBreadcrumbTitle();
  }

  getBreadcrumbTitle() {
    return this.model.emailTemplateId > 0 ? "Edit template" : "Add template";
  }

  @Watch("$route", { deep: true })
  setLocaleCode() {
    this.selectedLocaleCode = this.getInitalLocaleCode();
  }

  getInitalLocaleCode() {
    return this.$route.params.locale != null
      ? this.$route.params.locale
      : DefaultLocale;
  }

  @Watch("$route", { deep: true })
  async BindEmailTemplate() {
    const loading = this.$loading({
      lock: true,
      text: "Getting email template...",
    });

    this.locales = await LocaleService.list();
    this.emailTemplateType = await EmailTemplateService.getTypeById(this.emailTemplateTypeId);
    const response = await EmailTemplateService.getResponseById(
      this.emailTemplateTypeId,
      this.localeCode,
      this.courseId);

    if (response.status === 204) {
      this.model = this.getNewEmailtemplate();
    } else {
      this.model = response.data as EmailTemplateModel;
    }

    loading.close();
  }

  async onSubmitForm() {
    await EmailTemplateService.updateTemplate(this.emailTemplateTypeId, this.model);
    this.$notify({
      title: "Success",
      message: "Email template saved.",
      type: "success",
    });

    this.goToListView();
  }

  onCancel() {
    this.goToListView();
  }

  onChangeLocale() {
    const route = this.$route;
    route.params.locale = this.selectedLocaleCode;

    this.$router.push(route);
  }

  async onFileSelected(file: any, fileList: any) {
    const result = await UploadService.uploadEmailAttachment(file.raw);

    this.model.attachments.push(result);
  }

  onPreviewUploadedFile(file: UploadFileModel) {
    window.open(file.url, "_blank");
  }

  onRemoveUploadedFile(file: UploadFileModel) {
    if (file == null) {
      return;
    }

    const index = _.findIndex(this.model.attachments, {fileName: file.fileName});
    if (index > -1) {
      this.model.attachments.splice(index, 1);
    }
  }

  goToListView() {
    this.$router.push(this.getEmailTemplatesListRoute());
  }

  getEmailTemplatesListRoute() {
    return {
      name: this.listRoute,
      params: {
        locale: this.localeCode,
        id: this.courseId || undefined as any,
      },
    };
  }

  private getNewEmailtemplate(): EmailTemplateModel {
    return {
      emailTemplateId: 0,
      emailTemplateTypeId: this.emailTemplateTypeId,
      locale: this.localeCode,
      subject: "",
      htmlTemplate: "",
      textTemplate: "",
      courseId: this.courseId as any,
      modelState: {},
      attachments: [],
      isActive: false,
    };
  }

  private getNewEmailtemplateType(): EmailTemplateTypeModel {
    return {
      id: 0,
      name: "",
      description: "",
      emailTemplate: undefined,
    };
  }

}
