import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  EndSessionRequest,
  InteractionRequiredAuthError,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
  LogLevel,
  AuthError,
} from "@azure/msal-browser";

import { LoggingService } from "@/core/services";
import store from "@/store/store";
import router from "@/router/router";

const ResetPasswordAuthority = process.env.VUE_APP_B2C_PASSWORD_RESET_AUTHORITY;
const LoginTotpMfaAuthority = process.env.VUE_APP_B2C_SIGN_IN_TOTP_MFA_AUTHORITY;
const CancelErrorCode = "AADB2C90091";
const PasswordResetErorCode = "AADB2C90118";

const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: process.env.VUE_APP_B2C_CLIENT_ID,
    authority: process.env.VUE_APP_B2C_SIGN_IN_AUTHORITY,
    knownAuthorities: [process.env.VUE_APP_B2C_SIGN_IN_AUTHORITY, ResetPasswordAuthority],
    redirectUri: process.env.VUE_APP_REDIRECT_URI + "/login",
    // We have custom code to handle return URL's. We are using custom code as we
    // want to retrieve the logged in user roles before redirecting to
    // the return URL
    navigateToLoginRequestUrl: false,
    // Reset Password Authority
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }

        switch (level) {
          case LogLevel.Error:
            LoggingService.error(message);
            return;
          case LogLevel.Info:
            LoggingService.info(message);
            return;
          case LogLevel.Verbose:
            LoggingService.debug(message);
            return;
          case LogLevel.Warning:
            LoggingService.warn(message);
            return;
        }
      },
    },
  },
};

export const DefaultLogoutRedirectUri = "/hello.html";

export class AuthService {
  private myMSALObj: PublicClientApplication;
  private account: AccountInfo | null;
  private loginRedirectRequest: RedirectRequest;
  private totpLogin = false;

  constructor() {
    window.addEventListener("load", async () => {
      this.loadAuthModule();
    });

    this.myMSALObj = new PublicClientApplication(MSAL_CONFIG);
    this.account = this.getAccount();

    this.loginRedirectRequest = {
      scopes: [process.env.VUE_APP_IRIS_API_WRITE_SCOPE],
      redirectStartPage: window.location.href,
    };
  }

  /**
   * Checks whether we are in the middle of a redirect and handles state accordingly. Only required for redirect flows.
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs
   *   /initialization.md#redirect-apis
   */
  loadAuthModule(): void {
    this.myMSALObj.handleRedirectPromise()
    .then((resp: AuthenticationResult | null) => {
      this.handleResponse(resp);
    })
    .catch((error) => {
      if (error && error instanceof AuthError) {
        const authError = error as AuthError;

        if (this.isOfErrorCode(authError, CancelErrorCode)) {
          // The user has clicked "Cancel" on B2C policy form
          this.goToDefault();
          return;
        } else if (this.isOfErrorCode(authError, PasswordResetErorCode)) {
          // User has requested a password reset - redirect them to tha correct policy
          this.passwordReset();
          return;
        }
      }

      // Log the error
      LoggingService.error(error);
    });
  }

  isOfErrorCode(error: AuthError, errorCode: string) {
    return error.errorCode === "access_denied"
      && error.errorMessage.startsWith(errorCode + ":");
  }

  /**
   * Redirects user to the default route. The default route depends on
   * whether the user is logged in or not
   */
  goToDefault() {
    if (this.isAuthenticated()) {
      router.push("/");
    } else {
      window.location.href = DefaultLogoutRedirectUri;
    }
  }

  /**
   * Calls loginRedirect
   */
  login(): void {
    this.totpLogin = false
    LoggingService.info("Redirect to login");

    this.myMSALObj.loginRedirect(this.loginRedirectRequest);
  }

/**
   * Calls loginRedirect to the TOTP MFA authroity
   */
  loginTotpMfa(): void {
    this.totpLogin = true;
    LoggingService.info("Redirect to login with TOTP MFA");
    const loginTotpMfaRequest: RedirectRequest = {
      scopes: [process.env.VUE_APP_IRIS_API_WRITE_SCOPE],
      authority: LoginTotpMfaAuthority,
      redirectStartPage: "/",
    };

    this.myMSALObj.loginRedirect(loginTotpMfaRequest);
  }

  /**
   * Calls loginRedirect to the password reset authority
   */
  passwordReset(): void {
    LoggingService.info("Redirect to Password reset");
    const passwordResetRequest: RedirectRequest = {
      scopes: [process.env.VUE_APP_IRIS_API_WRITE_SCOPE],
      authority: ResetPasswordAuthority,
      redirectStartPage: "/",
    };

    this.myMSALObj.loginRedirect(passwordResetRequest);
  }

  /**
   * Logs out of current account.
   */
  logout(redirectToRoute: string = DefaultLogoutRedirectUri): void {
    if (!this.isAuthenticated()) {
      return;
    }

    const logOutRequest: EndSessionRequest = {
      account: this.getLoggedInAccount(),
      postLogoutRedirectUri: process.env.VUE_APP_REDIRECT_URI + redirectToRoute,
    };

    this.account = null;
    this.myMSALObj.logout(logOutRequest);
  }

  isAuthenticated(): boolean {
    LoggingService.info("getting Authentication", this.account, this.getAccount());
    return this.account != null || this.getAccount() !== null;
  }

  /**
   * Calls getAllAccounts and determines the correct account to sign into, currently
   * defaults to first account found in cache.
   * TODO: Add account chooser code
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  getAccount(): AccountInfo | null {
    // need to call getAccount here?
    const currentAccounts = this.myMSALObj.getAllAccounts();
    if (currentAccounts === null) {
      LoggingService.info("No accounts detected");
      this.account = null;

      return null;
    }

    if (currentAccounts.length >= 1) {

      if (currentAccounts.length > 1) {
        // Add choose account code here
        LoggingService.warn("Multiple accounts detected, need to add choose account code.");
      }

      if (!this.account) {
        this.account = currentAccounts[0];
      }

      return currentAccounts[0];
    }

    this.account = null;

    return null;
  }

  getLoggedInAccount(): AccountInfo {
    const account = this.getAccount();
    if (account === null) {
      throw new Error("User is not currently logged in");
    }

    return account;
  }

  /**
   * Gets a token silently, or falls back to interactive redirect.
   */
  async getTokenRedirect(silentRequest: SilentRequest, interactiveRequest?: RedirectRequest): Promise<string> {
      try {
          const response = await this.myMSALObj.acquireTokenSilent(silentRequest);
          LoggingService.debug("acquireTokenSilent response", response);
          return response.accessToken;
      } catch (e) {
        LoggingService.error("silent token acquisition fails.", e);
        if (e instanceof InteractionRequiredAuthError) {
          LoggingService.info("acquiring token using redirect");
          this.myMSALObj
            .acquireTokenRedirect(this.loginRedirectRequest)
            .catch(LoggingService.error);
        } else {
          LoggingService.error(e);
        }
      }

      return "";
  }

  async getAccessToken() {
    if (!this.isAuthenticated) {
      return "";
    }

    const silentRequest: SilentRequest = {
      scopes: [process.env.VUE_APP_IRIS_API_WRITE_SCOPE],
      forceRefresh: false,
      account: this.getLoggedInAccount(),
    };

    return await this.getTokenRedirect(silentRequest);
  }

  /**
   * Handles the response from a popup or redirect. If response is null, will check if we have any
   * accounts and attempt to sign in.
   * @param response
   */
  private async handleResponse(response: AuthenticationResult | null) {
    if (response !== null) {
      this.account = response.account;
    } else {
      this.account = this.getAccount();
    }
    LoggingService.info("Handling response", response, this.account, this.getAccount());

    if (this.account) {
      // We are logged in!
      // Now get roles for this user
      store.dispatch("setUserRoles");

      // Once the roles are updated, the /login component will spot this and
      // redirect the user the correct URL
    } else if (response === null) {
      // This is probably caused by an "interaction_in_progress: Interaction is
      // currently in progress" error. The user is not logged in to try again!
      //
      // I can see the error in the console but I've been unable to handle it gracefully
      //
      // We need to handle if this should be a TOTP redirect
      LoggingService.info("Use TOTP ", this.totpLogin);

      if (this.totpLogin) {
        this.loginTotpMfa();
      } else {
        this.login();
      }
    }
  }
}
