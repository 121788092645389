import { HttpService } from "./common/http.service";
import {
  RegionModel,
  RegionLookupModel,
  RegionCollectionModel,
  PagedCollectionFilter,
  PagedCollection,
} from "@/core/models";

export const RegionService = {
  getRegion(id: number) {
    return HttpService.getData<RegionModel>(`/regions/${id}`);
  },

  async postRegion(model: RegionModel) {
    throw new Error("Region creation not yet supported");
  },

  putRegion(model: RegionModel) {
    return HttpService.putModel("/regions", model);
  },

  filterRegions(filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<RegionCollectionModel>>("/regions/filter", { params: filter });
  },

  getFormLookupData() {
    return HttpService.getData<RegionLookupModel>("/regions/formLookupData");
  },
};
