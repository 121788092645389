import Router from "vue-router";

import { StorageUtility } from "@/core/utilities/storage.utility";

export const loginRouteName: string = "login";
export const loginTotpMfaRouteName: string = "loginTotMfa";

export const loginRoutes = [
  {
    path: "/login",
    name: loginRouteName,
    meta: { layout: "empty", allowAnonymous: true },
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/auth/Login.vue"),
  },
  {
    path: "/logintotp",
    name: loginTotpMfaRouteName,
    meta: { layout: "empty", allowAnonymous: true },
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/auth/LoginTotp.vue"),
  },
];

export const LoginRouter = {
  useExternalLogin(): boolean {
    return process.env.VUE_APP_PASSPORT_LOGIN_URL !== "";
  },

  redirectToLogin(to: any, router: Router) {
    // Ensure user not redirected to /login after logging in.
    if (to && to.name !== loginRouteName) {
      StorageUtility.setReturnUrl(to.fullPath);
    }

    router.app.$msal.login();
  },

  redirectToLoginTotpMfa(to: any, router: Router) {
    // Ensure user not redirected to /login after logging in.
    if (to && to.name !== loginTotpMfaRouteName) {
      StorageUtility.setReturnUrl(to.fullPath);
    }

    router.app.$msal.loginTotpMfa();
  },
};
