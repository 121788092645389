import _ from "lodash";
import moment from "moment";
import { HttpService } from "./common";
import {
  ComplianceCheckModel,
  ComplianceFormLookupModel,
  PagedCollection,
  PagedCollectionFilter,
} from "@/core/models";

export const ComplianceService = {
  getComplianceCheck(id: any) {
    return HttpService.getData<ComplianceCheckModel>(`/compliance-check/${id}`);
  },

  filter(filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<ComplianceCheckModel>>("/compliance-check/filter", { params: filter });
  },

  add(model: ComplianceCheckModel) {
    return HttpService.postModel(`/compliance-check`, model);
  },

  update(model: ComplianceCheckModel) {
    return HttpService.putModel(`/compliance-check`, model);
  },

  getEditLookups() {
    return HttpService.getData<ComplianceFormLookupModel>(`/compliance-check/edit/lookups`);
  },

  getUnmetConditions(userId: number, model: ComplianceCheckModel) {
    const courseIds = _.map(model.courses, (course) => {
      return course.courseId;
    })
    const courseIdList = _.join(courseIds, ",");
    const checkDateParam = !model.complianceCheckOn
      ? ""
      : moment(model.complianceCheckOn).format('YYYY-MM-DD');

    return HttpService.getData<string[]>(
      `compliance-check/check/user/${userId}?checkOnDate=${checkDateParam}&courseIds=${courseIdList}`,
    );
  },

};
