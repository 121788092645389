import { HttpService } from "./common/http.service";
import {
  UnionModel,
  UnionLookupModel,
  UnionCollectionModel,
  SimpleLookupModel,
  PagedCollectionFilter,
  PagedCollection,
} from "@/core/models";

export const UnionService = {
  getUnion(id: any) {
    return HttpService.getData<UnionModel>(`/unions/${id}`);
  },

  async postUnion(model: UnionModel) {
    throw new Error("Union creation not yet supported");
  },

  putUnion(model: UnionModel) {
    return HttpService.putModel("/unions", model);
  },

  filterUnions(filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<UnionCollectionModel>>("/unions/filter", { params: filter });
  },

  getAsLookups() {
    return HttpService.getData<SimpleLookupModel[]>(`/unions`);
  },

  getFormLookupData() {
    return HttpService.getData<UnionLookupModel>("/unions/formLookupData");
  },
};
