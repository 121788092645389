import { RoleName } from "@/core/constants";

export const regionRoutes = [
  {
    path: "/regions",
    name: "regionList",
    meta: {
      title: "Regions",
      roles: `${RoleName.GlobalTrainingManager},` +
        `${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "region" */ "@/views/region/RegionList.vue"),
  },
  {
    path: "/regions/:regionId/edit",
    name: "regionForm",
    meta: {
      title: "Region Edit",
      roles: `${RoleName.GlobalTrainingManager},` +
        `${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "region" */ "@/views/region/RegionForm.vue"),
  },
];
