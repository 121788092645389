






import { Vue, Component, Prop } from "vue-property-decorator";
import _ from "lodash";

import { Validatable } from "@/core/models";

@Component
export default class ValidationHint extends Vue {
  @Prop() model: Validatable;

  get showHint() {
    if (!this.model || !this.model.modelState) {
      return false;
    }

    return _.filter(this.model.modelState, (item: any) => !!item).length > 0;
  }
}
