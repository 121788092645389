













import { Component, Vue } from "vue-property-decorator";
import {
  Sidebar,
  Header,
  Footer,
 } from "@/components/"; // @ is an alias to /src
import AppVue from "@/apps/AppVue.vue";

@Component({
  components: {
    Sidebar,
    Header,
    Footer,
  },
})
export default class Default extends AppVue {
}
