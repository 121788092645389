











import { Component, Prop } from "vue-property-decorator";

import {
  ValidationMessage,
  BaseInputComponent,
} from ".";
import LabelWithTooltip from "./LabelWithTooltip.vue";

@Component({
  components: {
    ValidationMessage,
    LabelWithTooltip,
  },
})
export default class InputSwitch extends BaseInputComponent {
  @Prop({ default: "Yes" }) activeText: string;
  @Prop({ default: "No" }) inactiveText: string;
}
