







































































import { Component, Vue, Prop } from "vue-property-decorator";
import _ from "lodash";

import { UserService } from "@/core/services";
import { UserModel, AutoCompleteItem } from "@/core/models";
import AppVue from "@/apps/AppVue.vue";

@Component
export default class UserSelector extends AppVue {
  @Prop() onSelected?: (user: AutoCompleteItem) => void;
  @Prop() findMethod?: (search: string, isExactEmail: boolean) => Promise<UserModel[]>;
  @Prop({ default: true }) useAutoComplete: boolean;

  state = "";
  foundUsers: UserModel[] | null = null;
  isExactEmail: boolean = true;
  resultsPopoverVisible: boolean = false;
  showSpinner = false;

  get searchInputPlaceholder() {
    return this.isExactEmail
      ? "person1@company.com"
      : this.localiseString("userAdmin.searchAttendee");
  }

  created() {
    this.isExactEmail = !this.useAutoComplete;
  }

  async findUsers() {
    this.foundUsers = null;
    this.showSpinner = true;
    this.resultsPopoverVisible = true;

    const users = this.findMethod
      ? await this.findMethod(this.state, this.isExactEmail)
      : await UserService.find(this.state, this.isExactEmail);

    if (this.isExactEmail && users.length === 1) {
      // We have got a user based on exact email, so autoselect the user
      this.onSelectUser(users[0]);
    }

    this.foundUsers = users;
    this.showSpinner = false;
  }

  async find(search: string,  callback: (items: AutoCompleteItem[]) => any) {
    if (!search) {
      callback([]);
      return;
    }

    this.foundUsers = null;

    const users = this.findMethod
      ? await this.findMethod(search, this.isExactEmail)
      : await UserService.find(search, this.isExactEmail);

    this.foundUsers = users;
    callback(this.formatUserList(users));
  }

  onSelectedInternal(user: AutoCompleteItem) {
    if (this.onSelected) {
      this.onSelected(user);
      this.state = "";
    }
  }

  onSelectUser(user: UserModel) {
    const item = {
      id: user.userId,
      value: user.email,
    } as AutoCompleteItem;
    this.resultsPopoverVisible = false;

    this.onSelectedInternal(item);
  }

  private formatUserList(users: UserModel[]) {
    return _.map(users, (user) => {
      const name = user.fullName;
      const union = user.unionName ? ` - ${user.unionName}` : ``;
      const email = ` (${user.email})`;

      return {
        id: user.userId,
        value: `${name}${union}${email}`,
      };
    });
  }
}
