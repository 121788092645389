


















import { Component, Prop, Watch } from "vue-property-decorator";
import _ from "lodash";

import { AutoCompleteItem } from "@/core/models";
import { ValidationMessage, SelectableInputComponent } from ".";
import LabelWithTooltip from "./LabelWithTooltip.vue";

@Component({
  components: {
    ValidationMessage,
    LabelWithTooltip,
  },
})
export default class InputAutocomplete extends SelectableInputComponent {
  @Prop() onSelect: (item: AutoCompleteItem) => void;
  state = "";

  @Watch("lookups")
  onLookupsChanged(val: any, oldVal: any) {
    // This is pretty much for when lookups get loaded asynchronously
    // from the backend to potentially "pre-select" a value
    this.state = this.getSelectedText();
  }

  fetchSuggestions(query: string, callback: (items: AutoCompleteItem[]) => any) {
    if (query !== this.getSelectedText()) {
      // Clear model prop if user changed the query
      (this.model as any)[this.prop] = undefined;
    }

    const filtered = super.filterLookups(this.lookups, query);
    callback(super.toAutocompleteFormat(filtered));
  }

  onFocus() {
    if (!this.prop || !this.model || !this.model.modelState) {
      return;
    }

    this.model.modelState[this.prop] = null as any;
  }

  onSelectInternal(item: AutoCompleteItem) {
    (this.model as any)[this.prop] = item.id;

    if (this.onSelect) {
      this.onSelect(item);
    }
  }

  private getSelectedText() {
    if (!this.model || !this.prop) {
      return null;
    }

    const selectedId = (this.model as any)[this.prop];
    const selected = _.find(this.lookups, { id: selectedId });

    return selected ? this.getLookupItemText(selected) : null;
  }
}
