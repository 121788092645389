























import CourseAppVue from "@/apps/CourseAppVue.vue";
import { Component, Prop } from "vue-property-decorator";

import { CourseSessionStatus } from "@/core/constants";
import {
  CourseSessionModel,
} from "@/core/models";
import { forEach } from "lodash";
import {
  CourseSessionService,
} from "@/core/services";

@Component({name: "CourseSessionNavigation"})
export default class CourseSessionNavigation extends CourseAppVue {
  @Prop() courseSession: CourseSessionModel;

  get showNavigation() {
    return this.courseSession.courseSessionId !== undefined;
  }

  get showAttendees() {
    return this.courseSession.courseSessionStatusId >= CourseSessionStatus.Approved;
  }

  get isAdminView() {
    return this.$route.query.adminView === "y";
  }

  get attendeesCount() {
    return this.courseSession.attendeeCount;
  }

  getCourseSessionDetailsRoute() {
    return this.isAdminView
      ? this.getRoute("courseSessionEdit")
      : this.getRoute("courseSessionView");
  }

  getCourseSessionAttendeesRoute() {
    return this.getRoute("courseSessionAttendees");
  }

  getCourseSessionHistoryRoute() {
    return this.getRoute("courseSessionHistory");
  }

  getCourseSessionMaterialsRoute() {
    return this.getRoute("courseSessionMaterials");
  }

  getAdminViewQueryValue() {
    return this.isAdminView ? "y" : "n";
  }

  getRoute(name: string) {
    const route = {
      name,
      params: {
        id: this.$route.params.id,
        locale: this.courseSession.locale,
      },
      query: {
        adminView: this.getAdminViewQueryValue(),
      },
    };

    return route;
  }

  getActiveindex() {
    return this.$route.name;
  }
}
