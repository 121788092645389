
import _ from "lodash";

import AppVue from "@/apps/AppVue.vue";
import { GridModel, PagedCollection, PagedCollectionFilter, PagedCollectionFilterDictionary } from "@/core/models";
import { GridService } from "@/core/services";

export default abstract class BaseGridComponent<T> extends AppVue {
  grid: GridModel<T>;
  gridFilter: PagedCollectionFilter;

  /**
   * Initializes the grid component. This method is supposed to be called from
   * the class that's implementing the BaseGridComponent class.
   * Builds a grid object that contains filter, collection and event handlers.
   *
   * @param filterMethod - Filter method to be called on filter change to fetch new data.
   * @param routeName - Route name to transition to on filter change (optional).
   * @param filter - Initial grid filter (optional, if not passed, default queryParams will be used).
   */
  protected initialize(
    filterMethod: (filter: PagedCollectionFilter) => Promise<PagedCollection<T>>,
    routeName?: string,
    filter?: PagedCollectionFilter,
  ) {
    this.gridFilter = !!filter
      ? filter
      : this.defaultQueryParamsFactory(routeName);

    // Initialize the shared grid model
    this.grid = GridService.gridFactory<T>(this.gridFilter);

    // Prevent duplication of events, will potentially have to
    // improve this in the future, but for now this is ok
    this.$eventHub.$off("GRID_FILTER_UPDATED");

    // On grid interaction, update the route and re-fetch data
    this.$eventHub.$on("GRID_FILTER_UPDATED", () => {
      if (!!routeName) {
        this.$store.state.listFilters[routeName] = this.gridFilter;
      }

      this.updateRoute(this.gridFilter, routeName);
      this.filterCollection(filterMethod, this.gridFilter);
    });

    // Initialize the first fetch
    this.$eventHub.$emit("GRID_FILTER_UPDATED");
  }

  /**
   * Builds default query params filter.
   *
   * If a filter for this route is stored in Vuex state, and there are no querystring params
   * specified in the URL, then the saved filter is returned. Otherwise the default filter params
   * are returned/
   *
   * If any additional params need to be added, or any of the
   * default ones changed - override/extend the query params passed to the initializeGrid() in the
   * concrete grid implementation class.
   */
  private defaultQueryParamsFactory(routeName?: string) {
    if (
      !!routeName // a route is specified
      && !!this.$store.state.listFilters[routeName] // A filter is stored for this route in state
      && Object.keys(this.$route.query).length === 0 // No filter params are specfied in URL
    ) {
      // return the filter that is stored in state for this route
      return this.$store.state.listFilters[routeName];
    }

    return GridService.queryParamsFactory(this.$route.query);
  }

  /**
   * Updates the current grid collection route with currently defined query params (based on filter).
   * @param filter - Current grid collection filter.
   * @param route - Route name to transition to on filter change.
   */
  private updateRoute(filter: PagedCollectionFilter, routeName?: string) {
    if (routeName) {
      const definedParams = this.getDefinedParams(filter);
      this.$router.push({ name: routeName, query: definedParams as any });
    }
  }

  /**
   * Handles the actual data fetching/filtering.
   * @param filterMethod - Filter method to be called on filter change to fetch new data.
   * @param filter - Current grid collection filter.
   */
  private filterCollection(
    filterMethod: (filter: PagedCollectionFilter) => Promise<PagedCollection<T>>,
    filter: PagedCollectionFilter,
  ) {
    const loading = this.$loading({
      lock: true,
      text: this.localiseString("common.gettingData") + "...",
    });

    const definedParams = this.getDefinedParams(filter);

    filterMethod(definedParams).then((collection) => {
      _.extend(this.grid.collection, collection);
    }).catch(() => {
      _.extend(this.grid.collection, {
        pageCount: 0,
        recordCount: 0,
        items: [],
      });
    }).finally(() => {
      loading.close();
      this.$eventHub.$emit("GRID_DATA_UPDATED");
      this.$forceUpdate();
    });
  }

  private getDefinedParams(filter: PagedCollectionFilter) {
    // Only filter out params which are actually set, ignore unused ones
    return _.pickBy(this.grid.filter, _.identity);
  }
}
