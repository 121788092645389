






























import { Component, Prop, Watch } from "vue-property-decorator";

import AppVue from "@/apps/AppVue.vue";
import {
  BaseFormComponent,
  CourseMenu,
  InputField,
  InputSwitch,
  ValidationHint,
} from "@/components";
import {
  CourseLocaleModel,
} from "@/core/models";
import {
  CourseLocaleService,
} from "@/core/services";

@Component({
  components: {
    CourseMenu,
    InputField,
    InputSwitch,
    ValidationHint,
  },
})
export default class LocalisationById extends BaseFormComponent {
  @Prop() courseId: number;
  @Prop() defaultName: string;
  model: CourseLocaleModel[] = [];

  async created() {
    await this.bindData();
  }

  @Watch("$route", { deep: true })
  async bindData() {
    const loading = this.$loading({
      lock: true,
      text: "Getting translations...",
    });

    this.model = await CourseLocaleService.GetByCourseId(this.courseId);

    loading.close();
  }

  async onSubmitForm() {
    try  {
      await CourseLocaleService.save(this.courseId, this.model);
      await this.bindData();

      this.$notify({
        title: "Success",
        message: "Translations saved.",
        type: "success",
      });
    } catch (err: any) {
      if (err.response.status !== 422) {
        this.$notify({
          title: "Error",
          message: "An error occurred when updating translations",
          type: "error",
        });
      }
    }
  }

  getTranslationLabel(translation: CourseLocaleModel) {
    return `${translation.locale.name} (${translation.locale.isoCode})`;
  }
}
