import { HttpService } from "./common/http.service";
import _ from "lodash";

import {
  CourseSessionModel,
  CourseCollectionModel,
  CourseMaterialModel,
  CourseSessionCollectionModel,
  CourseSessionHostFormLookupModel,
  CourseSessionAttendanceSetStatusModel,
  CourseOwnSessionGridLookupModel,
  CourseScheduledSessionGridLookupModel,
  CourseSessionAttendeeCollectionModel,
  CourseSessionAttendeeUpdateList,
  EmailTemplateModel,
  PagedCollectionFilter,
  PagedCollection,
  SimpleAuditRecordCollectionModel,
  SimpleLookupModel,
  CheckMultiUserModel,
  CheckMultiUserResponseModel,
  AddUserModel,
} from "@/core/models";

export const CourseSessionService = {
  getCourseSession(id: number) {
    return HttpService.getData<CourseSessionModel>(`/courseSessions/${id}`);
  },

  getCourseSessionHistory(id: number) {
    return HttpService.getData<SimpleAuditRecordCollectionModel[]>(`/courseSessions/${id}/history`);
  },

  getOwnLookupData() {
    return HttpService.getData<CourseOwnSessionGridLookupModel>(`/courseSessions/own/lookupData`);
  },

  getCourseLocales(courseId: number|undefined) {
    if (!courseId) {
      return [];
    }

    return HttpService.getData<SimpleLookupModel[]>(`/courses/${courseId}/locale/lookups`);
  },

  getScheduledLookupData() {
    return HttpService.getData<CourseScheduledSessionGridLookupModel>(`/courseSessions/scheduled/lookupData`);
  },

  filterOwnCourseSessions(filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<CourseSessionCollectionModel>>(
      "/courseSessions/filterOwn",
      { params: filter });
  },

  filterScheduledCourseSessions(filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<CourseSessionCollectionModel>>(
      "/courseSessions/filterScheduled",
      { params: filter });
  },

  getCoursesByEducator(date: Date) {
    return HttpService.getData<CourseCollectionModel[]>(`/courseSessions/courseList?date=${date}`);
  },

  getHostFormLookupByEducator(
    licenseId: number | undefined,
    id: number | null = null) {

    const params = {
        licenseId,
        id,
    };

    return HttpService.getData<CourseSessionHostFormLookupModel>(
      "/courseSessions/hostFormLookupData",
      { params });
  },

  getScheduleFormLookupByEducator(id: number | null = null) {
    const params = { id };
    return HttpService.getData<CourseSessionHostFormLookupModel>(
      `/courseSessions/scheduleFormLookupData`,
      { params });
  },

  hostSession(model: CourseSessionModel) {
    if (!!model.courseSessionId) {
      // Course validation rules will be checked if provided
      model.course = undefined;

      return HttpService.putModel("/courseSessions/host", model);
    }

    return HttpService.postModel("/courseSessions/host", model);
  },

  scheduleSession(model: CourseSessionModel) {
    return HttpService.postModel("/courseSessions/schedule", model);
  },

  approveSession(id: number) {
    return HttpService.put(`/courseSessions/approve/${id}`);
  },

  rejectSession(id: number) {
    return HttpService.delete(`/courseSessions/${id}`);
  },

  deleteSession(id: number) {
    return HttpService.delete(`/courseSessions/${id}/delete`);
  },

  getAttendees(courseSessionId: number) {
    return HttpService.getData<CourseSessionAttendeeCollectionModel[]>(
      `/courseSessions/${courseSessionId}/attendees`);
  },

  getAttendeeStatuses(courseSessionId: number) {
    return HttpService.getData<SimpleLookupModel[]>(
      `/courseSessions/${courseSessionId}/attendees/statuses`);
  },

  setAttendanceStatus(model: CourseSessionAttendanceSetStatusModel) {
    return HttpService.put(`/courseSessions/setAttendanceStatus`, model);
  },

  updateAttendeeList(model: CourseSessionAttendeeUpdateList) {
    return HttpService.put("/courseSessions/updateAttendeeList", model);
  },

  getUnmetConditions(courseSessionId: number, userId: number) {
    return HttpService.getData<string[]>(
      `/courseSessions/${courseSessionId}/unmetConditions/${userId}`,
    );
  },

  getMaterials(courseSessionId: number, locale: string) {
    return HttpService.getData<CourseMaterialModel[]>(
      `/courses/materials/session/${courseSessionId}/${locale}`,
    );
  },

  checkAttendees(courseSessionId: number, model: CheckMultiUserModel) {
    return HttpService.postData<CheckMultiUserResponseModel>(
      `/courseSessions/${courseSessionId}/checkAttendees`,
      model,
    );
  },

  getAttendeeEmailPreview(model: CourseSessionModel) {
    const courseSession = _.clone(model);
    courseSession.course = undefined;

    return HttpService.postData<EmailTemplateModel>(
      `/courseSessions/attendee-email/preview`,
      courseSession,
    );
  },

  addUser(courseSessionId: number, model: AddUserModel) {
    return HttpService.postData<number>(`/courseSessions/${courseSessionId}/addNewUser`, model);
  },
};
