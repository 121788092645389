













































































































































import { Component, Prop, Watch } from "vue-property-decorator";

import {
  QuestionnaireService,
  DefaultLocale,
} from "@/core/services";
import {
  InputSwitch,
} from "@/components";
import BaseFormComponent from "@/components/common/form/BaseFormComponent.vue";
import {
  QuestionnaireModel,
  QuestionnaireQuestionModel,
  QuestionnaireLookupModel,
} from "@/core/models";

@Component({
  components: {
    InputSwitch,
  },
})
export default class QuestionnaireEditForm extends BaseFormComponent {
  @Prop({ default: null }) courseId: number;
  defaultLocaleCode: string = DefaultLocale;
  selectedLocaleCode: string = DefaultLocale;
  model: QuestionnaireModel = this.getDefaultQuestionnaire();
  validationErrors: any = {};
  isActiveOnDb: boolean = true;
  lookups: QuestionnaireLookupModel = {
    locales: [],
    questionTypes: [],
  };

  get locale() {
     return this.$route.params.locale != null
      ? this.$route.params.locale
      : DefaultLocale;
  }

  get isDefaultLocaleSelected(): boolean {
    return this.locale === this.defaultLocaleCode;
  }

  get hasAlternativeModel(): boolean {
    return !this.isDefaultLocaleSelected && this.model.alternativeLanguageQuestionnaire != null;
  }

  get questionsErrorModel() {
   return this.validationErrors.questions;
  }

  getQuestionErrorModel(index: number, property: string) {
    if (this.validationErrors &&
      this.validationErrors[`questions[${index}].${property}`]) {
      return this.validationErrors[`questions[${index}].${property}`];
    }

    return null;
  }

  async created() {
    await this.getQuestionnaire();
  }

  @Watch("$route", { deep: true })
  async getQuestionnaire() {
    this.selectedLocaleCode = this.locale;
    const loading = this.$loading({
      lock: true,
      text: "Getting questionnaire...",
    });

    this.lookups = await QuestionnaireService.getLookups();
    this.model = await QuestionnaireService.getQuestionnaireByCourseId(this.courseId, this.locale);
    this.isActiveOnDb = this.model.isActive;
    this.model.modelState = {};

    loading.close();
  }

  async onSubmitForm() {

    try {
      const response = await QuestionnaireService.update(this.model);
      this.model = response.data as QuestionnaireModel;
      this.isActiveOnDb = this.model.isActive;
      this.validationErrors = {};

      this.$notify({
        title: "Success",
        message: "Questionnaire saved.",
        type: "success",
      });
    } catch (err: any) {
      this.validationErrors = this.model.modelState as object;

      if (err.response.status !== 422) {
        this.$notify({
          title: "Error",
          message: "An error occurred when updating questionnaire",
          type: "error",
        });
      }
    }
  }

  addNewQuestion(): void {
    this.model.questions.push(this.getDefaultQuestionnaireQuestion());
  }

  removeQuestion(index: number): void {
    this.model.questions.splice(index, 1);
  }

  onChangeLocale() {
    const route = this.$route;
    route.params.locale = this.selectedLocaleCode;

    this.$router.push(route);
  }

  private getDefaultQuestionnaire() {
    return  {
      questionnaireId: null,
      courseId: this.courseId,
      isActive: false,
      localeCode: this.defaultLocaleCode,
      questions: [],
      modelState: { },
      alternativeLanguageQuestionnaire: {} as QuestionnaireModel,
    } as QuestionnaireModel;
  }

  private getDefaultQuestionnaireQuestion() {
    return  {
      questionnaireQuestionId: null,
      questionIdentifier: "",
      questionnaireQuestionTypeId: 1,
      content: "",
    } as QuestionnaireQuestionModel;
  }
}
