





















































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Placeholder,
  Image,
} from "tiptap-extensions";

import { ValidationMessage, BaseInputComponent } from ".";
import LabelWithTooltip from "./LabelWithTooltip.vue";

@Component({
  components: {
    EditorContent,
    EditorMenuBar,
    LabelWithTooltip,
    ValidationMessage,
  },
})
export default class InputRichText extends BaseInputComponent {
  @Prop({ default: false }) allowImages: boolean;
  linkUrl: string = "";
  imageUrl: string = "";
  isMarkupCodeVisible: boolean = false;
  editor: Editor;

  created() {
    this.editor = this.getEditor();
  }

  get contentMarkup() {
    return (this.model as any)[this.prop];
  }

  set contentMarkup(value: any) {
    (this.model as any)[this.prop] = value;
  }

  showLinkInput(attrs: any, command: any) {
    const title = attrs.href === undefined
      ? "Add link"
      : "Update link";

    this.$prompt("Please input your link URL (::SESSION-QUESTIONNAIRE-URL:: placeholder also accepted)", title, {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      /* tslint:disable-next-line */
      inputPattern: /(::SESSION-QUESTIONNAIRE-URL::)|(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))/,
      inputErrorMessage: "Invalid URL",
      inputPlaceholder: "https://",
      inputValue: attrs.href,
    }).then(({ value }: any) => {
      command({ href: value});
    }).catch(() => {
      this.linkUrl = "";
    });
  }

  showImageInput(command: any) {
    const title = "Add Image";

    this.$prompt("Please input your image URL", title, {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      /* tslint:disable-next-line */
      inputPattern: /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))/,
      inputErrorMessage: "Invalid URL",
      inputPlaceholder: "https://",
      inputValue: "",
    }).then(({ value }: any) => {
      command({ src: value});
    }).catch(() => {
      this.imageUrl = "";
    });
  }

  @Watch("contentMarkup")
  onModelChanged(val: any, oldVal: any) {
    // Note: fore some reason, boolean compomenent member variables and editor
    // variables are reset to default value (i.e. not what set to by component)
    // in this watcher. That is why we use a string value for $status, rather than
    // a boolean
    if (this.editor && this.editor.$status !== "update") {
      this.editor.setContent(val, true);
    }

    this.editor.$status = "";
  }

  onOpenCodeDialog() {
    this.isMarkupCodeVisible = true;
    this.editor.$updateStatus = "markup";
  }

  onCloseCodeDialog() {
    this.isMarkupCodeVisible = false;
    this.editor.$updateStatus = undefined;
  }

  beforeDestroy() {
    this.editor.destroy();
  }

  private getEditor() {
    return new Editor({
      content: this.contentMarkup,
      onUpdate: ({ getHTML }: any) => {
        if (!this.editor.$updateStatus) {
          this.editor.$status = "update";
          this.contentMarkup = getHTML();
        }

        // This should clear the is-error class for the form item but it doesn't
        (this.$refs.inputRichTextFormItem as any).clearValidate();
      },
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
        new Image(),
        new Placeholder({
          emptyNodeClass: "is-empty",
          emptyNodeText: !!this.placeholder ? this.placeholder : "Add HTMP template here",
          showOnlyWhenEditable: true,
        }),
      ],
    });
  }
}
