import { RoleName } from "@/core/constants";

export const unionRoutes = [
  {
    path: "/unions",
    name: "unionList",
    meta: {
      title: "Unions",
      roles: `${RoleName.RegionalTrainingManager},` +
        `${RoleName.GlobalTrainingManager},` +
        `${RoleName.IrisAdministrator}`,
      },
    component: () =>
      import(/* webpackChunkName: "union" */ "@/views/union/UnionList.vue"),
  },
  {
    path: "/unions/:unionId/edit",
    name: "unionForm",
    meta: {
      title: "Union Edit",
      roles:  `${RoleName.RegionalTrainingManager},` +
        `${RoleName.GlobalTrainingManager},` +
        `${RoleName.IrisAdministrator}`,
      },
    component: () =>
      import(/* webpackChunkName: "union" */ "@/views/union/UnionForm.vue"),
  },
];
