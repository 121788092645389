import { CourseSessionCollectionModel, QualityAuditCollectionModel } from ".";

export class DashboardModel {
  isAdministrator: boolean = false;
  administrationAreaType: string = "";
  administrationAreaName: string = "";
}

export class CourseSessionDashboardModel {
  myRecentCourses: number = 0;
  myUpcomingCourses: number = 0;
  myCoursesToApprove: number = 0;
  recentCoursesInMyArea: number | null = null;
  upcomingCoursesInMyArea: number | null = null;
  coursesToApproveInMyArea: number | null = null;
  attendeesToCertify: number | null = null;
  assessmentsExpiredInPreviousYear: number = 0;
  assessmentsDueToExpire: number = 0;
  assessmentsExpiredInPreviousYearInMyArea: number = 0;
  assessmentsDueToExpireInMyArea: number = 0;
  recentAndUpcoming: CourseSessionCollectionModel[] = [];
}

export class AuditDashboardModel {
  auditsRequiringMyFeedback: number = 0;
  myAuditsInProgress: number = 0;
  myUpcomingAudits: number = 0;
  auditsInProgressInMyArea: number | null = null;
  upcomingAuditsInMyArea: number | null = null;
  hasAuditLicense: boolean = false;
  unpublishedAudits: QualityAuditCollectionModel[] = [];
}

export class CorrespondenceDashboardModel {
  correspondenceAwaitingApproval: number = 0;
}
