







import { Vue, Component, Emit, Prop } from "vue-property-decorator";

import { GridModel } from "@/core/models";

@Component
export default class GridCheckboxFilter extends Vue {
  @Prop() grid: GridModel<any>;
  @Prop() prop: string;
  @Prop({ default: "CHECKBOX LABEL" }) label: string;

  onChange(value: any) {
    this.$forceUpdate();

    if (!this.grid.preventAutoSubmit) {
      this.grid.onFilter();
    }

    this.$emit("change", value);
  }
}
