import { RoleName } from "@/core/constants";

export const courseListRouteName = "courseList";
export const courseDetailsRouteName = "courseDetails";
export const courseExamQuestionPagesRouteName = "courseExamQuestionPages";
export const courseExamQuestionPageEditRouteName = "courseExamQuestionPageEdit";
export const courseExamQuestionsRouteName = "courseExamQuestions";
export const courseExamQuestionEditRouteName = "courseExamQuestionEdit";
export const courseAddRouteName = "courseAdd";
export const courseEmailTemplateListRouteName = "courseEmailTemplateList";
export const courseEmailTemplateEditRouteName = "courseEmailTemplateEdit";
export const courseLocalisationRouteName = "courseLocalisation";
export const courseCertificatesName = "courseCertificates";
export const courseCertificateTemplatesName = "courseCertificateTemplates";
export const courseCertificateTemplateEditName = "courseCertificateTemplateEdit";
export const courseCertificateTemplateLocaleEditName = "courseCertificateTemplateLocaleEdit";
export const courseMaterialName = "courseMaterials";
export const courseTranscriptsName = "courseTranscripts";
export const courseTranscriptsAddName = "courseTranscriptsAdd";
export const courseTranscriptsViewName = "courseTranscriptsView";
export const courseTranscriptsEditName = "courseTranscriptsEdit";

const courseTranscriptForm = () =>
  import(/* webpackChunkName: "course" */ "@/views/course/courseTranscriptForm.vue");

export const courseRoutes = [
  {
    path: "/courses",
    name: courseListRouteName,
    meta: {
      title: "Curriculum subjects",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/course/courseList.vue"),
  },
  {
    path: "/courses/:id/details",
    name: courseDetailsRouteName,
    meta: {
      title: "Subject Details",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/course/courseSettings.vue"),
  },
  {
    path: "/courses/:id/exam-questions/",
    name: courseExamQuestionPagesRouteName,
    meta: {
      title: "Subject Exam Pages",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/course/courseExamQuestionPages.vue"),
  },
  {
    path: "/courses/:id/exam-questions/page/:pageId?",
    name: courseExamQuestionPageEditRouteName,
    meta: {
      title: "Edit Exam Pages",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/course/courseExamPageEdit.vue"),
  },
  {
    path: "/courses/:id/exam-questions/:pageId/:locale?",
    name: courseExamQuestionsRouteName,
    meta: {
      title: "Subject Exam Page Questions",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/course/courseExamQuestions.vue"),
  },
  {
    path: "/courses/:id/exam-questions/:pageId/edit/:locale/:questionId?",
    name: courseExamQuestionEditRouteName,
    meta: {
      title: "Edit Exam Question",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/course/courseExamQuestionEdit.vue"),
  },
  {
    path: "/courses/add",
    name: courseAddRouteName,
    meta: {
      title: "Add a subject",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/course/addCourse.vue"),
  },
  {
    path: "/courses/:id/email-templates/:locale?",
    name: courseEmailTemplateListRouteName,
    meta: {
      title: "Email templates",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/course/courseEmailTemplateList.vue"),
  },
  {
    path: "/courses/:id/email-templates/edit/:templateTypeId/:locale?",
    name: courseEmailTemplateEditRouteName,
    meta: {
      title: "Edit email template",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/course/courseEmailTemplateEdit.vue"),
  },
  {
    path: "/courses/:id/questionnaires/:locale?",
    name: "courseQuestionnaire",
    meta: {
      title: "Course questionnaire",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/course/courseQuestionnaire.vue"),
  },
  {
    path: "/courses/:id/localisation",
    name: courseLocalisationRouteName,
    meta: {
      title: "Subject localisation",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/course/courseLocalisation.vue"),
  },
  {
    path: "/courses/:id/certificates",
    name: courseCertificatesName,
    meta: {
      title: "Subject certificates",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/course/courseCertificates.vue"),
  },
  {
    path: "/courses/:id/certificate-templates",
    name: courseCertificateTemplatesName,
    meta: {
      title: "Certificate templates",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/course/courseCertificateTemplates.vue"),
  },
  {
    path: "/courses/:id/certificate-templates/edit/:certificateTemplateId?",
    name: courseCertificateTemplateEditName,
    meta: {
      title: "Edit Certificate template",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/course/courseCertificateTemplateEdit.vue"),
  },
  {
    path: "/courses/:id/certificate-templates/edit/:certificateTemplateId/locales/:certificateTemplateLocaleId?",
    name: courseCertificateTemplateLocaleEditName,
    meta: {
      title: "Edit Certificate template",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/course/courseCertificateTemplateLocaleEdit.vue"),
  },
  {
    path: "/courses/:id/materials/:locale?",
    name: courseMaterialName,
    meta: {
      title: "Subject materials",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/course/courseMaterials.vue"),
  },
  {
    path: "/courses/:id/transcripts",
    name: courseTranscriptsName,
    meta: {
      title: "Subject transcripts",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/course/courseTranscripts.vue"),
  },
  {
    path: "/courses/:id/transcripts/add",
    name: courseTranscriptsAddName,
    meta: {
      title: "Add a subject transcript",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: courseTranscriptForm,
  },
  {
    path: "/courses/:id/transcripts/:transcriptId/:locale",
    name: courseTranscriptsViewName,
    meta: {
      title: "View a subject transcript",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: courseTranscriptForm,
  },
  {
    path: "/courses/:id/transcripts/:transcriptId/edit/:locale",
    name: courseTranscriptsEditName,
    meta: {
      title: "Edit a course transcript",
      roles: `${RoleName.CourseEditor},${RoleName.IrisAdministrator}`,
    },
    component: courseTranscriptForm,
  },
];
