import { defaultLanguage } from "@/i18n";

const returnUrlKey: string = "return-url-to";
const SelectedLanguageKey: string = "SelectedLanguage";

export const StorageUtility = {

  getStorage(): Storage {
    return sessionStorage;
  },

  setReturnUrl(returnUrl: string) {
    this.getStorage().setItem(returnUrlKey, returnUrl);
  },

  getAndRemoveReturnUrl(): string {
    const value = this.getStorage().getItem(returnUrlKey);
    this.getStorage().removeItem(returnUrlKey);

    return value ? value : "";
  },

  /**
   * Set the selected language
   */
   setSelectedLanguage(selectedLanguage: string) {
    sessionStorage.setItem(SelectedLanguageKey, selectedLanguage);
  },

  /**
   * Gets the selected language, or default locale if not set
   */
  getSelectedLanguage() {
    const value = sessionStorage.getItem(SelectedLanguageKey);
    return value
      ? value
      : defaultLanguage;
  },
};
