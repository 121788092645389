import { HttpService } from "./common/http.service";

import {
  ReportModel,
  ReportEmbedModel,
  ReportLookupsModel,
} from "@/core/models";

export const ReportService = {

  postEmbedDetails(model: ReportModel) {
    return HttpService.postData<ReportEmbedModel>(
      `/reports/embed/${model.reportId}`,
      model.filter);
  },
  getLookups() {
    return HttpService.getData<ReportLookupsModel>(
      "/reports/lookups");
  },
};
