import { Vue } from "vue-property-decorator";
import _ from "lodash";

import { FileDownloadModel } from "@/core/models";

export const DownloadProvider = new Vue({
  methods: {
    downloadResponse(response: FileDownloadModel) {
      // check if response content is encoded or not!
      const content = response.contentType.startsWith("text/")
        ? response.data
        : Utils.getArrayBuffer(atob(response.data));

      const blob = new Blob(
        [ content ],
        { type: response.contentType });

      DownloadProvider.download(blob, response.fileName);
    },

    download(blob: Blob, filename: string) {
      const a = document.createElement("a");
      // IE
      const navigator = window.navigator as any;
      if (navigator.msSaveOrOpenBlob) {
        a.onclick = (evx) => {
          navigator.msSaveOrOpenBlob(blob, filename);
          this.notify("Success", "File successfully downloaded.", "success");
        };
        a.click();
      } else {
        // Chrome and safari
        a.href = URL.createObjectURL(blob);
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(a.href);
        this.notify("Success", "File successfully downloaded.", "success");
      }
    },
    notify(title: string, message: string, type: any) {
      this.$notify({
        title,
        message,
        type,
      });
    },
  },
});

export class Utils {
  static getArrayBuffer(data: string) {
    const buf = new ArrayBuffer(data.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== data.length; ++i) {
      // tslint:disable-next-line: no-bitwise
      view[i] = data.charCodeAt(i) & 0xff;
    }
    return buf;
  }
}