



















import { Component, Prop } from "vue-property-decorator";

import AppVue from "@/apps/AppVue.vue";
import { Route } from "vue-router";

@Component
export default class DashboardMetric extends AppVue {
  @Prop({ default: "" }) label: string;
  @Prop({ default: null }) value: number|null;
  @Prop() to: Route;
  @Prop({ default: false }) showActionrequiredDot: boolean;
  @Prop({ default: false }) isChildMetric: boolean;

  get displayActionRequiredDot() {
    return this.showActionrequiredDot
      && !!this.value
      && this.value > 0;
  }

  get tagType() {
    return this.value === 0
      ? "info"
      : "";
  }

  get childMetricClass() {
    return this.isChildMetric
      ? "child-metric"
      : "";
  }

  get tagSize() {
    return this.isChildMetric
      ? "small"
      : "";
  }
}
