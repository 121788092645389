import {
  Validatable,
  PagedCollectionFilter,
  CourseCollectionModel,
  UserModel,
} from "@/core/models";

export class ComplianceCheckModel extends Validatable {
  id: number|undefined = undefined;
  name: string = "";
  complianceCheckOn: Date;
  createdOn: Date;
  courses: CourseCollectionModel[] = [];
  users: ComplianceCheckUserModel[] = [];
}

export class ComplianceCheckPagedCollectionFilter extends PagedCollectionFilter {
  fromDate?: Date;
  toDate?: Date;
}

export class ComplianceFormLookupModel {
  courses:CourseCollectionModel[] = [];
}

export class ComplianceValidationUserModel extends Validatable {
  users: string = "";
}

export class ComplianceCheckUserModel {
  user: UserModel;
  unmetPrerequisites: string[];
}
