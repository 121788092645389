import intersection from "lodash/intersection";

import { RoleName } from "@/core/constants";
import irisStore from "@/store/store";

export const AuthorisationUtility = {

  isInRoles(roles: string[], userRoles: string[], excludeAdministrator: boolean = true): boolean {
    if (!excludeAdministrator) {
      roles.push(RoleName.IrisAdministrator);
    }

    // console.log("Is in Roles Req user", excludeAdministrator, roles, irisRoles);
    return intersection(userRoles, roles).length > 0;
  },

  isInRole(roleName: string, userRoles: string[], excludeAdministrator: boolean = false) {
    const roles: string[] = [roleName];

    return this.isInRoles(roles, userRoles, excludeAdministrator);
  },

  isInUserRole(roles: string, excludeAdministrator: boolean = true): boolean {
    const roleArray = roles.split(",");
    const userRoles = this.getIrisRoles();

    return this.isInRoles(roleArray, userRoles, excludeAdministrator);
  },

  getIrisRoles() {
    const store = irisStore;
    const userRoles = <string[]> store.getters["userRoles"]; // tslint:disable-line

    if (!userRoles || userRoles.length === 0) {
      // User should have at least one role. If there are no roles, then we probably
      // have not retrieved them yet.
      // So we should go fetch them (calls setUserRoles() in Store actions)
      //
      // This code is only required if someone removes role list from sessionStorage
      store.dispatch("setUserRoles");
    }

    return userRoles;
  },

  getIrisUserId() {
    const store = irisStore;
    const userRoles = <number|undefined> store.getters["userId"]; // tslint:disable-line

    return userRoles;
  },
};
