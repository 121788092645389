export default {
  "common": {
    "name": "Name",
    "hello": "Hello",
    "details": "Details",
    "created": "Created",
    "ok": "OK",
    "cancel": "Cancel",
    "save": "Save",
    "unpublish": "Unpublish",
    "id": "Id",
    "search": "Search",
    "status": "Status",
    "view": "View",
    "course": "Course",
    "union": "Union",
    "noData": "No Data",
    "startDate": "Start date",
    "startTime": "Start Time",
    "endDate": "End date",
    "notes": "Notes",
    "educators": "Educators",
    "forUser": "For user",
    "regions": "Regions",
    "unions": "Unions",
    "existingRegions": "Existing regions",
    "existingUnions": "Existing unions",
    "homeUnion": "Home union",
    "expiresOn": "Expires on",
    "selectUser": "Select User",
    "none": "None",
    "level": "Level",
    "actionAddAll": "Add all",
    "reason": "Reason",
    "gettingData": "Getting data",
    "success": "Success",
    "error": "Error",
    "actionEdit": "Edit",
    "actionSubmit": "Submit",
    "logo": "Logo",
    "actionDropFile": "Drop file here or",
    "imageFormatsAllowed1": "JPG/PNG/GIF files allowed",
    "forename": "Forename",
    "lastname": "Lastname",
    "emailAddress": "Email address",
    "email": "Email",
    "validationIsRequired": "is required",
    "code": "Code",
    "certified": "Certified",
    "certExpiry": "Cert expiry",
    "session": "Session",
    "yes": "Yes",
    "no": "No",
    "actionDownload": "Download",
    "close": "Close",
    "user": "User",
    "location": "Location",
    "from": "From",
    "to": "To",
    "type": "Type",
    "validationError": "Validation error",
    "validationErrorDescription": "A validation error occurred. Please correct",
    "errorSaving": "An error occurred saving to to the database",
    "saving": "Saving…"
  },
  "navigation": {
    "courses": "Courses",
    "myCourses": "My courses",
    "courseAdmin": "Course admin",
    "training": "Training",
    "mySessions": "My Sessions",
    "sessionAdmin": "Session Admin",
    "dashboard": "Dashboard",
    "navAbout": "About",
    "navAdministration": "Administration",
    "navUnions": "Unions",
    "logout": "Logout",
    "myProfile": "My profile",
    "coursesAttended": "Courses attended",
    "licensesAwarded": "Licenses awarded",
    "certification": "Certification",
    "fileshare": "Fileshare",
    "correspondence": "Correspondence"
  },
  "mySessions": {
    "viewMyTrainingSession": "View my training sessions",
    "hostTrainingSession": "Host a training session",
    "selectCourse": "Please select course",
    "selectUnion": "Please select union",
    "selectLicense": "Please select license",
    "licenseInfo": "License information",
    "validIn": "Valid in",
    "language": "Language",
    "unionToAdmin": "Union to administer"
  },
  "filters": {
    "filterByRegion": "Filter by region",
    "filterByUnion": "Filter by union",
    "filterByStatus": "Filter by status",
    "filterByQualityStatus": "Filter by quality status",
    "filterByLevel": "Filter by level",
    "filterByStrand": "Filter by strand",
    "filterByExpiry": "Filter by expiry",
    "filterByDeliveryType": "Filter by delivery type",
    "pleaseSelectLevel": "Please select level",
    "pleaseSelectStrand": "Please select stand",
    "pleaseSelectRegions": "Please select regions",
    "pleaseSelectUnions": "Please select unions",
    "pleaseSelectStatus": "Please select status",
    "filterByLanguage": "Filter by language",
    "filterByCourse": "Filter by course",
    "filterByAttendeeRegion": "Filter by attendee region",
    "filterByAttendeeUnion": "Filter by attendee union",
    "filterByUserEmail": "Filter by user email",
    "filterByAssessmentStatus": "Filter by Assessment Status"
  },
  "sessionAdmin": {
    "viewScheduledTrainingSession": "View scheduled training sessions",
    "scheduleTrainingSession": "Schedule a training session",
    "addTrainingSession": "Add training session"
  },
  "editTrainingSession": {
    "editTrainingSession": "Edit training session",
    "scheduledTraining": "Scheduled training",
    "certificateLanguage": "Certificate language",
    "venueName": "Venue name",
    "venueAddress": "Venue address",
    "organiser": "Organiser",
    "selectAnEducator": "Select an educator",
    "addEducator": "Add educator",
    "addNoteToHistory": "Add note to history",
    "approve": "Approve",
    "saveTrainingSession": "Save training session",
    "viewTrainingSessionHistory": "View training session history",
    "noHistory": "No history to display",
    "addEducatorWarning": "This educator can assist but cannot lead this session. Each session requires at least one lead educator.",
    "assistingEducators": "Assisting educators",
    "canLead": "Can lead?",
    "selectLanguage": "Please select language",
    "scheduleCourse": "Schedule a course",
    "saveCourse": "Save course",
    "addCourse": "Add course",
    "emailLanguageTooltip": "Emails to attendees are written in this language",
    "startTimeLocalTime": "Start Time is specified in local time"
  },
  "addNote": {
    "addNote": "Add a note",
    "noteDetails": "Note details"
  },
  "courseSessionStatus": {
    "awaitingApproval": "Awaiting Approval",
    "rejected": "Rejected",
    "approved": "Approved"
  },
  "tabs": {
    "tabattendees": "Attendees",
    "tabhistory": "History",
    "tabCourseMaterials": "Course Materials"
  },
  "addCourseAttendees": {
    "titleViewTrainingSessionAttendees": "View training session attendees",
    "editAttendeeList": "Edit Attendee List",
    "attendeeName": "Name",
    "assessmentStatus": "Assessment Status",
    "actions": "Actions",
    "editAttendees": "Edit Attendees",
    "actionConfirm": "Confirm",
    "noUsersFound": "No users found",
    "attendeeUpdateError": "Attendee Update Error",
    "attendeeUpdateErrorDescription": "An error occurred when updating attendee list. Has an email template been configured for session locale?",
    "optionSelectStatus": "Select status",
    "actionUpdateStatus": "Update status",
    "actionAdd": "Add",
    "titleAddAssessment": "Add assessment",
    "selectLanguage": "select Language",
    "actionLicense": "License",
    "actionLicenseDescription": "Award a license to this attendee",
    "actionEdit": "Edit",
    "unmetPrerequisites": "Unmet prerequisites",
    "approveAreYouSure": "Are you sure that you want to approve certification for the selected attendees",
    "approveCertification": "Approve certification",
    "errorUpdatingStatusTitle": "Error upditing status",
    "errorUpdatingStatusMessage": "An error occurred while udating attendee status",
    "sendAttendeeInvites": "Send invite email to new attendees:",
    "sendAttendeeInvitesToolTip": "Attendee invite emails are disabled by default by the host union"
  },
  "editAssessment": {
    "editAssessment": "Edit assessment",
    "competency": "Competency",
    "notYetCompetent": "Not Yet Competent",
    "notCompetent": "Not competent",
    "competent": "Competent",
    "excellent": "Excellent",
    "criteria": "Criteria",
    "commentHelpText": "Comment - Mandatory for 'Not yet Competent'",
    "actionSaveAndPublish": "Save & Publish",
    "assessorComment": "Assessor Comment",
    "publishedBy": "Published by",
    "lastUpdatedBy": "Last updated by",
    "addedBy": "Added by",
    "errorSavingAssessment": "Error saving assessment",
    "errorSavingAssessmentMessage": "Check if all inputs were valid",
    "confirmCancelAssessment": "Are you sure that you want to cancel?",
    "leaveAssessment": "Leave assessment?",
    "confirmPublishAssessment": "Are you sure that you want to publish this assessment? Once published, the assessment cannot be edited",
    "publishAssessment": "Publish assessment?",
    "viewAssessment": "View Assessment",
    "attendeeSelfReflection": "Attendee Self Reflection",
    "postCourseProgress": "Post course progress",
    "postLiveExperienceProgress": "Post live experience progress",
    "attendeeNotCompetentHeading": "Will attendee ever be competent?",
    "attendeeMayBeCompetent": "Attendee may yet be competent (default)",
    "attendeeNotCompetent": "Attendee will never be competent",
    "attendeeNotCompetentConfirmation": "Are you sure that you publish this assessment as Not Competent?",
    "unpublishAssessmentConfirmation": "Are you sure that you want to unpublish this assessment?"
  },
  "feedback": {
    "viewFeedback": "View feedback"
  },
  "activeCerts": {
    "activeCertificateStatistics": "Active certificate statistics",
    "date1": "Date 1",
    "date2": "Date 2",
    "activeCerts": "Active certs",
    "pickDate": "Pick a date",
    "total": "Total"
  },
  "issuedCerts": {
    "issuedCertificateStatistics": "Issued certificate statistics",
    "filterByDeliveryType": "Filter by delivery type",
    "range1": "Range 1",
    "range2": "Range 2",
    "dateTo": "To",
    "regionUserFilters": "Region/User filters",
    "regionUserFiltersDescription1": "Region and union filters are applied differently depending on the course delivery type:",
    "regionUserFiltersDescription2": "Online & Scorm: Statistics are grouped by the union registered to the attendee",
    "regionUserFiltersDescription3": "Face to face: Statistics are grouped by the union where the training ocurred",
    "courseCode": "Course Code",
    "courseName": "Course Name",
    "issuedCerts1": "Issued certs 1",
    "issuedCerts2": "Issued certs 2",
    "percentageIncrease": "% Increase",
    "range1Start": "Range 1 start",
    "range1End": "Range 1 end",
    "range2Start": "Range 2 start",
    "range2End": "Range 2 end"
  },
  "strands": {
    "strandRugbyReady": "Rugby ready",
    "strandLaws": "Laws of the game",
    "strandStrengthAndConditioning": "Strength & Conditioning",
    "strandPlayerWelfare": "Player Welfare",
    "strandKeepRugbyClean": "Keep Rugby Clean",
    "strandCoaching": "Coaching",
    "strandOfficiating": "Officiating",
    "strandIntegrity": "Integrity",
    "strandMatchDayStaff": "Match Day Staff",
    "strand": "Strand"
  },
  "licenseStatus": {
    "licenseActive": "Active",
    "licenseRevoked": "Rekoved",
    "licenseExpired": "Expired",
    "licenseProvisional": "Provisional"
  },
  "licenses": {
    "licenses": "Licenses",
    "grantEducatorLicense": "Grant educator license",
    "actionRevoke": "Revoke",
    "grantALicense": "Grant a license",
    "grantLicense": "Grant License",
    "certificatesAwarded": "Certificates awarded",
    "actionGrantALicense": "Grant a license",
    "licenseRevoke": "License revoke",
    "revokeLicense": "Revoke License",
    "noRevokeEmailHeader": "No automatic revoke email",
    "noRevokeEmailBody": "An automatic email is not sent when a license is revoked. Administrators may want to communicate this action personally",
    "viewEligibleCourses": "View eligible courses",
    "eligibleCourses": "Eligible courses",
    "messageLicenceAwardDelay": "There is delay between a manager awarding a certificate to an attendee and the certificate being issues to the attendee. the below table only shows certificates awarded, so it is possible that a certificate recently awarded to an attendee will not show up in the below table",
    "messageEducatorCanTeach": "An educator with the proposed license can teach the following courses",
    "licenseAwardedTo": "License awarded to",
    "errorAddingLicense": "An error occurred adding this license",
    "gettingLicenseInformation": "Getting license information",
    "noLicenses": "You do not have any active licenses at present",
    "licenseHistory": "License History",
    "qualityStatus": "Quality status",
    "delayIssuingCertificate": "Note: Delay in issuing certificates",
    "delayIssuingCertificateInfo": "There is delay between a manager awarding a certificate to an attendee and the certificate being issues to the attendee. the below table only shows certificates awarded, so it is possible that a certificate recently awarded to an attendee will not show up in the below table",
    "noActiveLicenses": "No active licenses",
    "addLicenseError": "An error occurred adding this license",
    "myLicenses": "My licenses",
    "emailWorkforce": "Email workforce",
    "licenseAmended": "The license has been successfully amended",
    "licenseAmendedConfirmationHeading": "Amend license?",
    "licenseAmendConfirmation": "Are you sure that you want to amend this license? Note that this update cannot be undone.",
    "expireLicense": "Expire license",
    "amendLicense": "Amend License"
  },
  "regionAdmin": {
    "rtmUser": "RTM User",
    "saveRegion": "Save Region"
  },
  "unionAdmin": {
    "unionTrainingEducationAdministrator": "Union T&E Administrator",
    "canDeliverCoursesForWr": "Can deliver courses on behalf of World Rugby",
    "saveUnion": "Save Union",
    "gettingUnion": "Getting union",
    "unionSaved": "Union saved"
  },
  "certStatus": {
    "certStatusInProgress": "In progress",
    "certStatusValid": "valid",
    "certStatusInExpired": "Expired",
    "certStatusAll": "All"
  },
  "userAdmin": {
    "userList": "User List",
    "inviteUser": "Invite user",
    "infoNoEmailInviteHeader": "No invite email will be sent",
    "infoNoEmailInviteBody1": "This function adds a user to the database with the Iris backend Role",
    "infoNoEmailInviteBody2": "No invite email is sent to the user as part of this feature, so it the iris adminsitrators responsibity to notify them that they have been added",
    "infoNoEmailInviteBody3": "If the user has not already done so, the user should register with WR SSO using the email address provided here",
    "actionAddUserAndAssignBackendRole": " Add user and assign backend role",
    "irisUserAddedHeader": "Iris backend user added",
    "irisUserAddedBody": "has been added as an Iris backend user",
    "errorAddingUserHeader": "Error adding backend user",
    "errorAddingUserBody": "An error has occurred adding the backend user",
    "isExactEmail": "Is exact email",
    "userBaseId": "User Base ID",
    "lastLoginDate": "last Login Date",
    "coursesAndCertificates": "Cources & certificates",
    "roles": "Roles",
    "currentValidCertificates": "Current valid certificates",
    "userLicenses": "User Licenses",
    "userNeverGrantedLicense": "This user has never been granted a license",
    "infoRegionManagerheader": "Regional training manager role",
    "infoRegionManagerDetail1": "The regional training manager role can be granted in the",
    "infoRegionManagerDetail2": "region administration form",
    "infoUnionManagerheader": "Union training manager role",
    "infoUnionManagerDetail1": "The union  training manager role can be granted in the",
    "infoUnionManagerDetail2": "union administration form",
    "userRoles": "user roles",
    "infoRolesUpdatedHeader": "Roles updated",
    "infoRolesUpdatedBody": "User roles hasve been updated successfully",
    "userExactEmail": "Use exact email",
    "select": "Select",
    "noUsers": "No Users",
    "searchAttendee": "Search for attendee to add"
  },
  "reports": {
    "reports": "Reports",
    "report": "Report",
    "deliveryType": "Delivery Type",
    "actionGetReport": "Get Report",
    "regions": "Region(s)",
    "unions": "Union(s)"
  },
  "qualityAudit": {
    "manage": "Manage",
    "qualityAssurance": "Quality Assurance",
    "qualityAudits": "Quality Audits",
    "date": "Date",
    "addQualityAudit": "Add Quality Audit",
    "noAuditsAdded": "No audits have been added to this license",
    "educatorCanTeach": "An educator with the proposed license can teach the following courses:",
    "auditor": "Auditor",
    "published": "Published",
    "actionPublish": "Publish",
    "audits": "Audits",
    "assignAuditor": "Assign auditor",
    "myAssignedQas": "My assigned QA's",
    "audit": "Audit",
    "auditBy": "Audit by",
    "publishedBy": "This audit was published by ",
    "onDate": "on",
    "venue": "Venue",
    "detailsPlaceholder": "Add details of how the audit was performed. Include online links if necessary",
    "areasOfStrength": "Areas of strength",
    "areasOfStrengthPlaceholder": "Add areas of strength here",
    "areasForDevelopment": "Areas for development",
    "areasForDevelopmentPlaceholder": "Add areas for development here",
    "agreedActionPlan": "Agreed action plan",
    "agreedActionPlanPlaceholder": "Add agreed action plan here",
    "educatorSelfReflection": "Educator self reflection",
    "educatorSelfReflectionPlaceholder": "Add educator self reflection here",
    "grade": "Grade",
    "gradePlaceholder": "Please select grade",
    "behaviour": "Behaviour",
    "behaviourPlaceholder": "Please select behaviour",
    "saveAsDraft": "Save as draft",
    "auditsOnEducator": "Audits on educator",
    "auditsByEducator": "Audits by educator",
    "educator": "Educator",
    "addEducatorNote": "Add educator note",
    "educatorNoteTooltip": "Educator notes will be visible to educators and their managers in the course history tab",
    "qaSubmitSuccess": "Quality audit submitted successfully",
    "qaPublishedSuccess": "Quality audit published successfully",
    "qaConfirmPublish": "Are you sure that you want to publish this quality audit? The audit will become read only once published.",
    "qaConfirmPublishTitle": "Publish audit?",
    "qaLoadingText": "Getting Quality Audit information…",
    "manageAudits": "Manage audits",
    "myActiveLicences": "My active licenses",
    "editQualityAudit": "Edit Quality Audit",
    "submitForm": "Submit form",
    "auditPublished": "Audit published",
    "user": "User",
    "licenseHolder": "License holder",
    "licenseStatus": "License Status",
    "publishStatus": "Publish Status",
    "actionSubmitEducatorFeedback": "Submit feedback",
    "actionSubmitForEducatorFeedback": "Submit for auditee feedback"
  },
  "qualityAuditStatus": {
    "Draft": "Draft",
    "AwaitingEducatorFeedback": "Awaiting auditee feedback",
    "AwaitingAuditeeFeedback": "Awaiting auditee feedback",
    "AwaitingPublish": "Awaiting publish",
    "Published": "Published"
  },
  "termsAndConds": {
    "heading": "Accept World Rugby terms and conditions",
    "main": "In order to proceed to use Iris, you must accept the following terms and conditions:",
    "educatorCodeOfConductHeading": "Educator code of conduct",
    "educatorLinkDescription": "The educator code of coduct can be viewed at",
    "educatorAcceptCodeOfConduct": "I accept educator code of conduct"
  },
  "courseSessionAttendees": {
    "removeAttendees": "Remove attendees",
    "addAttendees": "Add attendees",
    "addAttendeesBy": "Add attendees by",
    "draft": "Draft",
    "assessment": "Assessment",
    "noAttendees": "No attendees",
    "noAttendeesSubTitle": "No attendees have been added to this course. Click the button below to add attendees",
    "bulkAddAttendees": "Bulk adding attendees by email address",
    "findExisting": "Finding an exsiting user",
    "registeringNew": "Registering a new user",
    "bulkAddByEmailA": "Add multiple attendees to this course. Enter attendee email addresses in the field below, one email address per line, and click the 'Add attendees' button.",
    "bulkAddByEmailB": "Only existing Iris users will be added to the course. A list of attendee email addresses that could not be added will be provided to you.",
    "addAttendeeLabel": "attendee email addresses (one per line)",
    "addressesDoNotExist": "Attendee email addresses do not exist in Iris",
    "emailsAreRegistered": "The following email addresses are not registered as users",
    "registerNewUser": "Register a new user and add them as an attendee to this course. Do this only when the user does not already exist in Iris.",
    "firstName": "First Name",
    "surname": "Surname",
    "noEmailWarning": "If the attendee does not have an email address, email notifications will be sent to the organising educator",
    "registerNewUserAsAttendee": "Register new user as attendee",
    "draftAttendeeList": "Your draft attendee list",
    "updateAttendeeList": "Are you sure that you want to update the attendee list?",
    "confirmThisAction": "By confirming this action, all newly added attendees will receive an introductory email about this course.",
    "confirmTitle": "Update attendee list?",
    "errorAddingUserMessage": "An error has occurred adding a user",
    "errorAddingAttendees": "An error has occurred adding attendees"
  },
  "courseSessionHost": {
    "attendeeNotes": "Attendee notes",
    "attendeeNotesTooltip": "These notes will  appear in invite emails to to new attendees (once configured in email templates). Please write in the selected language",
    "addCourse": "Add course",
    "noEducatorLicensesDescription": "You so not have any active licenses. You need active educator licenses to deliver courses. Please contact you Regional training manager to to granted an educator license",
    "locationTooltip": "A descriptive place where the course is being held within a union, e.g. a province, city or borough"
  },
  "courseSessionRouteTags": {
    "viewMyCourses": "View my courses",
    "viewScheduledCourses": "View scheduled courses",
    "hostCourse": "Host a course",
    "scheduleCourse": "Schedule course",
    "editCourse": "Edit course",
    "viewCourse": "View course",
    "viewAttendees": "View attendees",
    "viewCourseHistory": "View course history"
  },
  "routeTags": {
    "acceptEducatorTerms": "Accept educator terms and conditions"
  },
  "profileCourses": {
    "started": "Started",
    "noCoursesAttended": "No courses attended",
    "notAttendedAnyCourses": "You have not attended any courses",
    "validCertLookupAll": "All",
    "validCertLookupCurrent": "Current valid certificates",
    "validCertLookupExpired": "Expired",
    "validCertLookupInProgress": "In Progress"
  },
  "profileLicenses": {
    "noLicenses": "No licenses",
    "noLicensesMatch": "No licenses match the criteria",
    "expiry": "Expiry"
  },
  "attendeeEmailInvite": {
    "showEmailPreview": "Show invite email preview",
    "attendeeEmailPreview": "Attendee email preview",
    "subject": "Subject",
    "htmlContent": "HTML Content",
    "textContent": "Text Content"
  },
  "myDashboard": {
    "myActions": "My actions",
    "recentCourseRequringApproval": "Recent courses requiring approval",
    "attendeesAwaitingCertApproval": "Attendees awaiting certification approval",
    "underMyAdmin": "Under my administration",
    "recentCourses": "Recent courses",
    "upcomingCourses": "Upcoming courses",
    "auditsInProgress": "Audits in progress",
    "myRecentCourses": "My recent courses",
    "awaitingApproval": "Awaiting approval",
    "myUpcomingCourses": "My upcoming courses",
    "myQualityAudits": "My quality audits",
    "myAuditsInProgress": "My audits in progress",
    "myUpcomingAudits": "My upcoming audits",
    "recentUpcomingCourses": "Recent and upcoming courses",
    "noCoursesSubtitle": "You have no recent course, and have no upcoming courses scheduled. Click the button below to host a course",
    "activeAudits": "Active audits",
    "noAuditsSubtitle": "You have no recent audit, and have no upcoming audits scheduled.",
    "auditsRequiringMyFeedback": "Requiring my feedback",
    "assessmentPeriodDueToExpire": "Assessments  near expiration",
    "assessmentsExpireInPastYear": "Assessments expired in past year",
    "correspondence": "Correspondence"
  },
  "fileshare": {
    "courses": "Fileshare courses",
    "fileshareCourseMaterials": "Fileshare course materials"
  },
  "correspondence": {
    "decline": "Decline",
    "validateCorrespondence": "Validate Correspondence",
    "id": "ID",
    "emailScheduleType": "Type",
    "status": "Status",
    "targetedWorkforce": "Targeted workforce",
    "viewUsers": "View users",
    "hideUsers": "Hide users",
    "attachments": "Attachments",
    "approve": "Approve",
    "validate": "Validate"
  },
  "educatorsEmail": {
    "numberOfUser": "Number of Users",
    "noUsersFound": "No users found",
    "workforce": "Workforce",
    "filters": "Filters"
  },
  "compliance": {
    "compliance": "Compliance",
    "complianceCheckEdit": "Edit compliance check",
    "complianceCheckAdd": "Add compliance check",
    "compliantOnDate": "Compliant on date",
    "complianceCheckSuccessfullySaved": "Your compliance check was successfully saved",
    "requiredCertificates": "Required certificates",
    "usersToCheck": "Users to check",
    "confirmRemoveRequiredCertificate": "Are you sure that you want to remove this required certificate",
    "confirmRemoveUser": "Are you sure that you want to remove this user",
    "errorAddingUsers": "An error occurred adding users",
    "noCertificates": "No required certificates"
  },
  "educatorList": {
    "numberOfCoursesDelivered": "Number of courses delivered",
    "numberOfUnionsDeliveredIn": "Number of unions delivered in"
  }
};
