import { RoleName } from "@/core/constants";

export const certificateRoutes = [
  {
    path: "/certificates",
    name: "certificateList",
    meta: {
      title: "Certification",
      roles: `${RoleName.GlobalTrainingManager},` +
        `${RoleName.IrisAdministrator},` +
        `${RoleName.RegionalTrainingManager},` +
        `${RoleName.UnionTrainingManager},` +
        `${RoleName.UnionAdministrator},`,
    },
    component: () =>
      import(/* webpackChunkName: "region" */ "@/views/certification/CertificateList.vue"),
  },
];
