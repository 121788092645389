
















import { Component, Prop } from "vue-property-decorator";

import { ValidationMessage, SelectableInputComponent } from ".";

@Component({
  components: {
    ValidationMessage,
  },
})
export default class InputCheckboxList extends SelectableInputComponent {
    @Prop() descriptionTextProp: string;

    getDescriptionText(lookup: any) {
      if (!lookup) {
        return null;
      }

      if (!this.descriptionTextProp) {
        return null;
      }

      return lookup[this.descriptionTextProp];
    }
}
