import { RoleName } from "@/core/constants";

export const questionnaireRoutes = [
  {
    path: "/admin/questionnaire/:locale?",
    name: "QuestionnaireEdit",
    meta: {
      title: "Edit default questionnaire",
      roles: `${RoleName.IrisAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "questionnaire" */ "@/views/questionnaire/QuestionnaireEdit.vue"),
  },
];
