

















































import { Component, Prop } from "vue-property-decorator";

import AppVue from "@/apps/AppVue.vue";
import {
  EmailTemplateModel,
  CourseSessionModel,
} from "@/core/models";
import { CourseSessionService } from "@/core/services";

@Component
export default class AttendeeEmailInvitePreview extends AppVue {
  @Prop() model: CourseSessionModel;

  loadingAttendeeEmailPreview = false;
  showAttendeeEmailPreview = false;
  attendeePreviewEmailTemplateModel: EmailTemplateModel = new EmailTemplateModel();

  async showEmailPreview() {
    this.loadingAttendeeEmailPreview = true;

    try {
      this.attendeePreviewEmailTemplateModel = await CourseSessionService
        .getAttendeeEmailPreview(this.model);

      this.showAttendeeEmailPreview = true;
    } finally {
      this.loadingAttendeeEmailPreview = false;
    }
  }
}
