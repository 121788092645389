























































































import { Component, Prop } from "vue-property-decorator";
import { RawLocation } from "vue-router";

import {
  BaseGridComponent,
  GridSearch,
  GridDateFilter,
  GridSelectFilter,
  GridPager,
} from "@/components/common";
import { QualityAuditListActor } from "@/core/constants";
import { QualityAuditCollectionModel, QualityAuditGridLookupModel, SimpleLookupModel } from "@/core/models";
import { QualityAuditService } from "@/core/services";

@Component({
  components: {
    GridSearch,
    GridDateFilter,
    GridSelectFilter,
    GridPager,
  },
})
export default class QualityAuditList extends BaseGridComponent<QualityAuditCollectionModel> {
  @Prop({ type: String, default: "Auditor" }) actor: string;

  lookups: QualityAuditGridLookupModel = new QualityAuditGridLookupModel();
  yesNoNookup: any[] = [
    { id: "true", text: this.localiseString("common.yes"), code: "Y" },
    { id: "false", text: this.localiseString("common.no"), code: "N" },
  ];

  get isManager() {
    return this.actor === QualityAuditListActor[QualityAuditListActor.Manager];
  }

  async created() {
    if (this.isManager) {
      this.initialize(
        (filter) =>  QualityAuditService.getAsManager(filter),
        this.$route.name,
      );
    } else {
      this.initialize(
        (filter) =>  QualityAuditService.getAsAuditor(filter),
        this.$route.name,
      );
    }

    this.lookups = await QualityAuditService.getGridLookups();
  }

  getEditAuditRoute(model: QualityAuditCollectionModel): RawLocation {
    return {
      name: "editQualityAudit",
      params: {
        licenseId: String(model.licenseId),
        qualityAuditId: String(model.id),
      },
    };
  }

  getSubmitAuditRoute(model: QualityAuditCollectionModel): RawLocation {
    return {
      name: "submitQualityAudit",
      params: {
        qualityAuditId: String(model.id),
      },
    };
  }
}
