


















import { Component, Vue, Prop } from "vue-property-decorator";
import _ from "lodash";

import { CourseService } from "@/core/services";
import { CourseCollectionModel, AutoCompleteItem } from "@/core/models";

@Component
export default class CourseSelector extends Vue {
  @Prop() onSelected?: (course: CourseCollectionModel) => void;
  @Prop() findMethod?: (search: string) => Promise<CourseCollectionModel[]>;
  @Prop({ default: false }) showDialog: boolean;

  state = "";
  foundCourses: CourseCollectionModel[] | null = null;

  get isOpen() {
    return this.showDialog;
  }

  async find(search: string,  callback: (items: AutoCompleteItem[]) => any) {
    if (!search) {
      callback([]);
      return;
    }

    this.foundCourses = null;

    const courses = this.findMethod
      ? await this.findMethod(search)
      : await CourseService.find(search);

    this.foundCourses = courses;
    callback(this.formatCourseList(courses));
  }

  onSelectedInternal(item: AutoCompleteItem) {
    if (this.onSelected) {
      const course = _.find(this.foundCourses, { courseId: item.id });
      this.onSelected(course as CourseCollectionModel);
      this.close();
    }
  }

  close() {
    this.state = "";
    this.$emit("onClose");
  }

  private formatCourseList(courses: CourseCollectionModel[]) {
    return _.map(courses, (course) => {
      const name = course.name;
      const code = course.code ? `${course.code} - ` : ``;

      return {
        id: course.courseId,
        value: `${code}${name}`,
      };
    });
  }
}
