import { Validatable, SimpleLookupModel, UserModel } from "@/core/models";
import { AssessmentCriteriaStatus, PublishStatus} from "@/core/constants";

export class AssessmentModel extends Validatable {
  assessmentId: number|null = null;
  courseAttendeeId: number = 0;
  assessmentTranscriptId: number;
  localeId: number = 0;
  publishStatusId: number = PublishStatus.Draft;
  publishStatusName: string = "";
  assessmentCriteriaStatusId: number = 0;
  assessmentCriteriaStatusName: string = "";
  assessorComment: string = "";
  attendeeSelfReflection: string = "";
  postCourseProgress: string = "";
  afterLiveExperienceProgress: string = "";
  competencies: CourseAttendeeAssessmentCompetencyModel[] = [];
  addedDate: Date|null = null;
  addedByUser: UserModel;
  updatedDate: Date|null = null;
  updatedByUser: UserModel;
  publishedDate: Date|null = null;
  publishedByUser: UserModel;
  legacyAssessmentUrl: string|null = null;
  attendeeIsNotCompetent: boolean = false;
}

export class CourseAttendeeAssessmentCompetencyModel extends Validatable {
  competencyId: number = 0;
  description: string = "";
  criteria: CourseAttendeeAssessmentCriteriaModel[]  = [];
}

export class CourseAttendeeAssessmentCriteriaModel extends Validatable {
  criteriaId: number = 0;
  assessmentCriteriaStatusId = 0;
  description: string = "";
  comment: string = "";
}

export class AssessmentLookupsModel {
  criteriaStatuses: SimpleLookupModel[] = [];
}