


























import { Route } from "vue-router";
import split from "lodash/split";
import take from "lodash/take";
import isEqual from "lodash/isEqual";
import { Component, Watch, Vue } from "vue-property-decorator";
import AppVue from "@/apps/AppVue.vue";

import AuthStatus from "@/components/auth/AuthStatus.vue"; // @ is an alias to /src
import { IrisLanguages, Language } from "@/i18n/languages";
import { StorageUtility } from "@/core/utilities/storage.utility";

@Component({
  components: {
    AuthStatus,
  },
})
export default class Header extends AppVue {
  private customPageTitle: string = "";

  get title() {
    if (this.$route.meta.tag) {
      return this.localiseString(this.$route.meta.tag);
    }

    return (this.customPageTitle || this.$route.meta.title || "Iris");
  }

  get routeTitle() {
    let title = "World Rugby Iris";

    if (this.$route.meta.tag) {
      title = `${this.localiseString(this.$route.meta.tag)} - ${title}`;
    } else if (this.$route.meta.title) {
      title = `${this.localiseString(this.$route.meta.title)} - ${title}`;
    }

    return title;
  }

  get showLanguageSelector() {
    return this.$route.meta && this.$route.meta.localise === true;
  }

  get irisLanguages() {
    return IrisLanguages;
  }

  @Watch("$route", { deep: true })
  ResetTitle(to: Route, from: Route) {
    if (!this.hasSameBaseRoute(to, from)) {
      this.customPageTitle = "";
    }

    // set document title on browser
    document.title = this.routeTitle;
  }

  protected created() {
    this.$root.$on("PAGE_TITLE_UPDATED", (pageTitle: any) => {
      this.customPageTitle = pageTitle;
      // document.title = `Iris - ${this.customPageTitle}`;
    });
  }

  protected updateLanguage(command: Language) {
    StorageUtility.setSelectedLanguage(command);
    this.$i18n.locale = command;
  }

  /**
   * Checks if two routes have the same Iris base path
   *
   * This method is used to check if the page title name needs to be reset.
   * Resetting the the page title on some pages will cause the title to flicker
   * and this is unecessary if the page title does not need to be changed.
   *
   * This method assumes that if the first 2 path of a routes path are the same,
   * then the title does not need to change, so there is no need to reset the
   * title.
   *
   * E.g. /courses/1 and /courses/1/settings will have the course title, so there
   * is no need to reset the title to "". This stops the flickering efffect.
   *
   * @param   {Route} to    to route
   * @param   {Route} from  from route
   *
   * @returns {boolean}     Returns true both route parameters have the same
   *                        base path.
   */
  private hasSameBaseRoute(to: Route, from: Route): boolean {
    const toBaseParts = take(split(to.path, "/"), 3);
    const fromBaseParts = take(split(from.path, "/"), 3);

    return isEqual(toBaseParts, fromBaseParts);
  }
}
