import { RoleName } from "@/core/constants";

export const complianceRoutes = [
  {
    path: "/compliance",
    name: "complianceCheckList",
    meta: {
      title: "Compliance",
      tag: "compliance.compliance",
      roles: `${RoleName.Compliance},` +
        `${RoleName.IrisAdministrator}`,
      },
    component: () =>
      import(/* webpackChunkName: "compliance" */ "@/views/compliance/ComplianceCheckList.vue"),
  },
  {
    path: "/compliance/:complianceCheckId/edit",
    name: "complianceCheckForm",
    meta: {
      title: "Compliance Check Edit",
      tag: "compliance.complianceCheckEdit",
      roles:  `${RoleName.Compliance},` +
        `${RoleName.IrisAdministrator}`,
      },
    component: () =>
      import(/* webpackChunkName: "compliance" */ "@/views/compliance/ComplianceCheckEditForm.vue"),
  },
  {
    path: "/compliance/add",
    name: "complianceCheckAddForm",
    meta: {
      title: "Compliance Check Add",
      tag: "compliance.complianceCheckAdd",
      roles:  `${RoleName.Compliance},` +
        `${RoleName.IrisAdministrator}`,
      },
    component: () =>
      import(/* webpackChunkName: "compliance" */ "@/views/compliance/ComplianceCheckEditForm.vue"),
  },
];
