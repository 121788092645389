
import { Vue } from "vue-property-decorator";
import moment from "moment";

import { RoleName } from "@/core/constants";
import { AuthorisationUtility } from "@/core/utilities/authorisation.utility";
import { DefaultLocale, LoggingService } from "@/core/services";

export default class AppVue extends Vue {
  get routeName() {
    return this.$route.meta.title;
  }

  hasFilterValues(obj: any) {
    return obj != null && obj !== "";
  }

  get isAdmin() {
    return this.isInRole(RoleName.IrisAdministrator);
  }

  get isCourseAdministrator() {
    return this.isInRole(RoleName.CourseEditor, false);
  }

  get isUnionTrainingManager() {
    return this.isInRole(RoleName.UnionTrainingManager);
  }

  get isUnionTrainingAdministrator() {
    return this.isInRole(RoleName.UnionAdministrator);
  }

  get isRegionalTrainingManager() {
    return this.isInRole(RoleName.RegionalTrainingManager);
  }

  get isGlobalTrainingManager() {
    return this.isInRole(RoleName.GlobalTrainingManager);
  }

  get isCompliance() {
    return this.isInRole(RoleName.Compliance);
  }

  get isAtLeastGlobalManager(): boolean {
    const expectedRoles = [
      RoleName.GlobalTrainingManager,
    ];

    return this.isInRoles(expectedRoles);
  }

  get isAtLeastRegionalManager() {
    const expectedRoles = [
      RoleName.RegionalTrainingManager,
      RoleName.GlobalTrainingManager,
    ];

    return this.isInRoles(expectedRoles);
  }

  get isAtLeastUnionManager() {
    const expectedRoles = [
      RoleName.UnionTrainingManager,
      RoleName.RegionalTrainingManager,
      RoleName.GlobalTrainingManager,
    ];

    return this.isInRoles(expectedRoles);
  }

  get isAtLeastUnionAdministrator() {
    const expectedRoles = [
      RoleName.UnionAdministrator,
      RoleName.UnionTrainingManager,
      RoleName.RegionalTrainingManager,
      RoleName.GlobalTrainingManager,
    ];

    return this.isInRoles(expectedRoles);
  }

  get environment() {
    return process.env.NODE_ENV;
  }

  get irisEnvironment() {
    return process.env.VUE_APP_IRIS_ENVIRONMENT;
  }

  get showEnvironmentBackground() {
    return !(this.irisEnvironment === "Production");
  }

  get currentUserId() {
    // Ensure the current user is logged in
    if (!this.$msal.isAuthenticated()) {
      return undefined;
    }

    return AuthorisationUtility.getIrisUserId();
  }

  get currentUserName() {
    if (!this.$msal.isAuthenticated() || !this.$msal.getLoggedInAccount()) {
      return undefined;
    }

    return this.$msal.getLoggedInAccount().name;
  }

  isInRoles(roles: string[], excludeAdministrator: boolean = false) {
    return AuthorisationUtility.isInRoles(roles, this.irisRoles, excludeAdministrator);
  }

  isInRole(roleName: string, excludeAdministrator: boolean = false) {
    return AuthorisationUtility.isInRole(roleName, this.irisRoles, excludeAdministrator);
  }

  redirectToPermissionDenied() {
    this.$router.push({ name: "permissionDenied" });
  }

  canEditCourse() {
    return this.isInRole(RoleName.CourseEditor, false);
  }

  formatDate(date: any) {
    if (!date) {
      return null;
    }

    return moment(date).format("YYYY-MM-DD");
  }

  formatDateAndTime(date: any) {
    if (!date) {
      return null;
    }

    return moment(date).format("YYYY-MM-DD hh:mm:ss");
  }

  today(): moment.Moment {
    return moment({hours: 0});
  }

  todayAsDate() {
    return this.today().toDate();
  }

  todayFormatted() {
    return this.formatDate(this.todayAsDate());
  }

  recentPeriodInWeeks(): number {
    return parseInt(process.env.VUE_APP_RECENT_PERIOD_IN_WEEKS, 10);
  }

  /**
   * Retun true if the error is a validation error
   */
  isValidationError(error: any) {
    return error
      && error.response
      && error.response.status === 400;
  }

  getRef(refName: string) {
    return this.$refs[refName] as any;
  }

  setInputFieldFocus(refName: string) {
    this.$nextTick(() => {
      const ref = this.getRef(refName);

      if (ref != null) {
        ref.$children[0].$children[1].focus();
      }
    });
  }

  elGetRef(refName: string) {
    const refs = (this.$refs[refName] as any);

    return (refs && refs.length > 0) ? refs[0] : null;
  }

  elSetFocus(refName: string) {
    this.$nextTick(() => {
      const ref = this.elGetRef(refName);

      if (ref != null) {
        ref.focus();
      }
    });
  }

  handleApiGetError(err: any) {
    // See https://stackoverflow.com/questions/3297048/403-forbidden-vs-401-unauthorized-http-responses
    // to explain difference between 401 & 403
    if (err.response.status === 401 || err.response.status === 403) {
      this.redirectToPermissionDenied();
    } else {
      this.$notify({
        title: "Error occurred",
        message: "Error occurred getting data",
        type: "error",
      });
    }
  }

  localiseString(placeholder: string) {
    try {
      let translatedText = String(this.$t(placeholder));

      if (translatedText == null || translatedText.length === 0) {
        const defaultTranslatedText = String(this.$t(placeholder, DefaultLocale));
        if (!!defaultTranslatedText && defaultTranslatedText.length > 0) {
          translatedText = `[${defaultTranslatedText}]`;
        }
      }

      return (!!translatedText && translatedText.length > 0)
        ? translatedText
        : translatedText = `[${placeholder}]`;
    } catch (e) {
      LoggingService.warn("Error in localiseString()", e);

      return placeholder;
    }
  }

  get irisRoles(): string[] {
    // Ensure the current user is logged in
    if (!this.$msal.isAuthenticated()) {
      return [];
    }

    return AuthorisationUtility.getIrisRoles();
  }

  localisedRouteName() {
    if (!this.$route.meta || !this.$route.meta.tag) {
      return this.routeName;
    }

    return this.localiseString(this.$route.meta.tag);
  }
}
