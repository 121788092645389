import { LicenseQualityStatus } from "@/core/constants";
import { HttpService } from "./common/http.service";
import {
  QualityAuditLookupModel,
  QualityAuditCollectionModel,
  QualityAuditModel,
  PagedCollection,
  PagedCollectionFilter,
  QualityAuditSubmitModel,
  QualityAuditGridLookupModel,
  SubmitQualityAuditLookupModel } from "@/core/models";
import { QualityAuditStatus } from "@/core/constants";

export const QualityAuditService = {

  getQualityStatusIcon(qualityStatus: any) {
    let iconClass: string = "el-icon-circle-close";

    if (qualityStatus === LicenseQualityStatus.BelowStandard) {
      iconClass = "el-icon-error";
    }

    if (qualityStatus === LicenseQualityStatus.QaComplete) {
      iconClass = "el-icon-success";
    }

    if (qualityStatus === LicenseQualityStatus.QaDue ||
      qualityStatus === LicenseQualityStatus.QaDueImminently) {
      iconClass = "el-icon-warning";
    }

    return `${iconClass} qa-status-${qualityStatus}`;
  },

  getFormLookups(licenseId: number, id: number | null) {
    const params = !!id
      ? {id}
      : {};

    return HttpService.getData<QualityAuditLookupModel>(
      `/qualityaudits/lookups/${licenseId}`,
      { params });
  },

  getSubmitLookups(id: number) {
    return HttpService.getData<SubmitQualityAuditLookupModel>(`/qualityaudits/submitlookups/${id}`);
  },

  getGridLookups() {
    return HttpService.getData<QualityAuditGridLookupModel>(`/qualityaudits/list/lookups/`);
  },

  getById(id: number) {
    return HttpService.getData<QualityAuditModel>(`/qualityaudits/${id}`);
  },

  getSubmissionById(id: number) {
    return HttpService.getData<QualityAuditSubmitModel>(`/qualityaudits/submit/${id}`);
  },

  getByLicenseId(licenseId: number) {
    return HttpService.getData<QualityAuditCollectionModel>(`/qualityaudits/license/${licenseId}`);
  },

  getByEducatorId(educatorUserId: number, strandId: number) {
    return HttpService.getData<QualityAuditCollectionModel>(`/qualityaudits/educatorAudits/${educatorUserId}/${strandId}`);
  },

  async postQualityAudit(licenseId: number, model: QualityAuditModel) {
    return await HttpService.postModel(
      `/qualityaudits/${licenseId}`,
      model,
    );
  },

  async putQualityAudit(id: number, model: QualityAuditModel) {
    return await HttpService.putModel(
      `/qualityaudits/${id}`,
      model,
    );
  },

  async submitQualityAudit(
    id: number, 
    model: QualityAuditSubmitModel,
    qualityAuditStatusId: number) {
    return await HttpService.putModel(
      `/qualityaudits/submit/${id}/${qualityAuditStatusId}`,
      model,
    );
  },

  async publishQualityAudit(id: number, model: QualityAuditSubmitModel) {
    return await HttpService.putModel(
      `/qualityaudits/publish/${id}`,
      model,
    );
  },

  async deleteQualityAudit(qualityAuditId: number) {
    return await HttpService.delete(
      `/qualityaudits/${qualityAuditId}`,
    );
  },

  getAsManager(filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<QualityAuditCollectionModel>>(
      `/qualityaudits/list/manager`,
      { params: filter });
  },

  getAsAuditor(filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<QualityAuditCollectionModel>>(
      `/qualityaudits/list/auditor`,
      { params: filter });
  },
};
