



























import { Component, PropSync, Vue } from "vue-property-decorator";
import CourseExamQuestionOptionForm from "@/components/course/CourseExamQuestionOptionForm.vue";

@Component({
    components: {
        CourseExamQuestionOptionForm,
    },
})
export default class CourseExamQuestionForm extends Vue {
    /*
    @PropSync("question") private questionSync!: string;
    @PropSync("options", { default: () => [] } )
    private optionsSync!: CourseExamQuestionOptionForm[];
    */
    private question = {
        questionIdentifier: "",
        order: 10000,
        question: "",
        locale: 1,
        type: 1,
    };

    protected addOption(): void {
        const option = new CourseExamQuestionOptionForm();
        // this.optionsSync.push(option);
    }
}
