

























































































import filter from "lodash/filter";
import head from "lodash/head";
import {
  LocaleService,
  DefaultLocale,
  EmailTemplateService,
} from "@/core/services";
import {
  LocaleModel,
  EmailTemplateTypeModel,
  EmailTemplateModel,
} from "@/core/models";

import { Component, Watch, Prop } from "vue-property-decorator";
import AppVue from "@/apps/AppVue.vue";

@Component
export default class EmailTemplateListComponent extends AppVue {
  @Prop() editRoute: string;
  @Prop() courseId?: number;

  selectedLocaleCode: string = DefaultLocale;
  defaultLocaleCode: string = DefaultLocale;
  locales: LocaleModel[] = [];
  emailTemplateTypes: EmailTemplateTypeModel[] = [];

  created() {
    this.setLocaleCode();
    this.BindEmailTemplates();
  }

  @Watch("$route", { deep: true })
  setLocaleCode() {
    this.selectedLocaleCode = this.getInitalLocaleCode();
  }

  getInitalLocaleCode() {
    return this.$route.params.locale != null
      ? this.$route.params.locale
      : DefaultLocale;
  }

  get selectedLocale() {
    return head(filter(this.locales, { isoCode: this.selectedLocaleCode }));
  }

  @Watch("$route", { deep: true })
  async BindEmailTemplates() {
    const loading = this.$loading({
      lock: true,
      text: "Getting email templates...",
    });

    this.locales = await LocaleService.list();
    this.emailTemplateTypes = await EmailTemplateService.list(this.selectedLocaleCode, this.courseId);

    loading.close();
  }

  onChangeLocale() {
    const route = this.$route;
    route.params.locale = this.selectedLocaleCode;

    this.$router.push(route);
  }

  getEditTemplateRoute(emailTemplateTypeId: number) {
    return {
      name: this.editRoute,
      params: {
        templateTypeId: emailTemplateTypeId,
        locale: this.selectedLocaleCode,
        id: this.courseId || undefined as any,
      },
    };
  }

  hasTemplate(type: EmailTemplateTypeModel): boolean {
    return type.emailTemplate !== undefined && type.emailTemplate !== null;
  }

  getDefaultEmailTemplateEditRoute(templateTypeId: number) {
    return {
      name: "adminEmailTemplateEdit",
      params: {
        templateTypeId,
        locale: this.selectedLocaleCode,
      },
    };
  }

  getDefaultEmailTemplateListRoute() {
    return {
      name: "adminEmailTemplateList",
    };
  }
}
