


















import { Component, Prop, Emit, Vue } from "vue-property-decorator";

@Component
export default class DtInputNumber extends Vue {
  @Prop() private label!: string;
  @Prop({default: "text"}) private type!: string;
  @Prop() private value!: any;
  @Prop() private property!: string;
  @Prop({default: false}) private disabled!: boolean;

  get showError() {
    return this.errorModel != null;
  }

  get errorMessage() {
    return this.showError
      ? this.errorModel[0]
      : "";
  }

  private get errorModel() {
    if (this.value.modelState && this.value.modelState[this.property]) {
      return this.value.modelState[this.property];
    }

    return null;
  }

  get isDisabled(): boolean {
    return this.disabled;
  }

  @Emit("input")
  private handleInput(event: any) {
    return this.value;
  }
}
