














































import { Component, Prop } from "vue-property-decorator";
import { RawLocation } from "vue-router";
import _ from "lodash";

import { UserModel } from "@/core/models";
import { UserService } from "@/core/services";
import {
  BaseFormComponent,
  InputField,
  InputDate,
} from "@/components";
import UserNavigation from "@/components/user/UserNavigation.vue";

@Component({
  components: {
    InputField,
    InputDate,
    UserNavigation,
  },
})
export default class UserDetails extends BaseFormComponent {
  @Prop() userId: number;
  model: UserModel = new UserModel();
  isReadOnly: boolean = true;
  actionValue: string = "";

  async created() {
    await this.initializeModel();
  }

  async onSubmitForm() {
    /*
    this.userId
      ? await UserService.putUser(this.model)
      : await UserService.postUser(this.model);
    */

    this.$router.push("/users");
  }

  get userfullName() {
    return this.model
      ? `${this.model.firstName} ${this.model.lastName}`
      : "";
  }

  protected doAction() {
    const route: RawLocation = {
      name: this.actionValue,
      params: {
        id: this.userId.toString(),
      },
    };

    this.$router.push(route);
  }

  private async initializeModel() {
    if (this.userId) {
      const model = await UserService.get(this.userId);
      _.extend(this.model, model);
    }
  }
}
