import { HttpService } from "./common";
import {
  AssessmentTranscriptModel,
  AssessmentTranscriptCollectionModel,
  AssessmentTranscriptLookupModel,
  SimpleLookupModel,
} from "@/core/models";

export const AssessmentTranscriptService = {
  get(transcriptId: number, locale: string) {
    return HttpService.getData<AssessmentTranscriptModel>(
      `/assessmentTranscript/${transcriptId}/${locale}`,
    );
  },

  getForCourseSession(courseSessionId: number, locale: string) {
    return HttpService.getData<AssessmentTranscriptModel>(
      `/assessmentTranscript/forCourseSession/${courseSessionId}/${locale}`,
    );
  },

  getForCourse(courseId: number) {
    return HttpService.getData<AssessmentTranscriptCollectionModel>(
      `/assessmentTranscript/forCourse/${courseId}`,
    );
  },

  create(model: AssessmentTranscriptModel) {
    return HttpService.postModel(`/assessmentTranscript`, model);
  },

  createDraft(transcriptId: number) {
    return HttpService.put(`/assessmentTranscript/${transcriptId}/createDraft`);
  },

  update(model: AssessmentTranscriptModel) {
    return HttpService.putModel(`/assessmentTranscript`, model);
  },

  publish(transcriptId: any) {
    return HttpService.put(`/assessmentTranscript/publish/${transcriptId}`);
  },

  deleteDraft(transcriptId: any) {
    return HttpService.delete(`/assessmentTranscript/${transcriptId}/draft`);
  },

  Unpublish(assessmentId: any) {
    return HttpService.put(`/assessmentTranscript/${assessmentId}/unpublish`);
  },

  formLookupData() {
    return HttpService.getData<AssessmentTranscriptLookupModel>(`/assessmentTranscript/formLookupData`);
  },

  getTranscriptLocales(courseSessionId: number) {
    const url = `/assessmentTranscript/transcriptLocales?courseSessionId=${courseSessionId}`;
    return HttpService.getData<SimpleLookupModel[]>(url);
  },
};
