export enum EmailScheduleType {
  EducatorMassEmail = 1,
}

export enum EmailScheduleStatus {
  AwaitingApproval = 1,
  Declined = 2,
  Approved = 3,
  Sent = 4,
}
