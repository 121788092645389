import { SimpleLookupModel } from "@/core/models";
export class ReportModel {
  reportId: string = "";
  filter: ReportFilterModel = new ReportFilterModel();
}

export class ReportEmbedModel {
  url: string = "";
  embedToken: string = "";
}

export class ReportFilterModel {
  startDate: Date|undefined = undefined;
  endDate: Date|undefined = undefined;
  levelIds: number[] = [];
  regionIds: number[] = [];
  unionIds: number[] = [];
}

export class ReportLookupsModel {
  deliveryTypes: SimpleLookupModel[] = [];
  levels: SimpleLookupModel[] = [];
  unions: SimpleLookupModel[] = [];
  regions: SimpleLookupModel[] = [];
}