import { Language } from "./languages";

import en from "./messages/en";
import es from "./messages/es";
import fr from "./messages/fr";
import ja from "./messages/ja";
import ru from "./messages/ru";
import ro from "./messages/ro";
import ptbr from "./messages/pt-br";
import nl from "./messages/nl";
import de from "./messages/de";
import zhcn from "./messages/zh-cn";

export const messages = {
  [Language.EN]: en,
  [Language.ES]: es,
  [Language.FR]: fr,
  [Language.JA]: ja,
  [Language.RU]: ru,
  [Language.RO]: ro,
  [Language.PTBR]: ptbr,
  [Language.NL]: nl,
  [Language.DE]: de,
  [Language.ZHCN]: zhcn,
};

export const defaultLanguage: Language = Language.EN;