import { HttpService } from "./common/http.service";
import { DefaultLocale } from "@/core/services";
import { QuestionnaireModel, QuestionnaireLookupModel } from "@/core/models";

export const QuestionnaireService = {
  getQuestionnaireByCourseId(courseId: number, locale?: string) {
    const config = {
      params: {
        courseId,
        locale,
        altLocale: DefaultLocale,
      },
    };

    return HttpService.getData<QuestionnaireModel>(`/questionnaires`, config);
  },

  getQuestionnaire(id: number) {
    return HttpService.getData<QuestionnaireModel>(`/questionnaires/${id}`);
  },

  getQuestionnaireId(courseId: number) {
    return HttpService.getData<number>(`/questionnaires/id?courseid=${courseId}`);
  },

  getLookups() {
    return HttpService.getData<QuestionnaireLookupModel>(`/questionnaires/lookups`);
  },

  update(model: QuestionnaireModel) {
    return HttpService.putModel(`/questionnaires`, model);
  },

  create(courseId: number) {
    const model = {
      courseId,
    };

    return HttpService.postModel(`/questionnaires?courseId=${courseId}`, {});
  },
};
