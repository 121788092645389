export const profileRoutes = [
  {
    path: "/profile/courses",
    name: "profileCourses",
    meta: {
      title: "Courses attended",
      tag: "navigation.coursesAttended",
      localise: true,
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/profile/ProfileCourses.vue"),
  },
  {
    path: "/profile/licenses",
    name: "profileLicenses",
    meta: {
      title: "Licenses awarded",
      tag: "navigation.licensesAwarded",
      localise: true,
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/profile/ProfileLicenses.vue"),
  },
];
