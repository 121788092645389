import { HttpService } from "./common/http.service";
import {
  PagedCollectionFilter,
  PagedCollection,
  EmailScheduleCollectionItemModel,
  EmailScheduleLookupModel,
  EmailScheduleDetailsModel,
} from "@/core/models";

export const EmailScheduleService = {
  updateScheduleEmailStatus(
    emailScheduleId: number,
    emailScheduleStatusId: number
  ) {
    return HttpService.put(
      `/emailschedule/${emailScheduleId}/status/${emailScheduleStatusId}`
    );
  },
  filterEmailSchedule(filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<EmailScheduleCollectionItemModel>>(
      "/emailschedule/filter",
      { params: filter }
    );
  },
  getEmailScheduleLookup() {
    return HttpService.getData<EmailScheduleLookupModel>(
      "/emailschedule/filter/lookups"
    );
  },
  getEmailScheduleDetails(emailScheduleId: number) {
    return HttpService.getData<EmailScheduleDetailsModel>(
      `/emailschedule/details/${emailScheduleId}`
    );
  },
};
