
import AppVue from "@/apps/AppVue.vue";

export default abstract class BaseFormComponent extends AppVue {
  submitInProgress = false;
  abstract onSubmitForm(): Promise<void>;

  async submitForm() {
    if (!this.onSubmitForm) {
      throw new Error("onSubmitForm not implemented");
    }

    this.submitInProgress = true;
    this.$forceUpdate();

    try {
      await this.onSubmitForm();
    } finally {
      this.submitInProgress = false;
      this.$forceUpdate();
    }
  }
}
