export const correspondenceRoutes = [
  {
    path: "/correspondence",
    name: "correspondenceList",
    meta: {
      title: "Correspondence",
      roles: 
        "GlobalTrainingManager,RegionalTrainingManager",
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/correspondence/CorrespondenceList.vue"),
  },
];
