


















import { Component, Prop, Watch } from "vue-property-decorator";
import _ from "lodash";

import AppVue from "@/apps/AppVue.vue";

import {
  CertificateStatisticsCollectionModel,
  NormalizedCertificateStatisticsModel,
  CertificateStatisticsRowType,
} from "@/core/models";

@Component({
  components: {},
})
export default class CertificateStatistics extends AppVue {
  @Prop() collection: CertificateStatisticsCollectionModel[];
  @Prop() column1Header: string;
  @Prop() column2Header: string;

  get normalizedData() {
    const normalized: NormalizedCertificateStatisticsModel[] = [];

    if (_.isEmpty(this.collection)) {
      return normalized;
    }

    _.each(this.collection, (strand) => {
      normalized.push({
        rowType: CertificateStatisticsRowType.Heading,
        col1: strand.strandName,
      });

      _.each(strand.courses, (course) => {
        normalized.push({
          rowType: CertificateStatisticsRowType.Data,
          col1: course.courseCode,
          col2: course.courseName,
          col3: course.increase,
          col4: course.value1,
          col5: course.value2,
        });
      });

      normalized.push({
        rowType: CertificateStatisticsRowType.Totals,
        col1: this.localiseString("activeCerts.total"),
        col3: strand.increase,
        col4: strand.total1,
        col5: strand.total2,
      });
    });

    return normalized;
  }

  getRowClass({ row, rowIndex }: any) {
    switch (row.rowType) {
      case CertificateStatisticsRowType.Heading:
        return "heading-row";
      case CertificateStatisticsRowType.Totals:
        return "totals-row";
      default:
        return "";
    }
  }
}
