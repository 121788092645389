import { HttpService, DownloadProvider} from "./common";
import {
  CourseAttendeeCollectionModel,
  CourseAttendeeLookupModel,
  FileDownloadModel,
  PagedCollectionFilter,
  PagedCollection,
} from "@/core/models";

export const CourseAttendeeService = {
  async dowmloadCertificatePdf(
    userId: number,
    courseAttendeeId: number,
    locale: string) {

    const response = await HttpService.getData<FileDownloadModel>(
      `users/${userId}/certificate/${courseAttendeeId}/download/${locale}`);

    DownloadProvider.downloadResponse(response);
  },

  getListLookupData(filter: PagedCollectionFilter) {
    return HttpService.getData<CourseAttendeeLookupModel>(
      "course-attendees/list-lookup-data",
      { params: filter });
  },

  filter(filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<CourseAttendeeCollectionModel>>(
      "course-attendees/filter",
      { params: filter });
  },
};
