export const userRoutes = [
  {
    path: "/users",
    name: "userList",
    meta: {
      title: "Users",
      roles: "UnionTrainingManager,UnionAdministrator,RegionalTrainingManager," +
        "GlobalTrainingManager,IrisAdministrator",
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/user/UserList.vue"),
  },
  {
    path: "/users/invite",
    name: "userInvite",
    meta: {
      title: "Invite a User",
      roles: "UnionTrainingManager,UnionAdministrator,RegionalTrainingManager," +
        "GlobalTrainingManager,IrisAdministrator",
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/user/UserInvite.vue"),
  },
  {
    path: "/users/:id/details",
    name: "userDetails",
    meta: {
      title: "User Details",
      roles: "UnionTrainingManager,UnionAdministrator,RegionalTrainingManager," +
        "GlobalTrainingManager,IrisAdministrator",
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/user/UserDetailsView.vue"),
  },
  {
    path: "/users/:id/courses",
    name: "userCourses",
    meta: {
      title: "User Courses",
      roles: "UnionTrainingManager,UnionAdministrator,RegionalTrainingManager," +
        "GlobalTrainingManager,IrisAdministrator",
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/user/UserCoursesView.vue"),
  },
  {
    path: "/users/:id/courses-delivered",
    name: "userCoursesDelivered",
    meta: {
      title: "User Courses Delivered",
      roles: "UnionTrainingManager,UnionAdministrator,RegionalTrainingManager," +
        "GlobalTrainingManager,IrisAdministrator",
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/user/UserCoursesDeliveredView.vue"),
  },
  {
    path: "/users/:id/licenses",
    name: "userLicenses",
    meta: {
      title: "User Licenses",
      roles: "UnionTrainingManager,UnionAdministrator,RegionalTrainingManager," +
        "GlobalTrainingManager,IrisAdministrator",
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/user/UserLicensesView.vue"),
  },
  {
    path: "/users/:id/roles",
    name: "userRoles",
    meta: {
      title: "User Roles",
      roles: "UnionAdministrator,UnionTrainingManager,RegionalTrainingManager," +
        "GlobalTrainingManager,IrisAdministrator",
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/user/UserRolesView.vue"),
  },
  {
    path: "/users/:id/update-email",
    name: "userUpdateEmail",
    meta: {
      title: "Update user email",
      roles: "UnionAdministrator,UnionTrainingManager,RegionalTrainingManager," +
        "GlobalTrainingManager,IrisAdministrator",
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/user/UserUpdateEmailView.vue"),
  },
  {
    path: "/users/:id/merge",
    name: "mergeUser",
    meta: {
      title: "Merge Users",
      roles: "GlobalTrainingManager,IrisAdministrator",
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/user/UserMergeView.vue"),
  },
  {
    path: "/users/:id",
    name: "deleteUser",
    meta: {
      title: "Delete User",
      roles: "GlobalTrainingManager,IrisAdministrator",
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/user/UserDeleteView.vue"),
  },
];
