
import { HttpService } from "./common/http.service";
import _ from "lodash";
import {
  UserModel,
  UserLookupModel,
  UserCourseAttendeeModel,
  UserCourseSessionModel,
  UserInfoModel,
  UserTermsAndConditionsModel,
  UserRoleModel,
  UpdateUserRolesModel,
  LicenseCollectionModel,
  PagedCollection,
  PagedCollectionFilter,
  UserInviteModel,
  UpdateUserEmailModel,
  MergeUserModel,
  CourseLookupModel,
  CheckMultiUserModel,
  CheckMultiUserResponseModel,
} from "@/core/models";

export const UserService = {
  async find(search: string, isExactEmail: boolean) {
    let params = {};
    if (isExactEmail) {
      params = {
        isExactEmail,
      };
    }

    return this.baseFind(search, "/users/filter", params);
  },
  async findPrivate(search: string) {
    return this.baseFind(search, "/users/privateFilter");
  },
  async findForCourseSession(search: string, courseSessionId: number, isExactEmail: boolean = false) {
    return this.baseFind(search, "/courseSessions/filterUsers", { courseSessionId, isExactEmail });
  },
  async baseFind(search: string, url: string, extraParams: object = {}) {
    if (!search) {
      return [];
    }

    const params = _.extend({
      page: 1,
      pageSize: 0,
      search,
      sortBy: "user.fullname",
    }, extraParams);

    const result = await HttpService.getData<PagedCollection<UserModel>>(url, { params });

    return result.items;
  },
  async get(userId: number) {
    return HttpService.getData<UserModel>(`/users/${userId}`);
  },
  filterUsers(filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<UserModel>>("/users/filter", { params: filter });
  },
  getFilterLookupModel() {
    return HttpService.getData<UserLookupModel>("/users/filterLookupData");
  },
  getCourses(userId: number) {
    return HttpService.getData<UserCourseAttendeeModel[]>(`/users/${userId}/courseAttendee`);
  },
  getLicences(userId: number) {
    return HttpService.getData<LicenseCollectionModel[]>(`/users/${userId}/license`);
  },
  getMyLicences(filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<LicenseCollectionModel>>(
      `/users/me/licenses`,
      { params: filter });
  },
  getUserRolesLookupData(userId: number) {
    return HttpService.getData<UserRoleModel[]>(`/users/userRoles/lookupData/${userId}`);
  },
  getUserRoles(userId: number) {
    return HttpService.getData<UserRoleModel[]>(`/users/${userId}/userRoles`);
  },
  getMyUserInfo() {
    return HttpService.getData<UserInfoModel>(`/users/me/userInfo`);
  },
  getMyAcceptedTemsAndConditions() {
    return HttpService.getData<UserTermsAndConditionsModel>(`/users/me/terms-and-conditions`);
  },
  setMyAcceptedTemsAndConditions(model: UserTermsAndConditionsModel) {
    return HttpService.putModel(`/users/me/terms-and-conditions`, model);
  },
  getProfileLookups() {
    return HttpService.getData<CourseLookupModel>("/users/me/lookups");
  },
  updateUserRoles(userId: number, model: UpdateUserRolesModel) {
    return HttpService.putModel(`/users/${userId}/userRoles`, model);
  },
  inviteBackendUser(model: UserInviteModel) {
    return HttpService.putData<UserModel>("/users/inviteToBackend", model);
  },
  updateUserEmail(userId: number, model: UpdateUserEmailModel) {
    return HttpService.putData<UserModel>(`/users/${userId}/email`, model);
  },
  mergeUsers(userId: number, model: MergeUserModel) {
    return HttpService.putData<UserModel>(`/users/${userId}/merge`, model);
  },
  deleteUser(userId: number) {
    return HttpService.deleteData<boolean>(`/users/${userId}`);
  },
  filterUserCourses(userId: number, filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<UserCourseAttendeeModel>>(
      `/users/${userId}/filter/courseAttendee`, { params: filter });
  },
  filterMyUserCourses(filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<UserCourseAttendeeModel>>(
      "/users/me/filter/courseAttendee", { params: filter });
  },
  filterUserCoursesDelivered(userId: number, filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<UserCourseSessionModel>>(
      `/users/${userId}/filter/courseEducator`, { params: filter });
  },
  checkUsers(model: CheckMultiUserModel) {
    return HttpService.postData<CheckMultiUserResponseModel>(
      `/users/checkUsers`,
      model,
    );
  },
};
