

























import { Component, Prop, Emit, Vue } from "vue-property-decorator";

@Component
export default class DtInput extends Vue {
  @Prop() private label!: string;
  @Prop({default: "text"}) private type!: string;
  @Prop() private value!: any;
  @Prop() private property!: string;
  @Prop() private alternativeModel!: string;
  @Prop({default: false}) private disabled!: boolean;

  get showError() {
    return this.errorModel != null;
  }

  get hasAlternativeModel() {
    if (this.alternativeModel === "") {
      return false;
    }

    return this.value != null && this.value[this.alternativeModel] != null;
  }

  get alternativeContent() {
    return this.value[this.alternativeModel][this.property];
  }

  get errorMessage() {
    return this.showError
      ? this.errorModel[0]
      : "";
  }

  get isDisabled(): boolean {
    return this.disabled;
  }

  private get errorModel() {
    if (this.value.modelState && this.value.modelState[this.property]) {
      return this.value.modelState[this.property];
    }

    return null;
  }

  @Emit("input")
  private handleInput(event: any) {
    return this.value;
  }
}
