import { SimpleLookupModel, UploadFileModel } from "@/core/models";

export class EmailScheduleLookupModel {
  emailScheduleTypes: SimpleLookupModel[] = [];
  emailScheduleStatuses: SimpleLookupModel[] = [];
}

export class EmailScheduleModel {
    emailScheduleId: number;
    emailScheduleTypeId: number;
    emailScheduleType: string;
    emailSubject: string;
    emailScheduleStatusId: number;
    emailScheduleStatus: string;
    dateCreated: Date;
    dateApproved?: Date;
    dateSent?: Date;
    dateDeclined?: Date;
    createdByUserName: string;
    approvedByUserName: string;
    declinedByUserName: string;
  }

export class EmailScheduleCollectionItemModel extends EmailScheduleModel {
  countAttachments: number;
  countUsers: number;
}

export class EmailScheduleDetailsModel extends EmailScheduleModel {
    filter: string;
    userNames: string[] = [];
    attachments: UploadFileModel[] = [];
  }