import { Validatable } from "@/core/models";
import { UploadFileModel } from "./common/UploadFileModels";

export class EmailTemplateModel extends Validatable {
  emailTemplateId: number;
  emailTemplateTypeId: number;
  locale: string;
  subject: string;
  htmlTemplate: string;
  textTemplate: string;
  courseId: number;
  attachments: UploadFileModel[];
  isActive: boolean;
}

export class EmailTemplateTypeModel {
  id: number;
  name: string;
  description: string;
  emailTemplate: EmailTemplateModel | undefined;
}