import { HttpService } from "./common/http.service";
import {
  CourseLocaleModel,
} from "@/core/models";

export const CourseLocaleService = {
  GetByCourseId(courseId: number) {
    return HttpService.getData<CourseLocaleModel[]>(`courses/${courseId}/locales`);
  },

  save(courseId: number, model: CourseLocaleModel[]) {
    return  HttpService.postModel(`courses/${courseId}/locales`, model);
  },
};
