





























import { Component, Prop } from "vue-property-decorator";
import AppVue from "@/apps/AppVue.vue";

import { AuditRecordService } from "@/core/services";
import { AuditRecordNoteModel } from "@/core/models";
import InputField from "@/components/common/form/InputField.vue";
import ValidationHint from "@/components/common/form/ValidationHint.vue";
import ValidationMessage from "@/components/common/form/ValidationMessage.vue";
import { CourseTranslationTags } from "@/core/constants";

@Component({
  components: {
    InputField,
    ValidationHint,
    ValidationMessage,
  },
})
export default class AddAuditRecord extends AppVue {
  @Prop() pkId: number;
  @Prop() auditRecordTypeId: number;
  @Prop({ default: "info" }) buttonType: string;
  @Prop() buttonSize: string;
  @Prop({ default: "Add note" }) buttonText: string;
  model: AuditRecordNoteModel = {
    auditRecordId: undefined,
    auditRecordTypeId: 0,
    forPkId: 0,
    note: "",
    modelState: {},
  };
  isNoteFormVisible: boolean = false;
  submitInProgress = false;

  get courseSessionId() {
    return this.$route.params.id;
  }

  async submitForm() {
    this.submitInProgress = true;
    try {
      this.model.forPkId = +this.pkId;
      this.model.auditRecordTypeId = this.auditRecordTypeId;

      await AuditRecordService.addNote(this.model);
      this.isNoteFormVisible = false;
      this.model.note = "";
      this.$emit("note-added");

      this.$notify({
        title: "Success",
        message: "Note saved.",
        type: "success",
      });
    } finally {
      this.submitInProgress = false;
    }
  }
}
