import moment from "moment";

import { Validatable } from "@/core/models";

export class CourseCertificateTemplateCollectionModel {
  id: number;
  effectiveDate: Date;
  signaturee1Name: string;
  signaturee2Name: string;
}

export class CourseCertificateTemplateModel extends Validatable {
  id: number|null = null;
  courseId: number = 0;
  effectiveDate: Date = moment().startOf("day").toDate();
  signaturee1Name: string = "";
  signature1Filename: string = "";
  signature1Url: string = "";
  signaturee2Name: string = "";
  signature2Filename: string = "";
  signature2Url: string = "";
  templateLocales: CourseCertificateTemplateLocaleCollectionModel[] = [];
}

export class CourseCertificateTemplateLocaleCollectionModel {
  id: number;
  localeCode: string;
  courseName: string;
  signature1Title: string;
  signature2Title: string;
}

export class CourseCertificateTemplateLocaleModel extends Validatable {
  id: number|null = null;
  courseCertificateTemplateId: number = 0;
  localeCode: string = "";
  courseName: string = "";
  signature1Title: string = "";
  signature2Title: string = "";
  certifyMessage: string = "";
  onDayMessage: string = "";
  accreditationMessage: string = "";
  successfullyCompletedMessage = "";
}