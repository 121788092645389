
import { Prop } from "vue-property-decorator";
import _ from "lodash";

import { AutoCompleteItem } from "@/core/models";
import { BaseInputComponent } from ".";

export default class SelectableInputComponent extends BaseInputComponent {
  @Prop() lookups: any[];
  @Prop({ default: "id" }) lookupIdProp: string;
  @Prop() lookupTextProp: string;
  @Prop() getLookupText?: (lookup: any) => string;

  /**
   * This allows custom lookup text method.
   */
  getLookupItemText(lookup: any) {
    if (this.getLookupText) {
      return this.getLookupText(lookup);
    }

    if (!!this.lookupTextProp && this.lookupTextProp.length > 0 && !!lookup[this.lookupTextProp]) {
      return lookup[this.lookupTextProp];
    }

    /* tslint:disable:no-string-literal */
    return lookup["text"] || lookup["name"] || lookup[this.lookupTextProp];
    /* tslint:enable:no-string-literal */
  }

  protected filterLookups(lookups: any[], query: string) {
    if (!query) {
      return this.lookups;
    }

    return _.filter(this.lookups, (lookup) => {
      const lookupText = _.toLower(this.getLookupItemText(lookup));
      return _.includes(lookupText, _.toLower(query));
    });
  }

  protected toAutocompleteFormat(lookups: any[]) {
    return _.map(lookups, (lookup) => {
      return {
        id: lookup.id,
        value: this.getLookupItemText(lookup),
      } as AutoCompleteItem;
    });
  }
}
