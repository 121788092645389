




















import { Component, Prop } from "vue-property-decorator";

import { ValidationMessage, BaseInputComponent } from ".";
import LabelWithTooltip from "./LabelWithTooltip.vue";

@Component({
  components: {
    ValidationMessage,
    LabelWithTooltip,
  },
})
export default class InputField extends BaseInputComponent {
  @Prop({ default: "text" }) type: string;
  @Prop() autofocus?: boolean;
  @Prop() autosize?: any;
  @Prop() rows?: any;
}
