






























































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import _ from "lodash";

import CourseAppVue from "@/apps/CourseAppVue.vue";
import {
  PublishStatus,
} from "@/core/constants";
import {
  AssessmentTranscriptModel,
  AssessmentCompetencyModel,
  AssessmentTranscriptLookupModel,
} from "@/core/models";
import {
  AssessmentTranscriptService,
  LocaleService,
  DefaultLocale,
} from "@/core/services";

@Component
export default class CourseTranscriptForm extends CourseAppVue {
  @Prop() courseId: number;
  @Prop() transcriptId?: number;

  get isViewMode() {
    return this.$route.name === "courseTranscriptsView";
  }

  isDirty = false;
  sortableCollection: any[] = [];
  defaultLocaleCode = DefaultLocale;
  selectedLocaleCode = DefaultLocale;
  lookups: AssessmentTranscriptLookupModel = {
    statuses: [],
    locales: [],
  };
  showSortDialog = false;
  model: AssessmentTranscriptModel = {
    transcriptId: undefined,
    courseId: 0,
    localeId: 1, // en
    version: 1,
    statusId: PublishStatus.Draft,
    competencies: [{
      competencyId: undefined,
      description: "",
      sortOrder: 1,
      criteria: [{
        criteriaId: undefined,
        description: "",
        sortOrder: 1,
      }],
    }],
  } as any;

  async created() {
    this.model.courseId = this.courseId;

    if (this.$route.params.locale) {
      this.selectedLocaleCode = this.$route.params.locale;
    }
    await this.bindData();
    if (this.model.competencies.length > 0) {
      this.elSetFocus("competency-0");
    }
  }

  @Watch("$route", { deep: true })
  async bindData() {
    const loading = this.$loading({
      lock: true,
      text: "Getting data...",
    });

    await this.bindLookups();
    await this.bindModel();

    loading.close();

    this.isDirty = false;
  }

  @Watch("model", { deep: true})
  onFormChange() {
    this.isDirty = true;
  }

  async bindLookups() {
    this.lookups = await AssessmentTranscriptService.formLookupData();
  }

  async bindModel() {
    if (this.transcriptId) {
      const responseModel = await AssessmentTranscriptService.get(this.transcriptId, this.selectedLocaleCode);
      this.model = responseModel;
    }
  }

  get statusLabel() {
    const status = _.find(this.lookups.statuses, { id: this.model.statusId });

    return status && status.text ? status.text : "N/A";
  }

  get isDefaultLocaleSelected() {
    return this.selectedLocaleCode === this.defaultLocaleCode;
  }

  get hasAlternativeModel() {
    return !this.isDefaultLocaleSelected && !!this.model.alternativeTranscript;
  }

  async onSubmit() {
    const loading = this.$loading({
      lock: true,
      text: "Saving data...",
    });

    try {
      if (this.model.transcriptId) {
        await AssessmentTranscriptService.update(this.model);
      } else {
        await AssessmentTranscriptService.create(this.model);
      }

      this.redirectToTranscripts();
    } catch (err) {
      this.$notify({
        title: "Transcription was not saved",
        message: "The transcription was not validated correctly. Did you fill in all the fields?",
        type: "error",
      });
    } finally {
      loading.close();
      this.$forceUpdate();
    }
  }

  onCancel() {
    if (!this.isDirty) {
      this.redirectToTranscripts();
      return;
    }

    this.$confirm("Are you sure that you want to cancel?",
      "Leave question?", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
    })
    .then(() => {
      this.redirectToTranscripts();
    });
  }

  onChangeLocale() {
    if (!this.isDirty) {
      this.refreshRoute();
      return;
    }

    this.$confirm("Are you sure that you want edit different locale?",
      "Leave question?", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
    })
    .then(() => {
      this.refreshRoute();
    });
  }

  onOpenSortDialog(collectionToSort: any[]) {
    this.sortableCollection =  collectionToSort;
    if (collectionToSort.length > 1) {
      this.showSortDialog = true;
    }
  }

  onCloseDialog() {
    // This is to revert to original sort in case of cancelling
    this.sortableCollection = this.sortableCollection
      .sort((item1, item2) => item1.sortOrder - item2.sortOrder);
    this.sortableCollection = [];
    this.showSortDialog = false;
  }

  onConfirmSortOrders() {
    _.each(this.sortableCollection, (item, index) => {
      item.sortOrder = index + 1;
    });
    
    this.onCloseDialog();
  }

  addNewCompetency() {
    const maxSortObject =  _.maxBy(this.model.competencies, (competency) => competency.sortOrder);
    const maxSortOrder = maxSortObject ? maxSortObject.sortOrder : 0;

    this.model.competencies.push({
      competencyId: undefined as any,
      description: "",
      sortOrder: maxSortOrder + 1,
      criteria: [{
        criteriaId: undefined as any,
        description: "",
        sortOrder: 1,
      }],
    });

    this.setFocus("competency", this.model.competencies.length - 1);
    this.isDirty = true;
  }

  setFocus(type: string, index: number) {
    if (index < 0) {
      return;
    }

    this.elSetFocus(`${type}-${this.model.competencies.length - 1}`);
  }

  addNewCriteria(competency: AssessmentCompetencyModel, compIndex: number) {
    const maxSortObject =  _.maxBy(competency.criteria, (criteria) => criteria.sortOrder);
    const maxSortOrder = maxSortObject ? maxSortObject.sortOrder : 0;

    const newLength = competency.criteria.push({
      criteriaId: undefined as any,
      description: "",
      sortOrder: maxSortOrder + 1,
    });

    this.elSetFocus(`criteria-${compIndex}-${newLength - 1}`);
    this.isDirty = true;
  }

  removeCompetency(competencyIndex: number) {
    this.$confirm("Are you sure that you want to delete this competency?",
      "Delete competency?", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
    })
    .then(() => {
      this.model.competencies.splice(competencyIndex, 1);
    });
  }

  removeCriteria(competency: AssessmentCompetencyModel, criteriaIndex: number) {
    this.$confirm("Are you sure that you want to delete this criteria?",
      "Delete criteria?", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
    })
    .then(() => {
      competency.criteria.splice(criteriaIndex, 1);
    });
  }

  allowSortDrop(draggingNode: any, dropNode: any, type: any) {
    // Don't allow any node to become a child of a node on the tree
    return type !== "inner";
  }

  redirectToTranscripts() {
    this.$router.push({
      name: "courseTranscripts",
      params: {
        id: this.courseId as any,
      },
    });
  }

  refreshRoute() {
    this.$router.push({
      name: this.$route.name,
      params: {
        id: this.courseId as any,
        transcriptId: this.transcriptId as any,
        locale: this.selectedLocaleCode as any,
      },
    });
  }

  getValidationMessage(compIndex: number, critIndex?: number) {
    const identifier = this.getValidationMessageIdentifier(compIndex, critIndex);

    if (!this.model || !this.model.modelState || !identifier) {
      return null;
    }

    const propValidation = this.model.modelState[identifier];
    if (!propValidation) {
      return null;
    }

    return propValidation[0] || null;
  }

  private getValidationMessageIdentifier(compIndex: number, critIndex?: number) {
    if (!_.isNumber(compIndex)) {
      return null;
    }

    const identifier = `competencies[${compIndex}]`;

    if (!_.isNumber(critIndex)) {
      return `${identifier}.Description`;
    }

    return `${identifier}.Criteria[${critIndex}].Description`;
  }
}
