













import { Component, Prop } from "vue-property-decorator";

import { ValidationMessage, SelectableInputComponent } from ".";

@Component({
  components: {
    ValidationMessage,
  },
})
export default class InputRadio extends SelectableInputComponent {}
