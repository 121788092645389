import {
  LicenseAuditStatus,
  QualityAuditStatus,
  QualityAuditType } from "@/core/constants";
import {
  Validatable,
  AuditRecorCollectionModel,
  LicenseSummaryModel,
  SimpleLookupModel,
  CourseSessionLookupModel,
  CourseSessionSummaryModel,
  UserModel } from "@/core/models";

export class QualityAuditCollectionModel {
  id: number|null = null;
  licenseId: number;
  licenseAuditStatusId: number|null = LicenseAuditStatus.AwaitingAudit;
  licenseAuditStatusName: string = "";
  qualityAuditStatusId: number|null = QualityAuditStatus.Draft;
  qualityAuditStatusName: string = "";
  auditDate: Date|null  = null;
  datePublished: Date|null = null;
  userFullName: string = "";
  userEmail: string = "";
  strandName: string = "";
  levelName: string = "";
  auditor: UserModel = new UserModel();
}

export class QualityAuditLookupModel {
  locales: SimpleLookupModel[] = [];
  auditors: SimpleLookupModel[] = [];
  qualityAuditTypes: SimpleLookupModel[] = [];
  courseSessions: CourseSessionLookupModel[] = [];
  license: LicenseSummaryModel = new LicenseSummaryModel();
}

export class SubmitQualityAuditLookupModel {
  qualityAuditGrades: SimpleLookupModel[] = [];
  qualityBehaviours: SimpleLookupModel[] = [];
  courseSession: CourseSessionSummaryModel = new CourseSessionSummaryModel();
  license: LicenseSummaryModel = new LicenseSummaryModel();
}

export class QualityAuditModel extends Validatable {
  id: number|null = null;
  licenseId: number = -1;
  licenseAuditStatusId: number = LicenseAuditStatus.AwaitingAudit;
  licenseAuditStatusName: string = "";
  qualityAuditStatusId: number = QualityAuditStatus.Draft;
  qualityAuditTypeId: number = QualityAuditType.Course;
  auditDate: Date|null  = null;
  localeId: number|null = null;
  courseSessionId: number|null = null;
  auditorUserId: number|null = null;
  isReadOnly: boolean = false;
  auditRecords: AuditRecorCollectionModel[] = [];
}

export class QualityAuditSubmitModel extends Validatable {
  id: number;
  auditDate: Date|undefined = undefined;
  qualityAuditType: string = "";
  qualityAuditTypeId = 1;
  details: string = "";
  areasOfStrength: string = "";
  areasForDevelopment: string = "";
  agreedActionPlan: string = "";
  educatorSelfReflection: string = "";
  qualityAuditStatusId: number = QualityAuditStatus.Draft;
  qualityAuditGradeId: number|undefined = undefined;
  qualityBehaviourId: number|undefined = undefined;
  datePublished: Date|undefined = undefined;
  auditor: UserModel = new UserModel();
  publishedByUser: UserModel = new UserModel();
  isReadOnly: boolean = false;
  isFeedbackReadOnly: boolean = false;
  educatorAudits: QualityAuditCollectionModel[] = [];
  auditorAudits: QualityAuditCollectionModel[] = [];
}

export class QualityAuditGridLookupModel {
  statuses: SimpleLookupModel[] = [];
  levels: SimpleLookupModel[] = [];
  regions: SimpleLookupModel[] = [];
  unions: SimpleLookupModel[] = [];
  strands: SimpleLookupModel[] = [];
}
