import moment from "moment";

export const DateService = {
  formatDate(date: Date) {
    return moment(date).format("YYYY-MM-DD");
  },

  formatDateAndTime(date: any) {
    return moment(date).format("YYYY-MM-DD hh:mm:ss");
  },

  formatIso(date: Date) {
    return moment(date).format();
  },
};
