import {
  CourseModel,
  CourseCollectionModel,
  SimpleLookupModel,
  UserCourseSessionCollectionModel,
  UserModel,
  PagedCollectionFilter,
} from "@/core/models";
import { CourseAttendeeStatus } from "@/core/constants";

export class UserCourseAttendeeModel {
  courseAttendeeId: number;
  userId: number;
  courseId: number;
  course: CourseModel;
  progress: number;
  startDate: Date;
  certificationDate: Date;
  certificationExpiryDate: Date|undefined;
  courseSession: UserCourseSessionCollectionModel;
  status: CourseAttendeeStatus|undefined;
}

export class CourseAttendeeCollectionModel {
  courseAttendeeId: number;
  user: UserModel;
  course: CourseCollectionModel;
  certificationDate: Date|undefined;
  certificationExpiryDate: Date|undefined;
}

export class CourseAttendeeLookupModel {
  certificateStatuses: SimpleLookupModel[] = [];
  levels: SimpleLookupModel[] = [];
  strands: SimpleLookupModel[] = [];
  regions: SimpleLookupModel[] = [];
  unions: SimpleLookupModel[] = [];
  expiry: SimpleLookupModel[] = [];
  courses: SimpleLookupModel[] = [];
}

export class CourseAttendeeListPagedCollectionFilter extends PagedCollectionFilter {
  certificateStatusId: number|null = null;
  strandId: number|null = null;
  levelId: number|null = null;
  regionId: number|null = null;
  unionId: number|null = null;
  expiresWithin: number|null = null;
  courseId: number|null = null;
}