import Vue from "vue";
import IdleVue from "idle-vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";
import axios from "axios";
import VueAxios from "vue-axios";
import VueI18n from "vue-i18n";

import "vue-awesome/icons";
import Icon from "vue-awesome/components/Icon";
Vue.component("v-icon", Icon);

import App from "./App.vue";
import router from "@/router/router";
import store from "./store/store";
import MsalPlugin from "@/core/plugins/vue.msal.plugin";
import { messages, defaultLanguage } from "@/i18n";

import Default from "./shared/layouts/Default.vue";
import Empty from "./shared/layouts/Empty.vue";
import { StorageUtility } from "@/core/utilities/storage.utility";

Vue.component("default-layout", Default);
Vue.component("empty-layout", Empty);

Vue.config.productionTip = false;
Vue.prototype.$eventHub = new Vue();

Vue.use(VueAxios, axios);
Vue.use(MsalPlugin, {});
Vue.use(ElementUI, { locale });
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: StorageUtility.getSelectedLanguage(),
  fallbackLocale: defaultLanguage,
  messages,
});

const vm = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(IdleVue, {
  eventEmitter: vm.$eventHub,
  idleTime: 1000,
});