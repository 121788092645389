









import { Component, Prop } from "vue-property-decorator";
import AppVue from "@/apps/AppVue.vue";

@Component
export default class LabelWithTooltip extends AppVue {
  @Prop() label?: string;
  @Prop({ default: "" }) tooltipContent: string;
}
