








import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
  },
})
export default class Home extends Vue {
  get layout() {
    return (this.$route.meta.layout || "default") + "-layout";
  }
}
