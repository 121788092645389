import { Method, AxiosRequestConfig } from "axios";
import { Validatable, Map } from "@/core/models";
import { AxiosFactory } from "./";

// TODO: Model should be Validatable
async function modelRequest(resourceUri: string, verb: Method, model: any) {
  model.modelState = {} as Map<string[]>;

  const promise = (await AxiosFactory.create()).request({ url: resourceUri, method: verb, data: model });
  promise.then(() => { return; }, (error: any) => {
    if (error.response.status === 400) {
      model.modelState = error.response.data.errors;
    }
  });

  return promise;
}

export const HttpService = {
  getUri: async (config?: AxiosRequestConfig) => (await AxiosFactory.create()).getUri(config),
  request: async (config: AxiosRequestConfig) => (await AxiosFactory.create()).request(config),
  get: async (url: string, config?: AxiosRequestConfig) => (await AxiosFactory.create()).get(url, config),
  delete: async (url: string, config?: AxiosRequestConfig) => (await AxiosFactory.create()).delete(url, config),
  head: async (url: string, config?: AxiosRequestConfig) => (await AxiosFactory.create()).head(url, config),
  post: async (url: string, data?: any, config?: AxiosRequestConfig) =>
    (await AxiosFactory.create()).post(url, data, config),
  put: async (url: string, data?: any, config?: AxiosRequestConfig) =>
    (await AxiosFactory.create()).put(url, data, config),
  patch: async (url: string, data?: any, config?: AxiosRequestConfig) =>
    (await AxiosFactory.create()).patch(url, data, config),
  // TODO: Model should be Validatable
  async postModel(resourceUri: string, model: any) {
    return await modelRequest(resourceUri, "POST", model);
  },
  // TODO: Model should be Validatable
  async putModel(resourceUri: string, model: any) {
    return await modelRequest(resourceUri, "PUT", model);
  },
  getData: async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
    const response = await (await AxiosFactory.create()).get(url, config);

    return response.data as T;
  },
  getDataResponse: async (url: string, config?: AxiosRequestConfig) => {
    return await (await AxiosFactory.create()).get(url, config);
  },
  postData: async <T> (url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
    const response = modelRequest(url, "POST", data);
    return (await response).data as T;
  },
  putData: async <T> (url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
    const response = modelRequest(url, "PUT", data);
    return (await response).data as T;
  },
  deleteData: async <T> (url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
    const response = (await AxiosFactory.create()).delete(url, config);
    return (await response).data as T;
  },
};
