import { HttpService } from "./common/http.service";
import {
  DashboardModel,
  CourseSessionDashboardModel,
  AuditDashboardModel,
  CorrespondenceDashboardModel } from "@/core/models";

export const DashboardService = {
  getDashboardData() {
    return HttpService.getData<DashboardModel>(`/dashboard`);
  },
  getCourseSessionDashboardData() {
    return HttpService.getData<CourseSessionDashboardModel>(`/dashboard/course-sessions`);
  },
  getAuditDashboardData() {
    return HttpService.getData<AuditDashboardModel>(`/dashboard/audits`);
  },
  getCorrespondenceData() {
    return HttpService.getData<CorrespondenceDashboardModel>(`/dashboard/correspondence`);
  },
};
