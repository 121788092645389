import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=3e500352&scoped=true&"
import script from "./Sidebar.vue?vue&type=script&lang=ts&"
export * from "./Sidebar.vue?vue&type=script&lang=ts&"
import style0 from "./Sidebar.vue?vue&type=style&index=0&id=3e500352&prod&lang=scss&scoped=true&"
import style1 from "./Sidebar.vue?vue&type=style&index=1&id=3e500352&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e500352",
  null
  
)

export default component.exports