











import { Component, PropSync, Vue } from "vue-property-decorator";

@Component
export default class CourseExamQuestionOptionForm extends Vue {
    @PropSync("option") optionSync!: string;
    @PropSync("isCorrect") isCorrectSync!: boolean;
}
