import { HttpService } from "./common";
import {
  EducatorCollectionModel, 
  EducatorFilterModel,
  EducatorLookupModel,
  PagedCollectionFilter,
  PagedCollection,
} from "@/core/models";

export const EducatorService = {

  getCoursesDelivered(filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<EducatorCollectionModel>>(
      "/educators/courses-delivered", 
      { params: filter });
  },

  getLookupData(filter: EducatorFilterModel)
  {
    return HttpService.getData<EducatorLookupModel>(
      "/educators/lookup-data", 
      { params: filter });
  }
};
