








































































































































































































































































































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import _ from "lodash";

import AppVue from "@/apps/AppVue.vue";
import { 
  CourseSessionAttendeeStatus, 
  AuditRecordType, 
  PublishStatus,
  AssessmentCriteriaStatus } from "@/core/constants";
import {
  UserSelector,
} from "../common";
import AddAuditRecord from "@/components/audit-record/AddAuditRecord.vue";
import {
  CourseSessionModel,
  AssessmentModel,
  AutoCompleteItem,
  CourseSessionAttendeeCollectionModel,
  CourseSessionAttendanceSetStatusModel,
  CourseSessionAttendeeUpdateList,
  SimpleLookupModel,
  AddUserModel,
  CheckMultiUserModel,
  CourseModel,
  UserModel,
} from "@/core/models";
import {
  CourseService,
  CourseSessionService,
  UserService,
  AssessmentTranscriptService,
  UnionService,
} from "@/core/services";
import InputField from "@/components/common/form/InputField.vue";
import { DeliveryType } from "@/core/constants";

@Component({
  components: {
    UserSelector,
    AddAuditRecord,
    InputField,
  },
})
export default class CourseSessionAttendees extends AppVue {
  @Prop() courseSession: CourseSessionModel;
  sendAttendeeInvite: boolean = true;
  auditRecordTypeId: number = AuditRecordType.CourseSession;
  courseSessionId: number;
  attendees: CourseSessionAttendeeCollectionModel[] = [];
  course: CourseModel = new CourseModel();
  isReadOnly = false;
  selectedAttendeesState: CourseSessionAttendeeCollectionModel[] = [];
  attendanceStatuses: SimpleLookupModel[] = [];
  attendanceStatusId: number|null = null;
  addAttendeesMethod: number = 1;
  isAddAttendeeDialogOpen = false;
  isRemoveAttendeeDialogOpen = false;
  isAddingUser = false;
  isAddingMultipleAttendees = false;
  isUpdatingAttendeeList = false;
  attendeeEditList: CourseSessionAttendeeCollectionModel[] = [];
  isAddAssessmentDialogOpen = false;
  transcriptLocales: SimpleLookupModel[] = [];
  selectedAssessmentLanguage: string = "";
  courseAttendeeIdForAssessment: number = 0;
  assessmentDeliveryTypeId: number = +DeliveryType.FaceToFaceAssessment;
  draftPublishStatusId: number = PublishStatus.Draft;
  publishedPublishStatusId: number = PublishStatus.Published;
  addUserModel = new AddUserModel();
  addMultipleAttendeesModel = new CheckMultiUserModel();
  unions: SimpleLookupModel[] = [];
  nonExistingAttendeeEmails: string[] = [];
  hasUserBeenAdded = false;

  get isAdminView() {
    return this.$route.query.adminView === "y";
  }

  async created() {
    await this.bindData();
  }

  @Watch("$route", { deep: true })
  async bindData() {
    this.courseSessionId = Number(this.$route.params.id);
    const loading = this.$loading({
      lock: true,
      text: this.localiseString("common.gettingData"),
    });

    try {
      await this.initializeModel();
    } catch (err) {
      this.handleApiGetError(err);
    } finally {
      loading.close();
    }
  }

  async findUsers(search: string, isExactEmail: boolean) {
    return UserService.findForCourseSession(search, this.courseSessionId, isExactEmail);
  }

  async onRemoveAttendeeClicked(attendee: CourseSessionAttendeeCollectionModel) {
    const index = _.findIndex(this.attendeeEditList, {userId: attendee.userId});
    if (index > -1) {
      this.attendeeEditList.splice(index, 1);
    }
  }

  async onAttendeeSelected(user: AutoCompleteItem) {
    // Double check that the user is not already on attendee list
    if (_.findIndex(this.attendeeEditList, {userId: user.id}) > -1) {
      return;
    }

    // Get user details and them them to temporary attendee list
    const userModel = await UserService.get(user.id);
    this.addUserAsAttendee(userModel);
  }

  async addUserAsAttendee(userModel: UserModel) {
    // Double check that the user is not already on attendee list
    if (_.findIndex(this.attendeeEditList, {userId: userModel.userId}) > -1) {
      return;
    }

    this.hasUserBeenAdded = true;

    // Get user details and them them to temporary attendee list
    const unmet = await CourseSessionService.getUnmetConditions(
      this.courseSessionId,
      userModel.userId);

    this.attendeeEditList.push({
      courseSessionAttendeeId: 0,
      courseSessionId: this.courseSessionId,
      userId: userModel.userId,
      userFullName: userModel.fullName,
      unionId: userModel.unionId,
      unionName: userModel.unionName,
      statusId: 0,
      unmetPrerequisites: unmet,
      statusName: "",
    } as CourseSessionAttendeeCollectionModel);
  }

  async onUpdateAttendeeList() {
    if (this.hasUserBeenAdded) {
      const confirmMessage = this.localiseString("courseSessionAttendees.updateAttendeeList") + "<br/></br>"
        + this.localiseString("courseSessionAttendees.confirmThisAction");
      this.$confirm(confirmMessage, this.localiseString("courseSessionAttendees.confirmTitle"), {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        dangerouslyUseHTMLString: true,
        type: "warning",
      })
      .then(() => {
        this.updateAttendeeList();
      });
    } else {
      this.updateAttendeeList();
    }
  }

  async updateAttendeeList() {
    const model = {
      courseSessionId: this.courseSessionId,
      attendeeUserids: _.map(this.attendeeEditList, "userId"),
      sendAttendeeInvite: this.sendAttendeeInvite,
    } as CourseSessionAttendeeUpdateList;

    this.isUpdatingAttendeeList = true;
    try {
      await CourseSessionService.updateAttendeeList(model);
    } catch (err) {
      this.$notify({
        title: this.localiseString("addCourseAttendees.attendeeUpdateError"),
        message: this.localiseString("addCourseAttendees.attendeeUpdateErrorDescription"),
        type: "error",
      });
    }

    await this.initializeModel();
    this.isUpdatingAttendeeList = false;
    this.isAddAttendeeDialogOpen = false;
    this.hasUserBeenAdded = false;
  }

  markAsAttended(selected: CourseSessionAttendeeCollectionModel[]) {
    this.selectedAttendeesState = selected;
  }

  async onUpdateStatus() {
    if (this.attendanceStatusId === CourseSessionAttendeeStatus.CertificateApproved) {
      const message = this.localiseString("addCourseAttendees.approveAreYouSure");
      this.$confirm(message, this.localiseString("addCourseAttendees.approveCertification"), {
          confirmButtonText: this.localiseString("common.ok"),
          cancelButtonText: this.localiseString("common.cancel"),
          type: "info",
        }).then(() => {
          this.updateStatus();
        });
    } else {
      this.updateStatus();
    }
  }

  async updateStatus() {
    const model = this.setAttendanceStatusModelFactory();

    try {
      await CourseSessionService.setAttendanceStatus(model);
      this.clearSelectedAttendees();
      this.initializeModel();
    } catch (err) {
      this.$notify({
        title: this.localiseString("addCourseAttendees.errorUpdatingStatusTitle"),
        message: this.localiseString("addCourseAttendees.errorUpdatingStatusMessage"),
        type: "error",
      });
    }
  }

  onOpenAddAttendeeDialog() {
    this.attendeeEditList = _.clone(this.attendees);
    this.isAddAttendeeDialogOpen = true;
  }

  onOpenRemoveAttendeeDialog() {
    this.attendeeEditList = _.clone(this.attendees);
    this.isRemoveAttendeeDialogOpen = true;
  }

  get allowEditAttendees(): boolean {
    return this.courseSession && this.courseSession.canAttendeesBeAdded;
  }

  get isAssessmentCourse(): boolean {
    const value = this.course
      && this.course.deliveryTypeId === this.assessmentDeliveryTypeId;

    return Boolean(value);
  }

  canSelectAttendee(row: CourseSessionAttendeeCollectionModel, index: any) {
    return row.isStatusUpdateAllowed;
  }

  get isUnionValidationError() {
    if (!this.addUserModel || !this.addUserModel.modelState) {
      return false;
    }

    const modelErrors = this.addUserModel.modelState.unionId;

    return _.filter(modelErrors, (item: any) => !!item).length > 0;
  }

  get unionValidationMessage() {
    if (!this.addUserModel || !this.addUserModel.modelState) {
      return "";
    }

    const messages = this.addUserModel.modelState.unionId;

    if (!messages || messages.length === 0) {
      return "";
    }

    return messages[0] || "";
  }

  public isCompetent(attendee: CourseSessionAttendeeCollectionModel) {
    return attendee.assessment.assessmentCriteriaStatusId === AssessmentCriteriaStatus.Competent
      || attendee.assessment.assessmentCriteriaStatusId === AssessmentCriteriaStatus.Excellent;
  }

  public isAssessmentPeriodExpired(attendee: CourseSessionAttendeeCollectionModel) {
    return attendee.statusId === CourseSessionAttendeeStatus.AssessmentPeriodExpired;
  }

  private async initializeModel() {
    if (!!this.courseSessionId) {
      this.sendAttendeeInvite = !this.courseSession.disableAttendeeInvite;
      this.attendees = await CourseSessionService.getAttendees(this.courseSessionId);
      this.attendeeEditList = _.clone(this.attendees);
      this.attendanceStatuses = await CourseSessionService.getAttendeeStatuses(this.courseSessionId);
      this.unions = await UnionService.getAsLookups();
      this.course = await CourseService.getByCourseSessionId(this.courseSessionId);

      this.transcriptLocales = await AssessmentTranscriptService
        .getTranscriptLocales(this.courseSessionId);
      this.selectDefaultLocale();

      this.$forceUpdate();
    }
  }

  private clearSelectedAttendees() {
    const attendeesTable = this.$refs.attendeesTable as any;
    if (attendeesTable) {
      attendeesTable.clearSelection();
    }
  }

  private setAttendanceStatusModelFactory(): CourseSessionAttendanceSetStatusModel {
    return {
      courseSessionId: this.courseSessionId,
      statusId: this.attendanceStatusId ? this.attendanceStatusId : 0,
      attendeeIds: _.map(this.selectedAttendeesState, (attendee) => {
        return attendee.courseSessionAttendeeId;
      }),
    };
  }

  private onOpenAssessmentDialog(courseAttendeeId: number) {
    this.courseAttendeeIdForAssessment = courseAttendeeId;
    this.isAddAssessmentDialogOpen = true;
  }

  private onConfirmAddAssessment() {
    this.isAddAssessmentDialogOpen = false;

    this.goToCourseSessionRoute({
      name: "addSessionAssessment",
      params: {
        id: this.courseSessionId,
        courseAttendeeId:  this.courseAttendeeIdForAssessment,
        locale: this.selectedAssessmentLanguage,
      },
    } as any);
  }

  private onEditAssessment(assessment: AssessmentModel) {
    if (!assessment) {
      return;
    }

    this.goToCourseSessionRoute({
      name: "editAssessment",
      params: {
        id: this.courseSessionId,
        assessmentId: assessment.assessmentId,
      },
    });
  }

  private onAwardLicense(userId: number) {
    this.$router.push({
      name: "licenseNew",
      params: {
        userId: userId.toString(),
      },
      query: {
        courseSessionId: this.courseSessionId.toString(),
      },
    });
  }

  private goToCourseSessionRoute(route: any) {
    if (this.isAdminView) {
      if (!route.query) {
        route.query = {};
      }

      route.query.adminView = "y";
    }

    this.$router.push(route);
  }

  private async onAddUser() {
    try {
      this.isAddingUser = true;
      const newUserId = await CourseSessionService.addUser(
        this.courseSessionId,
        this.addUserModel);

      const item = new AutoCompleteItem();
      item.id = newUserId;

      await this.onAttendeeSelected(item);

      this.addUserModel = new AddUserModel();
    } catch (e) {
      if (this.isValidationError(e)) {
        // show the validation error
        return;
      }

      // An error has occurred adding the user
      this.$notify.error({
        title: this.localiseString("common.error"),
        message: this.localiseString("courseSessionAttendees.errorAddingUserMessage"),
      });

    } finally {
      this.isAddingUser = false;
    }
  }

  private async onCheckAttendees() {
    try {
      this.isAddingMultipleAttendees = true;
      const response = await CourseSessionService.checkAttendees(
        this.courseSessionId,
        this.addMultipleAttendeesModel);

      // report on emails that are not existing users in Iris
      this.nonExistingAttendeeEmails = response.nonUserEmails;
      // Add each existing user to temporary attendee list
      response.prospectiveUsers.forEach((user: UserModel) => {
        this.addUserAsAttendee(user);
      });

      this.addMultipleAttendeesModel = new CheckMultiUserModel();
    } catch (e) {
      if (this.isValidationError(e)) {
        // show the validation error
        return;
      }

      // An error has occurred adding the user
      this.$notify.error({
        title: this.localiseString("common.error"),
        message: this.localiseString("courseSessionAttendees.errorAddingAttendees"),
      });

    } finally {
      this.isAddingMultipleAttendees = false;
    }
  }

  private getViewAssessmentActionText(assessment: AssessmentModel) {
    return assessment.publishStatusId === PublishStatus.Draft
      ? this.localiseString("common.actionEdit")
      : this.localiseString("common.view");
  }

  private selectDefaultLocale() {
    const defaultLocale = _.find(this.transcriptLocales, { id: this.courseSession.localeId});
    if (defaultLocale) {
      this.selectedAssessmentLanguage = defaultLocale.code;
    }
  }

  private getUserDetailsRoute(userId: number) {
    return {
      name: "userDetails",
      params: {
        id: userId,
      },
    };
  }
}
