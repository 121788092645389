export default {
  "common": {
    "name": "氏名",
    "hello": "こんにちは",
    "details": "内容",
    "created": "作成",
    "ok": "OK",
    "cancel": "取り消す",
    "save": "保存する",
    "unpublish": "",
    "id": "ID",
    "search": "検索する",
    "status": "ステータス",
    "view": "見る",
    "course": "コース名",
    "union": "協会",
    "noData": "データがありません",
    "startDate": "開始日",
    "startTime": "",
    "endDate": "終了日",
    "notes": "注記",
    "educators": "エデュケーター",
    "forUser": "ユーザー用",
    "regions": "リージョン",
    "unions": "協会",
    "existingRegions": "",
    "existingUnions": "",
    "homeUnion": "",
    "expiresOn": "（Date)に失効しました",
    "selectUser": "ユーザーを選択してください",
    "none": "該当なし",
    "level": "レベル",
    "actionAddAll": "全て追加する",
    "reason": "理由",
    "gettingData": "データを読み込み中",
    "success": "完了",
    "error": "エラー",
    "actionEdit": "変更する",
    "actionSubmit": "送信する",
    "logo": "ロゴ",
    "actionDropFile": "ここにファイルをドロップする、または",
    "imageFormatsAllowed1": "JPG/PNG/GIFファイル可",
    "forename": "名",
    "lastname": "姓",
    "emailAddress": "Eメールアドレス",
    "email": "E メール",
    "validationIsRequired": "必須項目",
    "code": "コード",
    "certified": "認定済み",
    "certExpiry": "認定証の有効期限",
    "session": "セッション",
    "yes": "はい",
    "no": "いいえ",
    "actionDownload": "ダウンロード",
    "close": "閉じる",
    "user": "ユーザー",
    "location": "",
    "from": "",
    "to": "",
    "type": "",
    "validationError": "",
    "validationErrorDescription": "",
    "errorSaving": "",
    "saving": ""
  },
  "navigation": {
    "courses": "コース",
    "myCourses": "マイコース",
    "courseAdmin": "コース管理者",
    "training": "トレーニング",
    "mySessions": "マイセッション",
    "sessionAdmin": "セッション管理者",
    "dashboard": "ダッシュボード",
    "navAbout": "本コースについて",
    "navAdministration": "管理者",
    "navUnions": "協会",
    "logout": "ログアウト",
    "myProfile": "マイプロフィール",
    "coursesAttended": "受講したコース",
    "licensesAwarded": "取得したライセンス",
    "certification": "",
    "fileshare": "",
    "correspondence": ""
  },
  "mySessions": {
    "viewMyTrainingSession": "自分のトレーニングセッションを見る",
    "hostTrainingSession": "トレーニングセッションを主催する",
    "selectCourse": "コースを選択してください",
    "selectUnion": "協会を選択してください",
    "selectLicense": "ライセンスを選択してください",
    "licenseInfo": "ライセンス情報",
    "validIn": "XXで有効",
    "language": "言語",
    "unionToAdmin": "協会から管理者へ"
  },
  "filters": {
    "filterByRegion": "リージョン別に見る",
    "filterByUnion": "協会別に見る",
    "filterByStatus": "ステータス別に見る",
    "filterByQualityStatus": "クオリティステータス別に見る",
    "filterByLevel": "レベル別に見る",
    "filterByStrand": "ストランド別に見る",
    "filterByExpiry": "有効期限別に見る",
    "filterByDeliveryType": "提供タイプ別に見る",
    "pleaseSelectLevel": "レベルを選択してください",
    "pleaseSelectStrand": "ストランドを選択してください",
    "pleaseSelectRegions": "リージョンを選択してください",
    "pleaseSelectUnions": "協会を選択してください",
    "pleaseSelectStatus": "ステータスを選択してください",
    "filterByLanguage": "",
    "filterByCourse": "",
    "filterByAttendeeRegion": "",
    "filterByAttendeeUnion": "",
    "filterByUserEmail": "",
    "filterByAssessmentStatus": ""
  },
  "sessionAdmin": {
    "viewScheduledTrainingSession": "トレーニングセッションの日程を見る",
    "scheduleTrainingSession": "トレーニングセッションの日程を組む",
    "addTrainingSession": "トレーニングセッションを追加する"
  },
  "editTrainingSession": {
    "editTrainingSession": "トレーニングセッションを変更する",
    "scheduledTraining": "予定されているトレーニングセッション",
    "certificateLanguage": "認定証の言語",
    "venueName": "会場名",
    "venueAddress": "会場の住所",
    "organiser": "運営者",
    "selectAnEducator": "エデュケーターを選択する",
    "addEducator": "エデュケーターを追加する",
    "addNoteToHistory": "履歴に注記を加える",
    "approve": "承認",
    "saveTrainingSession": "トレーニングセッションを保存する",
    "viewTrainingSessionHistory": "トレーニングセッションの履歴を見る",
    "noHistory": "表示する履歴がありません",
    "addEducatorWarning": "このエデュケーターはこのセッションを支援することはできますが、指導することはできません。各セッションには少なくともリードエデュケーターが一人必要です。",
    "assistingEducators": "アシスタントエデュケーター",
    "canLead": "リードすることができますか？",
    "selectLanguage": "言語を選択してください",
    "scheduleCourse": "コースを予約する",
    "saveCourse": "コースを保存する",
    "addCourse": "コースを追加する",
    "emailLanguageTooltip": "受講者へのEメールはこの言語で書きます",
    "startTimeLocalTime": ""
  },
  "addNote": {
    "addNote": "注記を加える",
    "noteDetails": "注記の内容"
  },
  "courseSessionStatus": {
    "awaitingApproval": "承認待ち",
    "rejected": "却下",
    "approved": "承認"
  },
  "tabs": {
    "tabattendees": "受講者",
    "tabhistory": "履歴",
    "tabCourseMaterials": "コースの教材"
  },
  "addCourseAttendees": {
    "titleViewTrainingSessionAttendees": "トレーニングセッションの受講者を見る",
    "editAttendeeList": "受講者リストを変更する",
    "attendeeName": "氏名",
    "assessmentStatus": "評価状況",
    "actions": "アクション",
    "editAttendees": "受講者を変更する",
    "actionConfirm": "確認する",
    "noUsersFound": "ユーザーが見つかりません",
    "attendeeUpdateError": "受講者の更新エラー",
    "attendeeUpdateErrorDescription": "受講者リストの更新中にエラーが発生しました。現地のセッション用のメールテンプレートが設定されていますか？",
    "optionSelectStatus": "ステータスを選択する",
    "actionUpdateStatus": "ステータスを更新する",
    "actionAdd": "追加する",
    "titleAddAssessment": "評価を追加する",
    "selectLanguage": "言語を選択する",
    "actionLicense": "ライセンス",
    "actionLicenseDescription": "この受講者にライセンスを付与してください",
    "actionEdit": "変更する",
    "unmetPrerequisites": "受講資格を満たしていない",
    "approveAreYouSure": "お選びの受講者の認定証を承認しますか？",
    "approveCertification": "認定証を承認する",
    "errorUpdatingStatusTitle": "ステータスの更新にエラーが発生しました",
    "errorUpdatingStatusMessage": "受講者のステータスを更新中にエラーが発生しました",
    "sendAttendeeInvites": "",
    "sendAttendeeInvitesToolTip": ""
  },
  "editAssessment": {
    "editAssessment": "評価を変更する",
    "competency": "能力",
    "notYetCompetent": "まだ能力が不十分",
    "notCompetent": "",
    "competent": "十分な能力",
    "excellent": "優秀",
    "criteria": "基準",
    "commentHelpText": "コメント - 「まだ能力が不十分」の場合は必須項目。",
    "actionSaveAndPublish": "保存＆発行",
    "assessorComment": "評価者のコメント",
    "publishedBy": "発行者",
    "lastUpdatedBy": "最新の更新者",
    "addedBy": "追加を行なった者",
    "errorSavingAssessment": "評価の保存中にエラーが発生しました",
    "errorSavingAssessmentMessage": "入力情報がすべて有効であるか確認する",
    "confirmCancelAssessment": "取り消してもよろしいですか？",
    "leaveAssessment": "評価のページから移動しますか？",
    "confirmPublishAssessment": "この評価を公開してもよろしいですか？一度公開すると、評価を変更することはできません",
    "publishAssessment": "評価を公開しますか？",
    "viewAssessment": "評価を見る",
    "attendeeSelfReflection": "受講者の自己省察",
    "postCourseProgress": "",
    "postLiveExperienceProgress": "",
    "attendeeNotCompetentHeading": "",
    "attendeeMayBeCompetent": "",
    "attendeeNotCompetent": "",
    "attendeeNotCompetentConfirmation": "",
    "unpublishAssessmentConfirmation": ""
  },
  "feedback": {
    "viewFeedback": "フィードバックを見る"
  },
  "activeCerts": {
    "activeCertificateStatistics": "アクティブ・サーティフィケート・スタティスティックス",
    "date1": "日付１",
    "date2": "日付２",
    "activeCerts": "アクティブ・サーティフィケート",
    "pickDate": "日付を選ぶ",
    "total": "合計"
  },
  "issuedCerts": {
    "issuedCertificateStatistics": "発行済みのサーティフィケート・スタティスティックス",
    "filterByDeliveryType": "提供形態タイプで絞る",
    "range1": "範囲１",
    "range2": "範囲２",
    "dateTo": "まで",
    "regionUserFilters": "リージョン/ユーザー別に見る",
    "regionUserFiltersDescription1": "リージョンと協会のフィルターは、コース提供タイプによって適用が異なります。",
    "regionUserFiltersDescription2": "オンライン＆スコーム：統計は受講者に登録されている協会ごとに分類されます。",
    "regionUserFiltersDescription3": "対面式：トレーニングが行われた協会別に集計されます。",
    "courseCode": "コースコード",
    "courseName": "コース名",
    "issuedCerts1": "発行済み認定証１",
    "issuedCerts2": "発行済み認定証２",
    "percentageIncrease": "％増",
    "range1Start": "範囲１開始",
    "range1End": "範囲１終了",
    "range2Start": "範囲２開始",
    "range2End": "範囲２終了"
  },
  "strands": {
    "strandRugbyReady": "Rugby Ready (ラグビーレディ)",
    "strandLaws": "競技規則",
    "strandStrengthAndConditioning": "ストレングス＆コンディショニング",
    "strandPlayerWelfare": "プレーヤーウェルフェア",
    "strandKeepRugbyClean": "キープ・ラグビー・クリーン",
    "strandCoaching": "コーチング",
    "strandOfficiating": "オフィシエーティング",
    "strandIntegrity": "品位",
    "strandMatchDayStaff": "マッチデースタッフ",
    "strand": "ストランド"
  },
  "licenseStatus": {
    "licenseActive": "アクティブ",
    "licenseRevoked": "無効となった",
    "licenseExpired": "失効した",
    "licenseProvisional": "一時的なライセンス"
  },
  "licenses": {
    "licenses": "ライセンス",
    "grantEducatorLicense": "エデュケーターに免許を付与する",
    "actionRevoke": "取り消す",
    "grantALicense": "ライセンスを付与する",
    "grantLicense": "ライセンスを付与する",
    "certificatesAwarded": "認定証が付与された",
    "actionGrantALicense": "ライセンスを付与する",
    "licenseRevoke": "ライセンスを取り消す",
    "revokeLicense": "無効となったライセンス",
    "noRevokeEmailHeader": "無効になったことを通知する自動Eメールはありません。",
    "noRevokeEmailBody": "免許が無効になったとき、自動的にメールは送信されません。管理者は、このアクションを個人に対して行うことができます。",
    "viewEligibleCourses": "受講できるコースを見る",
    "eligibleCourses": "受講可能なコース",
    "messageLicenceAwardDelay": "管理者が受講者に認定証を付与してから、認定証が受講者に発行されるまでには時間がかかります。下の表には付与された認定証のみが表示されるため、最近付与された認定証が下の表に表示されない可能性があります。",
    "messageEducatorCanTeach": "提示されたライセンスをもつエデュケーターは次のコースを教えることができます。",
    "licenseAwardedTo": "xxにライセンスを付与した",
    "errorAddingLicense": "このライセンスを追加中にエラーが発生しました",
    "gettingLicenseInformation": "ライセンスに関する情報を読み込み中",
    "noLicenses": "あなたには現在、有効なライセンスがありません",
    "licenseHistory": "ライセンス履歴",
    "qualityStatus": "クオリティのステータス",
    "delayIssuingCertificate": "注：認定証の発行には時間がかかります",
    "delayIssuingCertificateInfo": "管理者が参加者に証明書を授与してから、受講者に認定証が発行されるまでには時間がかかります。以下の表には付与された認定証のみが表示されるため、最近付与された認定証が下の表に表示されない可能性があります。",
    "noActiveLicenses": "有効なライセンスがない",
    "addLicenseError": "このライセンスの追加中にエラーが発生",
    "myLicenses": "",
    "emailWorkforce": "Email workforce",
    "licenseAmended": "",
    "licenseAmendedConfirmationHeading": "",
    "licenseAmendConfirmation": "",
    "expireLicense": "",
    "amendLicense": ""
  },
  "regionAdmin": {
    "rtmUser": "RTMユーザー",
    "saveRegion": "リージョンを保存する"
  },
  "unionAdmin": {
    "unionTrainingEducationAdministrator": "協会のT＆E管理者",
    "canDeliverCoursesForWr": "ワールドラグビーに代わってコースを提供することができます。",
    "saveUnion": "協会を保存する",
    "gettingUnion": "協会を読み込み中",
    "unionSaved": "協会が保存されました。"
  },
  "certStatus": {
    "certStatusInProgress": "進行中",
    "certStatusValid": "有効",
    "certStatusInExpired": "有効期限切れ",
    "certStatusAll": "すべて"
  },
  "userAdmin": {
    "userList": "ユーザーリスト",
    "inviteUser": "ユーザーを招待する。",
    "infoNoEmailInviteHeader": "招待メールは送信されません",
    "infoNoEmailInviteBody1": "この機能はIrisのバックエンドロールと一緒にデータベースにユーザーを追加します。",
    "infoNoEmailInviteBody2": "この機能の一部としてユーザーに招待メールは送信されないため、追加されたことを通知する責任はiris管理者にあります。",
    "infoNoEmailInviteBody3": "ユーザーがまだ登録していない場合は、ここで提供されたEメールアドレスを使用してWR SSOに登録してください。",
    "actionAddUserAndAssignBackendRole": "ユーザーの追加とバックエンドロールの割り当て",
    "irisUserAddedHeader": "Irisバックエンドユーザーが追加されました",
    "irisUserAddedBody": "Irisバックエンドユーザーとして追加されました",
    "errorAddingUserHeader": "バックエンドユーザーを追加する際にエラーが発生しました",
    "errorAddingUserBody": "バックエンドユーザーを追加する際にエラーが発生しました",
    "isExactEmail": "正しいEメールアドレス",
    "userBaseId": "ユーザーベースID",
    "lastLoginDate": "前回ログインした日付",
    "coursesAndCertificates": "コース＆認定証",
    "roles": "役割",
    "currentValidCertificates": "現在有効な認定証",
    "userLicenses": "ユーザーライセンス",
    "userNeverGrantedLicense": "このユーザーはライセンスを取得したことがありません。",
    "infoRegionManagerheader": "リージョナルトレーニングマネジャーの役割",
    "infoRegionManagerDetail1": "リージョナルトレーニングマネジャーの役割が付与されるフォームは",
    "infoRegionManagerDetail2": "リージョン管理フォーム",
    "infoUnionManagerheader": "協会トレーニングマネジャーの役割",
    "infoUnionManagerDetail1": "協会トレーニングマネジャーの役割が付与されるフォームは",
    "infoUnionManagerDetail2": "協会管理フォーム",
    "userRoles": "ユーザーの役割",
    "infoRolesUpdatedHeader": "役割が更新されました",
    "infoRolesUpdatedBody": "ユーザーロールの更新が完了しました",
    "userExactEmail": "正確なEメールアドレスを使用してください",
    "select": "選択する",
    "noUsers": "ユーザーなし",
    "searchAttendee": "追加する受講者を検索する"
  },
  "reports": {
    "reports": "レポート",
    "report": "報告する",
    "deliveryType": "コース実施タイプ",
    "actionGetReport": "レポートを取得する",
    "regions": "リージョン",
    "unions": "協会"
  },
  "qualityAudit": {
    "manage": "管理する",
    "qualityAssurance": "品質保証",
    "qualityAudits": "品質監査",
    "date": "日付",
    "addQualityAudit": "品質監査の追加",
    "noAuditsAdded": "本ライセンスに追加された監査はありません",
    "educatorCanTeach": "指定のライセンスを持つエデュケーターは、以下のコースを教えることができます。",
    "auditor": "監査者",
    "published": "発行済み",
    "actionPublish": "発行する",
    "audits": "監査",
    "assignAuditor": "監査者を任命する",
    "myAssignedQas": "任命されたマイQA",
    "audit": "監査",
    "auditBy": "監査者",
    "publishedBy": "本監査の発行者",
    "onDate": "発行日",
    "venue": "場所",
    "detailsPlaceholder": "",
    "areasOfStrength": "長所",
    "areasOfStrengthPlaceholder": "ここに長所を追加",
    "areasForDevelopment": "開発が必要な分野",
    "areasForDevelopmentPlaceholder": "開発が必要な分野をここに追加",
    "agreedActionPlan": "合意したアクションプラン",
    "agreedActionPlanPlaceholder": "合意したアクションプランをここに追加",
    "educatorSelfReflection": "エデュケーターの自己省察",
    "educatorSelfReflectionPlaceholder": "エデュケーターの自己省察をここに追加",
    "grade": "段階評価",
    "gradePlaceholder": "段階評価を選択してください",
    "behaviour": "行動",
    "behaviourPlaceholder": "行動を選択してください",
    "saveAsDraft": "下書きとして保存",
    "auditsOnEducator": "エデュケーターの監査",
    "auditsByEducator": "エデュケーターによる監査",
    "educator": "エデュケーター",
    "addEducatorNote": "エデュケーターの注記を追加",
    "educatorNoteTooltip": "エデュケーターの注記は、エデュケーターとその管理者がコース履歴タブから閲覧することができます",
    "qaSubmitSuccess": "品質監査が送信されました",
    "qaPublishedSuccess": "品質監査が公開されました",
    "qaConfirmPublish": "この品質監査を公開してもよろしいですか？公開された監査は、読み取り専用となります。",
    "qaConfirmPublishTitle": "監査を公開しますか？",
    "qaLoadingText": "品質監査の情報を読み込み中",
    "manageAudits": "監査を管理する",
    "myActiveLicences": "マイアクティブライセンス",
    "editQualityAudit": "品質監査を変更する",
    "submitForm": "フォームを送信する",
    "auditPublished": "監査が公開されました",
    "user": "ユーザー",
    "licenseHolder": "ライセンスの取得者",
    "licenseStatus": "",
    "publishStatus": "",
    "actionSubmitEducatorFeedback": "",
    "actionSubmitForEducatorFeedback": ""
  },
  "qualityAuditStatus": {
    "Draft": "",
    "AwaitingEducatorFeedback": "",
    "AwaitingAuditeeFeedback": "",
    "AwaitingPublish": "",
    "Published": ""
  },
  "termsAndConds": {
    "heading": "ワールドラグビー利用規約に同意する",
    "main": "Irisをご利用いただく前に、以下の利用規約に同意いただく必要があります。",
    "educatorCodeOfConductHeading": "エデュケーターの行動規範",
    "educatorLinkDescription": "エデュケーターの行動規範はここからご覧いただけます",
    "educatorAcceptCodeOfConduct": "エデュケーターの行動規範に同意する"
  },
  "courseSessionAttendees": {
    "removeAttendees": "受講者を削除する",
    "addAttendees": "受講者を追加する",
    "addAttendeesBy": "X別で受講者を追加する",
    "draft": "下書き",
    "assessment": "評価",
    "noAttendees": "受講者がいません",
    "noAttendeesSubTitle": "本コースに追加された受講者はいません。下のボタンをクリックして受講者を追加してください。",
    "bulkAddAttendees": "メールアドレスから受講者を一括追加する",
    "findExisting": "既存のユーザーを探す",
    "registeringNew": "新規ユーザーを登録する",
    "bulkAddByEmailA": "このコースに複数の受講者を追加します。下のフィールドに参加者のメールアドレスを1行に1つずつ入力し、「参加者を追加する」ボタンをクリックしてください。",
    "bulkAddByEmailB": "コースに追加されるのは、既存のIrisユーザーのみです。追加できなかった受講者のメールアドレスのリストがあなたに送られます。",
    "addAttendeeLabel": "受講者のEメールアドレス（一行に一つずつ）",
    "addressesDoNotExist": "受講者のメールアドレスはIrisにありません",
    "emailsAreRegistered": "次のメールアドレスはユーザーとして登録されています",
    "registerNewUser": "新規ユーザーとして登録し、本コースの受講者として追加してください。そのユーザーがまだIrisに追加されていない場合にのみ、この操作を行ってください。",
    "firstName": "名",
    "surname": "姓",
    "noEmailWarning": "受講者がメールアドレスを持っていない場合、Eメールでの通知はコースを運営するエデュケーターに送られます。",
    "registerNewUserAsAttendee": "新規ユーザーを受講者として登録する",
    "draftAttendeeList": "あなたの受講者リストの下書き",
    "updateAttendeeList": "受講者リストを更新してもよろしいですか？",
    "confirmThisAction": "このアクションを確認することで、新たに追加された受講者全員に、本コースに関する紹介メールが送信されます。",
    "confirmTitle": "受講者リストを更新しますか？",
    "errorAddingUserMessage": "ユーザーの追加中にエラーが発生しました",
    "errorAddingAttendees": "受講者の追加中にエラーが発生しました"
  },
  "courseSessionHost": {
    "attendeeNotes": "受講者に関する注記",
    "attendeeNotesTooltip": "これらの注記は新規受講者への招待メールに表示されます（メールテンプレートで設定した場合）。選択した言語で記述してください。",
    "addCourse": "コースを追加する",
    "noEducatorLicensesDescription": "あなたは有効なライセンスを持っていません。コースを行うには、有効なエデュケーターライセンスが必要です。エデュケーターライセンスを取得するには、リージョンのトレーニングマネージャーに連絡してください。",
    "locationTooltip": ""
  },
  "courseSessionRouteTags": {
    "viewMyCourses": "マイコースを見る",
    "viewScheduledCourses": "予定されているコースを見る",
    "hostCourse": "コースを主催する",
    "scheduleCourse": "コースを予定する",
    "editCourse": "コースを変更する",
    "viewCourse": "コースを見る",
    "viewAttendees": "受講者を見る",
    "viewCourseHistory": "コースの履歴を見る"
  },
  "routeTags": {
    "acceptEducatorTerms": "エデュケーターの行動規範に同意する"
  },
  "profileCourses": {
    "started": "開始しました",
    "noCoursesAttended": "受講済みのコースがありません",
    "notAttendedAnyCourses": "あなたはいずれのコースも受講していません",
    "validCertLookupAll": "全て",
    "validCertLookupCurrent": "現在有効な認定証",
    "validCertLookupExpired": "失効した",
    "validCertLookupInProgress": "進行中"
  },
  "profileLicenses": {
    "noLicenses": "ライセンスがありません",
    "noLicensesMatch": "条件に一致するライセンスがありません",
    "expiry": "有効期限"
  },
  "attendeeEmailInvite": {
    "showEmailPreview": "招待メールのプレビューを見る",
    "attendeeEmailPreview": "受講者へのメールのプレビュー",
    "subject": "件名",
    "htmlContent": "HTMLコンテンツ",
    "textContent": "テキストの内容"
  },
  "myDashboard": {
    "myActions": "マイアクション",
    "recentCourseRequringApproval": "承認が必要な最近のコース",
    "attendeesAwaitingCertApproval": "認定の承認を待っている受講者",
    "underMyAdmin": "",
    "recentCourses": "最近のコース",
    "upcomingCourses": "今後のコース",
    "auditsInProgress": "進行中の監査",
    "myRecentCourses": "自分の最近のコース",
    "awaitingApproval": "承認待ち",
    "myUpcomingCourses": "今後予定されているマイコース",
    "myQualityAudits": "自分の品質監査",
    "myAuditsInProgress": "進行中の自分の品質監査",
    "myUpcomingAudits": "今後予定されている自分の品質監査",
    "recentUpcomingCourses": "最近・今後予定されているコース",
    "noCoursesSubtitle": "あなたには最近のコース、また今後予定されているコースがありません。下のボタンをクリックしてコースを開催してください",
    "activeAudits": "有効な監査",
    "noAuditsSubtitle": "最近の監査、また今後行われる予定の監査はありません",
    "auditsRequiringMyFeedback": "",
    "assessmentPeriodDueToExpire": "",
    "assessmentsExpireInPastYear": "",
    "correspondence": "Correspondence"
  },
  "fileshare": {
    "courses": "",
    "fileshareCourseMaterials": ""
  },
  "correspondence": {
    "decline": "",
    "validateCorrespondence": "",
    "id": "",
    "emailScheduleType": "",
    "status": "",
    "targetedWorkforce": "Targeted workforce",
    "viewUsers": "",
    "hideUsers": "",
    "attachments": "",
    "approve": "",
    "validate": ""
  },
  "educatorsEmail": {
    "numberOfUser": "",
    "noUsersFound": "",
    "workforce": "Workforce",
    "filters": "Filters"
  },
  "compliance": {
    "compliance": "",
    "complianceCheckEdit": "",
    "complianceCheckAdd": "",
    "compliantOnDate": "",
    "complianceCheckSuccessfullySaved": "",
    "requiredCertificates": "",
    "usersToCheck": "",
    "confirmRemoveRequiredCertificate": "",
    "confirmRemoveUser": "",
    "errorAddingUsers": "",
    "noCertificates": ""
  },
  "educatorList": {
    "numberOfCoursesDelivered": "",
    "numberOfUnionsDeliveredIn": ""
  }
};
