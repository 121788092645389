
































































































import { Component, Prop, Watch } from "vue-property-decorator";

import AppVue from "@/apps/AppVue.vue";
import {
} from "@/components";
import {
  AssessmentTranscriptCollectionModel,
} from "@/core/models";
import {
  AssessmentTranscriptService,
  DefaultLocale,
} from "@/core/services";

@Component({
  components: {
  },
})
export default class CourseTranscripts extends AppVue {
  @Prop() courseId: number;
  defaultLocale = DefaultLocale;
  model: AssessmentTranscriptCollectionModel = {
    published: undefined,
    draft: undefined,
    archived: undefined,
  } as any;

  get published() {
    return this.model.published ? [this.model.published] : [];
  }

  get draft() {
    return this.model.draft ? [this.model.draft] : [];
  }

  get archived() {
    return this.model.archived || [];
  }

  async created() {
    await this.bindData();
  }

  @Watch("$route", { deep: true })
  async bindData() {
    const loading = this.$loading({
      lock: true,
      text: "Getting data...",
    });

    this.model = await AssessmentTranscriptService.getForCourse(this.courseId);

    loading.close();
  }

  async onEdit(transcriptId: number) {
    const response = await AssessmentTranscriptService.createDraft(transcriptId);
    const newTranscriptId = response.data as number;
    this.redirectToEditTranscript(newTranscriptId);
  }

  redirectToEditTranscript(transcriptId: number) {
    this.$router.push({
      name: "courseTranscriptsEdit",
      params: {
        id: this.courseId as any,
        transcriptId: transcriptId as any,
        locale: DefaultLocale as any,
      },
    });
  }

  onPublish(transcriptId: number) {
    this.$confirm("Are you sure that you want to publish this draft?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
    })
    .then(async () => {
      await AssessmentTranscriptService.publish(transcriptId);
      await this.bindData();

      this.$forceUpdate();
    });
  }

  onDelete(transcriptId: number) {
    this.$confirm("Are you sure that you want to delete this draft?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
    })
    .then(async () => {
      await AssessmentTranscriptService.deleteDraft(transcriptId);
      await this.bindData();

      this.$forceUpdate();
    });
  }
}
