














import { Vue, Component, Prop } from "vue-property-decorator";

import { GridModel, SimpleLookupModel } from "@/core/models";

@Component
export default class GridSelectFilter extends Vue {
  @Prop() grid: GridModel<any>;
  @Prop() prop: string;
  @Prop() lookups: SimpleLookupModel[];
  @Prop({ default: "FILTER PLACEHOLDER" }) placeholder: string;
  @Prop({ default: false }) filterable: boolean;

  onChange(value: any) {
    this.$forceUpdate();

    if (!this.grid.preventAutoSubmit) {
      this.grid.onFilter();
    }
  }
}
