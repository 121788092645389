import { Vue } from "vue-property-decorator";

export const LoggingService = new Vue({
  methods: {
    log(...data: any[]) {
      this.info(data); // tslint:disable-line
    },
    debug(...data: any[]) {
      console.debug(data); // tslint:disable-line
    },
    info(...data: any[]) {
      console.info(data); // tslint:disable-line
    },
    warn(...data: any[]) {
      console.warn(data); // tslint:disable-line
    },
    error(...data: any[]) {
      console.error(data); // tslint:disable-line
    },
  },
});