



























import { CourseModel } from "@/core/models";
import { CourseNavigation } from ".";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    CourseNavigation,
  },
})
export default class CourseMenu extends Vue {
  course = {} as CourseModel;

  get routeName() {
    return this.$route.meta.title;
  }

  get courseListRoute() {
    return {
      name: "courseList",
    };
  }

  created() {
    this.$root.$on("COURSE_UPDATED", (course: CourseModel) => {
      this.course = course;
    });
  }
}
