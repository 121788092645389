const courseSessionScheduleForm = () =>
  import(/* webpackChunkName: "course-session" */ "@/views/training/CourseSessionScheduleForm.vue");
const courseSessionHostForm = () =>
  import(/* webpackChunkName: "course-session" */ "@/views/training/CourseSessionHostForm.vue");
const courseSessionAssessmentFormView = () =>
  import(/* webpackChunkName: "course-session" */ "@/views/training/CourseSessionAssessmentFormView.vue");

import { RoleName } from "@/core/constants";

export const courseSessionsRoutes = [
  {
    path: "/my-course-sessions/list",
    name: "myCourseSessionList",
    meta: {
      title: "View my courses",
      tag: "courseSessionRouteTags.viewMyCourses",
      localise: true,
    },
    component: () =>
      import(/* webpackChunkName: "course-session" */ "@/views/training/MyCourseSessionList.vue"),
  },
  {
    path: "/scheduled-course-sessions/list",
    name: "scheduledCourseSessionList",
    meta: {
      title: "View scheduled courses",
      tag: "courseSessionRouteTags.viewScheduledCourses",
      localise: true,
    },
    component: () =>
      import(/* webpackChunkName: "course-session" */ "@/views/training/ScheduledCourseSessionList.vue"),
  },
  {
    path: "/course-sessions/host",
    name: "courseSessionHost",
    meta: {
      title: "Host a course",
      tag: "courseSessionRouteTags.hostCourse",
      localise: true },
    component: courseSessionHostForm,
  },
  {
    path: "/course-sessions/:id/view",
    name: "courseSessionView",
    meta: {
      title: "View course",
      tag: "courseSessionRouteTags.viewCourse",
      localise: true,
    },
    component: courseSessionHostForm,
  },
  {
    path: "/course-sessions/schedule",
    name: "courseSessionSchedule",
    meta: {
      title: "Schedule course",
      tag: "courseSessionRouteTags.scheduleCourse",
      localise: true,
    },
    component: courseSessionScheduleForm,
  },
  {
    path: "/course-sessions/:id/edit",
    name: "courseSessionEdit",
    meta: {
      title: "Edit course",
      tag: "courseSessionRouteTags.editCourse",
      localise: true,
    },
    component: courseSessionScheduleForm,
  },
  {
    path: "/course-sessions/:id/view/attendees",
    name: "courseSessionAttendees",
    meta: {
      title: "View attendees",
      tag: "courseSessionRouteTags.viewAttendees",
      localise: true,
    },
    component: () =>
      import(/* webpackChunkName: "course-session" */ "@/views/training/CourseSessionAttendeesView.vue"),
  },
  {
    path: "/course-sessions/:id/view/history",
    name: "courseSessionHistory",
    meta: {
      title: "View course history",
      tag: "courseSessionRouteTags.viewCourseHistory",
      localise: true,
    },
    component: () =>
      import(/* webpackChunkName: "course-session" */ "@/views/training/CourseSessionHistoryView.vue"),
  },
  {
    path: "/course-sessions/:id/view/materials/:locale?",
    name: "courseSessionMaterials",
    meta: { title: "View training materials" },
    component: () =>
      import(/* webpackChunkName: "course-session" */ "@/views/training/CourseSessionMaterialsView.vue"),
  },
  {
    path: "/course-feedback",
    name: "courseFeedbackView",
    meta: {
      title: "View feedback",
      tag: "feedback.viewFeedback",
      localise: true,
    },
    component: () =>
      import(/* webpackChunkName: "course-session" */ "@/views/training/CourseFeedbackView.vue"),
  },
  {
    path: "/active-certificate-statistics",
    name: "activeCertificateStatisticsList",
    meta: {
      title: "Active certificate statistics",
      tag: "activeCerts.activeCertificateStatistics",
      localise: true,
    },
    component: () =>
      import(/* webpackChunkName: "course-session" */ "@/views/training/ActiveCertificateStatisticsList.vue"),
  },
  {
    path: "/issued-certificate-statistics",
    name: "issuedCertificateStatisticsList",
    meta: {
      title: "Issued certificate statistics",
      tag: "issuedCerts.issuedCertificateStatistics",
      localise: true,
    },
    component: () =>
      import(/* webpackChunkName: "course-session" */ "@/views/training/IssuedCertificateStatisticsList.vue"),
  },
  {
    path: "/reports/:id?",
    name: "reports",
    meta: {
      title: "Reports",
      tag: "reports.reports",
      localise: true,
      roles: `${RoleName.IrisAdministrator},${RoleName.GlobalTrainingManager},${RoleName.RegionalTrainingManager},${RoleName.UnionTrainingManager},${RoleName.UnionAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "course-session" */ "@/views/training/ReportsView.vue"),
  },
  {
    path: "/course-sessions/:id/assessments/add/:courseAttendeeId/:locale",
    name: "addSessionAssessment",
    meta: { title: "Add assessment" },
    component: courseSessionAssessmentFormView,
  },
  {
    path: "/course-sessions/:id/assessments/edit/:assessmentId",
    name: "editAssessment",
    meta: { title: "Edit assessment" },
    component: courseSessionAssessmentFormView,
  },
];
