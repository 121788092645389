export const fileshareCourseListRouteName = "fileshareCourseList";
export const fileshareCourseDetailsRouteName = "fileshareCourseMaterials";

export const fileshareRoutes = [
  {
    path: "/fileshare/courses",
    name: fileshareCourseListRouteName,
    meta: {
      title: "Fileshare",
      tag: "fileshare.courses",
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/fileshare/fileshareCourseList.vue"),
  },
  {
    path: "/fileshare/courses/:id/details/:locale?",
    name: fileshareCourseDetailsRouteName,
    meta: {
      title: "Fileshare course Materials",
      tag: "fileshare.fileshareCourseMaterials",
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "@/views/fileshare/fileshareCourseMaterials.vue"),
  },
];
