





























import { Component, Watch } from "vue-property-decorator";

import AppVue from "@/apps/AppVue.vue";
import {
  SimpleAuditRecordCollectionModel,
} from "@/core/models";
import {
  CourseSessionService,
} from "@/core/services";
import {
  AuditRecordType,
} from "@/core/constants";
import AddAuditRecord from "@/components/audit-record/AddAuditRecord.vue";

@Component({
  components: {
    AddAuditRecord,
  },
})
export default class CourseSessionHistory extends AppVue {
  history: SimpleAuditRecordCollectionModel[] = [];
  auditRecordTypeId: number = AuditRecordType.CourseSession;

  get courseSessionId() {
    return this.$route.params.id;
  }

  async created() {
    await this.bindHistory();
  }

  @Watch("courseSessionId", { deep: true })
  async bindHistory() {
    const loading = this.$loading({
      lock: true,
      text: this.localiseString("common.gettingData"),
    });

    try {
      await this.initializeModel();
    } catch (err) {
      this.handleApiGetError(err);
    } finally {
      loading.close();
    }
  }

  private async initializeModel() {
    const courseSessionId = this.$route.params.id as any;

    if (courseSessionId) {
      const history = await CourseSessionService.getCourseSessionHistory(courseSessionId);
      this.history = history;
    }
  }
}
