










import { Vue, Component, Prop } from "vue-property-decorator";

import { GridModel } from "@/core/models";

@Component
export default class GridDateFilter extends Vue {
  @Prop() grid: GridModel<any>;
  @Prop() prop: string;
  @Prop() placeholder: string;
  state = "";

  created() {
    this.state = (this.grid.filter as any)[this.prop];
  }

  onChange(value: any) {
    (this.grid.filter as any)[this.prop] = value;
    this.$emit("change", value);
    this.$forceUpdate();
    this.grid.onFilter();
  }
}
