



























import { Component, Prop } from "vue-property-decorator";

import AppVue from "@/apps/AppVue.vue";
import {
  UserModel,
} from "@/core/models";

@Component({name: "UserNavigation"})
export default class UserNavigation extends AppVue {

  get userId() {
    return +this.$route.params.id;
  }

  get showNavigation() {
    return this.userId !== undefined;
  }

  getUserDetailsRoute() {
    return this.getRoute("userDetails");
  }

  getUserCoursesRoute() {
    return this.getRoute("userCourses");
  }

  getUserCoursesDeliveredRoute() {
    return this.getRoute("userCoursesDelivered");
  }

  getUserLicensesRoute() {
    return this.getRoute("userLicenses");
  }

  getUserRolesRoute() {
    return this.getRoute("userRoles");
  }

  getRoute(name: string) {
    return {
      name,
      params: {
        id: this.$route.params.id,
      },
    };
  }

  getActiveindex() {
    return this.$route.name;
  }
}
