







































import { Component, Vue } from "vue-property-decorator";
import moment from "moment";

import {
  LicenseCollectionModel,
  PagedCollectionFilter,
  LicensePagedCollectionFilter,
} from "@/core/models";
import {
  BaseGridComponent,
} from "@/components/common";
import { LicenseService, QualityAuditService } from "@/core/services";

@Component({
  components: {
  },
})
export default class MyLicenses extends BaseGridComponent<LicenseCollectionModel> {
  async created() {
    this.initialize(
      this.filterLicenses,
      "home",
    );
  }

  async filterLicenses(filter: PagedCollectionFilter) {
    const licenseFilter = new LicensePagedCollectionFilter();
    licenseFilter.forCurrentUser = true;
    licenseFilter.statusId = 1;

    return await LicenseService.filterLicenses(licenseFilter);
  }

  formatTime(time: any) {
    return moment(time).format("YYYY-MM-DD");
  }

  getQualityStatusIcon(qualityStatus: any) {
    return QualityAuditService.getQualityStatusIcon(qualityStatus);
  }

}
