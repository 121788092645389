import { HttpService } from "./common/http.service";
import {
  AuditRecordNoteModel,
} from "@/core/models";

export const AuditRecordService = {
  addNote(model: AuditRecordNoteModel) {
    return HttpService.postModel("/auditrecords/note", model);
  },
};
