

































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { EmailScheduleDetailsModel } from "@/core/models";
import { EmailScheduleService } from "@/core/services";
import { EmailScheduleStatus } from "@/core/constants";
import { InputField, InputRichText } from "@/components";
import LicenseFilterTable from "./LicenseFilterTable.vue";

import AppVue from "@/apps/AppVue.vue";

@Component({
  components: {
    InputField,
    InputRichText,
    LicenseFilterTable,
  },
})
export default class ValidateCorrespondence extends AppVue {
  @Prop({ default: false }) showDialog: boolean;
  @Prop() emailScheduleId: number;

  @Watch("emailScheduleId", { deep: true })
  async emailScheduleIdUpdated() {
    await this.loadModel();
  }

  model = new EmailScheduleDetailsModel();
  loading = false;
  showUsers = false;

  async created() {
    await this.loadModel();
  }

  get emailScheduleStatus() {
    return EmailScheduleStatus;
  }

  get isAwaitingApproval() {
    return (
      this.model.emailScheduleStatusId === EmailScheduleStatus.AwaitingApproval
    );
  }

  get isDeclined() {
    return this.model.emailScheduleStatusId === EmailScheduleStatus.Declined;
  }

  get isSent() {
    return this.model.emailScheduleStatusId === EmailScheduleStatus.Sent;
  }

  get isOpen() {
    return this.showDialog;
  }

  get getStatusBadgeType() {
    switch (this.model.emailScheduleStatusId) {
      case EmailScheduleStatus.AwaitingApproval:
        return "warning";
      case EmailScheduleStatus.Approved:
        return "info";
      case EmailScheduleStatus.Declined:
        return "danger";
      case EmailScheduleStatus.Sent:
        return "success";
    }
  }

  async updateStatus(statusId: number) {
    this.loading = true;
    try {
      await EmailScheduleService.updateScheduleEmailStatus(
        this.emailScheduleId,
        statusId
      );
      this.close(true);
    } finally {
      this.loading = false;
    }
  }

  async loadModel() {
    this.loading = true;
    try {
      this.model = await EmailScheduleService.getEmailScheduleDetails(
        this.emailScheduleId
      );
    } finally {
      this.loading = false;
    }
  }

  close(result: boolean = false) {
    this.$emit("onClose", result);
  }
}
