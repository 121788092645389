import { UploadFileModel } from "@/core/models";

import {
  Validatable,
  SimpleLookupModel,
  QualityAuditCollectionModel,
  PagedCollectionFilter,
} from "@/core/models";

export class LicenseModel extends Validatable {
  licenseId: number;
  forUserId: number;
  startDate: Date;
  expiryDate: Date;
  statusId: number;
  levelId: number;
  strandId: number;
  regionIds: number[];
  regions: any[];
  unionIds: number[];
  unions: any[];
  reason: string;
  qualityAudits: QualityAuditCollectionModel[] = [];
  history: LicenseHistoryModel;
}

export class LicenseSummaryModel {
  licenseId: number;
  userFulName: string = "";
  userEmail: string = "";
  strandName: string = "";
  levelname: string = "";
  regionNames: string = "";
  unionNames: string = "";
}

export class LicenseHistoryModel {
  licenseId: number;
  events: LicenseHistoryEventModel[];
}

export class LicenseHistoryEventModel {
  dateCreated: Date;
  licenseStatusId: number;
  licenseStatusText: string;
  reason: string;
}

export class RevokeLicenseModel extends Validatable {
  licenseId: number;
  reason: string;
}

export class ExpireLicenseModel extends Validatable {
  licenseId: number;
  expiryDate: Date;
  reason: string;
}

export class LicenseLookupModel {
  userFullName: string = "";
  statuses: SimpleLookupModel[] = [];
  levels: SimpleLookupModel[] = [];
  strands: SimpleLookupModel[] = [];
  regions: SimpleLookupModel[] = [];
  unions: SimpleLookupModel[] = [];
  expiry: SimpleLookupModel[] = [];
  licenseQualityStatuses: SimpleLookupModel[] = [];
  userPhotoUrl: string | undefined = undefined;
}

export class RevokeLicenseLookupModel {
  userFullName: string;
}

export class LicenseCollectionModel {
  licenseId: number;
  status: string;
  statusId: number;
  user: string;
  userId: number;
  regions: string;
  unions: string;
  strand: string;
  level: string;
  summary: string;
  expiryDate: Date;
}

export class LicensePagedCollectionFilter extends PagedCollectionFilter {
  statusId?: number;
  strandId?: number;
  levelId?: number;
  regionId?: number;
  unionId?: number;
  expiresWithin?: number;
  forCurrentUser: boolean = false;
}

export class EducatorsMassEmailModel extends Validatable {
  filter: LicensePagedCollectionFilter;
  emailSubject: string;
  emailBodyHtml: string;
  emailBodyText: string;
  stringFilter: string;
  attachments: UploadFileModel[] = [];
}
