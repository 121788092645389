/**
 * Grid provider model.
 */
export class GridModel<T> {
  filter: PagedCollectionFilter;
  collection: PagedCollection<T>;
  // Set to true if you want to add a filter action button
  // for performance resons
  // This prevents calling the filter API action unless
  // explicitly instructed
  preventAutoSubmit: boolean;

  onSearch: (searchClause?: string) => void;
  onFilter: () => void;
  clearSearch: (filter: PagedCollectionFilter) => void;
  sort: (params: ElSortParams) => void;
  goToPage: (page: number) => void;
  prev: () => void;
  next: () => void;
  first: () => void;
  last: () => void;

  isFirstPage: () => boolean;
  isLastPage: () => boolean;
}

/**
 * Element UI sort-change event params.
 */
export class ElSortParams {
  column: object;
  order: string;
  prop: string;
}

/**
 * Paged collection fetch filter used to page/sort/search.
 */
export class PagedCollectionFilter {
  /**
   * Page to fetch.
   */
  page?: number;

  /**
   * Amount of items to fetch.
   */
  pageSize?: number;

  /**
   * Search clause.
   */
  search?: string;

  /**
   * Sort by KEY (entity field name).
   */
  sortBy?: string;

  /**
   * Sort direction (optional).
   * Allowed: "ascending" / "descending".
   */
  sortOperator?: string;

  /**
   * Include soft deleted entities?
   */
  includeDeleted?: boolean;

  /**
   * Collection owner user Id.
   */
  ownerId?: number;
}

export class PagedCollectionFilterDictionary {
  [Key: string]: PagedCollectionFilter;
}

/**
 * Paged collection model.
 */
export class PagedCollection<T> {
  pageCount: number;
  recordCount: number;
  items: T[];
}
