































import { Component, Prop } from "vue-property-decorator";
import AppVue from "@/apps/AppVue.vue";

@Component({
  components: {
    //
  },
})
export default class LicenseFilterTable extends AppVue {
  @Prop() stringFilter: string;

  get objectFilter(): any {
    if (!this.stringFilter) return {};
    return JSON.parse(this.stringFilter);
  }
}
