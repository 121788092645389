import {
  HttpService,
  DownloadProvider } from "./common";
import {
  AssessmentModel,
  FileDownloadModel,
} from "@/core/models";

export const AssessmentService = {
  getAssessment(assessmentId: number) {
   return HttpService.getData<AssessmentModel>(`/assessments/${assessmentId}`);
  },

  putAssessment(model: AssessmentModel, publish: boolean = false) {

    return HttpService.putModel(this.getSaveUrl(publish), model);
  },

  postAssessment(model: AssessmentModel, publish: boolean = false) {
    return HttpService.postModel(this.getSaveUrl(publish), model);
  },

  calculateStatus(model: AssessmentModel) {
    return HttpService.postData<number|null>("/assessments/calculate-status", model);
  },

  Unpublish(assessmentId: any) {
    return HttpService.put(`/assessments/${assessmentId}/unpublish`);
  },

  async exportToPdf(assessmentId: number) {
    const response = await HttpService.getData<FileDownloadModel>(
      `/assessments/${assessmentId}/download`);

    DownloadProvider.downloadResponse(response);
  },

  getSaveUrl(publish: boolean = false) {
    const baseUrl = "/assessments";
    return publish
      ? `${baseUrl}?publish=true`
      : baseUrl;
  },
};
