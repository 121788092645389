
import AppVue from "./AppVue.vue";
import { DefaultLocale } from "@/core/services";
import { RoleName } from "@/core/constants";

export default class CourseAppVue extends AppVue {

  canEditCourse() {
    return this.isInRole(RoleName.CourseEditor);
  }

  canEditPage() {
    return this.canEditCourse();
  }

  canViewQuestions() {
    const questionRoles = [
      RoleName.CourseEditor,
      RoleName.CourseTranslator,
    ];

    return this.isInRoles(questionRoles);
  }

  canEditQuestion(localeCode: string) {
    if (this.canEditCourse()) {
      return true;
    }

    if (this.isInRole(RoleName.CourseTranslator) && localeCode !== DefaultLocale) {
      return true;
    }

    return false;
  }

  canAddQuestion(localeCode: string) {
    return this.canDeleteQuestion(localeCode);
  }

  canDeleteQuestion(localeCode: string) {
    if (this.canEditCourse() && localeCode === DefaultLocale) {
      return true;
    }

    return false;
  }
}
