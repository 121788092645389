import { HttpService } from "./common/http.service";
import { LocaleModel } from "@/core/models";

export const LocaleService = {
  list() {
    return HttpService.getData<LocaleModel[]>("/locales");
  },

};

export const DefaultLocale: string = "en";
